.csv-import-table .table tr td:nth-child(1),
.csv-import-table .table tr th:nth-child(1) {
    width: 120px;
    border-right: 1px solid #E2E2EA;
}

.csv-import-table .table tr td:nth-child(1) {
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.csv-import-table .table tr td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.csv-import-table .table {

    width: 452px;
    margin-bottom: 0;
    height: 100%;
}

.csv-import-table .table thead tr th {
    background: #0062FF;
    overflow: hidden;
    color: white;
}

.csv-import-table .table thead tr th:nth-child(1) {
    border-right: 2px solid white !important;
}

.csv-import-table .table tr td:nth-child(2) {
    color: var(--black);
    font-weight: 500;
}

.csv-import-table .table tr td:nth-child(2) span:not(.text-red) {
    color: #92929D;
    font-weight: 400;
}

.csv-import-table .table tr td:nth-child(2) span.text-red {
    color: var(--red);
}

.csv-import-table::-webkit-scrollbar {
    border-radius: 0 !important;
    background-color: white !important;
}

.csv-import-table {
    min-height: 330px;
    max-height: 330px;
    overflow-x: hidden;
    border-radius: 8px;
    padding-bottom: 0;
    overflow-y: scroll;
    position: relative;


}


.chang-passowrd .card {
    width: 420px;

}

.chang-passowrd {
    height: 70vh;
    display: flex;
    align-items: start;
    justify-content: start;
}

.setting-menu .card-header {
    padding: 12px 18px;
    border: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 22px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.setting-menu .card-header[aria-expanded="true"] svg {
    transform: rotateX(180deg);
}

.setting-menu .card-header[aria-expanded="true"] svg path {
    fill: var(--black);
}

.setting-menu .card-header svg {
    transition: 0.5s all;
}

.setting-menu .card {
    border-bottom: 1px solid var(--black-dark-400) !important;
    border: none;
    border-radius: 0;
    box-shadow: unset;
}

.setting-menu .card .nav a {
    margin-bottom: 8px;
}


.setting-menu div#accordion {
    margin-right: 1px;
}

.setting-menu .card a {
    padding: 8px 18px;
    display: block;
    margin: 0 15px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04px;
    color: var(--black-dark-700);
    font-weight: 500;
}

.setting-menu .card ul {
    margin: 0 1px 8px 1px;
}

.setting-menu .card ul li+li {
    margin-top: 4px;
}

.setting-menu .card a:hover,
.setting-menu .card a.active {
    background: #F0F6FF;
    color: var(--primary-color);
}


ol.breadcrumb li a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: var(--black-dark-700) !important;
    font-weight: 500;
    padding: 0 20px;
}

ol.breadcrumb li.active a {
    font-weight: 500;
    color: var(--primary-color);
    position: relative;
}

ol.breadcrumb li a span {
    margin-right: 8px;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0 !important;
    position: relative;
    margin-left: 20px;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">" !important;
    position: absolute !important;
    left: -15px !important;
    padding-right: 0 !important;

}

.breadcrumb-item+.active::before {
    color: var(--primary-color) !important;
}

ol.breadcrumb li a:before {
    content: " ";
    position: absolute;
    left: 0;
    bottom: -12px;
    width: calc(100% + 20px);
    height: 2px;
    background: var(--primary-color) !important;
    transition: 0.5s all;
    opacity: 0;
}

ol.breadcrumb li.active a:before {
    opacity: 9;
}

.drag-file {
    border: 1px dashed var(--primary-color);
    height: 460px;
    cursor: pointer;
    background: var(--black-dark-100);
}

.file-import {
    margin-top: 24px;
}

.abs-text {
    position: absolute;
    bottom: 15px;
    width: 338px;
    font-size: 10px;
    /* text-align: center; */
    line-height: 15px;
    color: var(--black-dark-700);
    font-weight: normal;
}

.csv-upload-bottom-info {
    position: absolute;
    bottom: 15px;
    width: 338px;
    font-size: 12px;
    /* text-align: center; */
    line-height: 15px;
    color: var(--black-dark-700);
    font-weight: normal;
}

.import-roule ul li {
    display: flex;
    margin-bottom: 16px;
}

.import-roule ul li span {
    margin-right: 16px;
}

.import-roule ul {
    padding-right: 110px;
}

.inport-part {
    /* min-height: calc(100% - var(--header-height)); */
    height: 100%;
    width: calc(100% - 64px);

}

.reset-submit {
    border-left: 1px solid #E2E2EA;
    background: white;
    width: 100%;
    border-top: 1px solid #E2E2EA;
}

.left {
    width: 226px;
    flex: 0 0 226px;
}

.fild-according .card-header {
    background: white;
    border: 0;
    padding: 13px 0;
    font-size: 16px;
    font-weight: 500;
    position: sticky;
    top: 0;
    cursor: pointer;
    z-index: 1;

}

.fild-according .card {
    padding: 0;
    border: 0;
    border-bottom: 1px solid #E2E2EA;
    border-radius: 0;
}

.fild-according .card-header svg {
    transition: 0.5s all;
}

.fild-according .card-header[aria-expanded="true"] .icon svg {
    transform: rotate(180deg)
}

.icon {
    width: 20px;
    margin-right: 20px;
}

.fild-according button.save-btn {
    border: 0;
    background: 0;
    margin-right: 20px;
    letter-spacing: 0.4px;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    border-radius: 4px;
    background: #F0F6FF;
    letter-spacing: 0.04px;
    padding: 4px 8px;
}



.fild-according .card-header[aria-expanded="true"] button {
    opacity: 9;
}

.fild-according .card-body {
    padding: 20px 26px;
    background: #FAFAFB;
    border-top: 1px solid #E2E2EA;
}

.row.heading {
    padding: 0 20px;
    border-radius: 8px;
    background: #F1F1F5;
    margin-bottom: 8px;
    font-size: 14px;
    color: var(--black-dark-800);
    font-weight: 500;
    letter-spacing: 0.5px;
    z-index: 1;
}

.fild-according .collapse.show .row.heading {
    position: sticky;
    top: 51px;
    /* top: 62px; */
}

.fild-according .card-body .row:not(.heading) {
    font-size: 14px;
    font-weight: 500;
    color: #171725;
    letter-spacing: 0.5px;
}

.fild-according .card-body .row {
    padding: 8px 24px 8px 20px;
    align-items: center;
    margin: 0
}

.fild-according .card-body .row:not(.heading)+.row {
    border-top: 0.5px solid #D5D5DC;
}

.fild-according .card-body .row:not(.heading) {
    padding: 8px 20px 8px 0px;

}

.fild-according .card-body .row:not(.heading) .col-md-6 {
    padding: 0;
}

.fild-according .card-body .css-1s2u09g-control {
    background: transparent;
    cursor: pointer;
}


.card-notification {
    width: 260px;
    margin-left: auto;
}

.card-notification .card {
    border: 1px solid #E2E2EA;
    border-radius: 4px;
}

.card-notification .card .card-header {
    background: #F1F1F5;
    border-bottom: 1px solid #E2E2EA;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 14px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
}

.card-notification .card .card-header svg {
    margin-right: 8px;
}

.card-notification .card .card-body {
    padding: 16px;
    font-size: 14px;
    line-height: 22px;
}


.block .description {
    padding-left: 28px;
}

.block h3 {
    color: var(--black);
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0px;
    font-weight: 500;
    margin-left: 16px;

}


.block label {
    margin-bottom: 6px;
}

.block .input {
    padding-top: 3px;
    margin-right: 16px;
}

.blocks {
    width: 557px;
    padding: 16px 22px;
    background: var(--black-dark-100);
    border-radius: 4px;
}

.block p {
    font-size: 14px;
    line-height: 22px;
    color: var(--black-dark-700);
    font-weight: 400;
    margin-bottom: 0;
}


.block+.block {
    margin-top: 16px;
}

.deletemodal .modal-footer {
    background: #E5E5E5;
    padding: 10px 16px;
}

.deletemodal .modal-body {
    border-bottom: 1px solid #E2E2EA;
}




.serch-btn {
    color: var(--black-dark-800);
    background: var(--black-dark-200);
    padding: 5px 12px;
    letter-spacing: 0.01px;
    border-radius: 4px;
}

.serch-btn:hover {
    background-color: var(--primary-color);
    color: var(--white);
}

.serch-btn.active {
    background: var(--Blue-100);
    color: var(--primary-color);
}

.def-table table thead,
.booking-detail-table table thead {
    background-color: #FAFAFB;
    position: sticky;
    top: 0;
    z-index: 9;
}

.def-table table thead tr th,
.booking-detail-table table thead tr th {
    color: var(--black-dark-800);
    background-color: #FAFAFB;
    white-space: nowrap;
    border-bottom: 0 !important;
}

.def-table {
    padding-right: 10px;
    height: 75vh;
    padding-bottom: 10px;
    overflow: auto;
    --webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
}

.broker-page .def-table {
    height: 74vh !important;
}

.broker-page .sv-completed .def-table {
    height: 70vh !important;
}

.dashboard-sv .broker-page .def-table {
    height: 72vh !important;
}

.booking-detail-table {
    padding-right: 10px;
    padding-bottom: 10px;
    overflow: scroll;
    --webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
}

.def-table table tbody tr td,
.booking-detail-table table tbody tr td {
    border-bottom: .5px solid rgb(213 213 220/60%) !important;
    box-sizing: border-box;
    border-top: none !important;
}

.agency-table .def-table {
    height: calc(100vh - 183px);
}



.facbook-leads-add .def-table {
    height: calc(100vh - 315px)
}

.fb-innerpageintegratoin .def-table {
    height: calc(100vh - 210px);
}


.all-project-table .def-table {
    height: calc(100vh - 192px);
}

.import-table-main .def-table {
    height: calc(100vh - 168px);
}

.exprot-table-main .def-table {
    height: calc(100vh - 197px);
}



.def-table.fb-lead-integration {
    height: calc(100vh - 181px);
}



.housing-manage-table .def-table {
    height: calc(100vh - 183px);
}

.def-table table tbody tr td {
    padding-top: 16px;
    padding-bottom: 16px;
}

.def-table table thead tr th:nth-child(1) {
    width: 65px;
}

.edit-table {
    max-width: 700px;
}

.all-projects-table table {
    margin-bottom: 0;
}


.company-info {
    height: calc(100vh - var(--header-height) - 90px);
    overflow-y: scroll;
    padding-bottom: 50px;
    overflow-x: hidden;
}

.fb-integration {
    max-width: 473px;
    border: 1px solid #E2E2EA;
    padding: 16px 24px;
    border-left-color: var(--primary-color);
    border-left-width: 6px;
    border-radius: 4px;
}

.secure {
    position: absolute;
    left: 20px;
    top: 20px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 10px;
    padding: 10px 12px;
    color: #8A8A97;
    font-size: 10px;
    font-weight: 500;
}

.secure svg {
    margin-right: 8px;
}