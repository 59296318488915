.intent-drop>button {
    min-width: 124px;
    display: flex;
    justify-content: space-between;
    border: 0;
}

.lead-details-sidebar {
    max-width: 400px;
    flex: 0 0 400px;
    margin-left: 20px;
}

.lead-details-board {
    max-width: calc(100% - 420px);
    flex: calc(100% - 420px);
}

.board {
    padding: calc(var(--value) * 10);
}

.pagination button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 24px;
}

.pagination .text {
    margin: 0px 12px;
    font-size: 12px;
    color: var(--black-dark-700);
    letter-spacing: 0.1px;
}

.pagination .text span.active {
    font-weight: 600;
}

.pagination button:hover {
    background: var(--Blue-100);
}

.pagination button:hover svg path {
    fill: var(--primary-color);
}

.followups {
    background: #F5F9FF;

}


.tags ul li {
    padding: calc(var(--value) * 4);
    color: var(--black-dark-800);
    font-size: 12px;
    margin-right: 12px;
    box-shadow: 0px 0px 2px #92929d;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    text-transform: uppercase;
}

.tags button[aria-expanded="true"] {
    background: #f5f9ff;
}

.tags .dropdown>button {
    padding: 4px 10px;
    color: var(--black-dark-600);
    font-family: var(--roboto);
    font-weight: var(--ro-light);
}

.tags .dropdown>button:hover {
    color: var(--primary-color)
}

.tags .dropdown>button:hover svg path {
    fill: var(--primary-color);
}

.lead-details-board .board {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.tabs {
    background: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: var(--white);
}

.tabs .nav-tabs .nav-link {
    border: 0;
}

.tabs .nav-tabs .nav-item {
    margin-bottom: 0;
}

.tabs .tab-content {
    padding: 20px 20px 0 20px;
    border-top: 2px solid #E2E2EA;
    margin-top: -2px;
}

.tabs .tab-content h3 {
    color: var(--black-dark-600);
    font-size: calc(var(--value) * 8);
    line-height: 20px;
    letter-spacing: 0.1px;
    font-weight: 500;
}

.tabs .tab-content h4 {
    color: var(--black);
    font-size: calc(var(--value) * 8);
    line-height: 20px;
    font-weight: var(--po-regular);
    letter-spacing: 0.1px;
}

.tabs .nav .nav-item .nav-link {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
    color: var(--black-dark-700);
    border-bottom: 1px solid transparent;


    position: relative;
    padding-top: 0;
}

.edit-input-modal .form-group .row .col-3 {
    max-width: 33.33333% !important;
    flex: 0 0 33.33333% !important;
}

.edit-input-modal .form-group .row .col-3 input {
    padding-left: 8px !important;
    padding-right: 0 !important;
}

.date-time input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 45px;
    line-height: 42px;
}

.edit-input-modal .form-group .row .col-9 {
    max-width: 0 0 66.666667% !important;
    flex: 66.666667% !important;
}

.tabs .nav .nav-item .nav-link:hover {
    color: var(--primary-color);
}

.bck-btn:hover svg path:nth-child(1) {
    fill: var(--Blue-100);
}

.bck-btn:hover svg path:nth-child(2) {
    fill: var(--primary-color);
}

.tabs .nav .nav-item .nav-link.active {
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
}

.tabs .tab-content h4 span {
    background: #F0F6FF;
    padding: 2px 4px;
    border-radius: 2px;
    font-size: 14px;
    cursor: pointer;
}

.tabs .tab-content h4 button {
    opacity: 0;
    transition: 0.5s all;
}

.tabs .tab-content h4:hover button {
    opacity: 9;
}

.enableEditButton {
    opacity: 9 !important;
}



.next-prev button {
    margin-left: 12px;
}

.edit button {
    font-size: 14px;
    color: var(--black-dark-700);
    font-family: var(--roboto);
    letter-spacing: 0.1px;
}

.edit button svg {
    margin-right: 4px;
}

.siderbar-list h2 span,
.sidebar-title h2 span {
    font-size: calc(var(--value) * 6);
    margin-left: 20px;
    color: var(--primary-color);
    background: var(--Blue-100);
    padding: 1px 8px;
    line-height: 22px;
    letter-spacing: 0.1px;
    border-radius: 14px;
}

.siderbar-list {
    padding: 10px;
    cursor: pointer;
}

.siderbar-list button {
    color: var(--black-dark-600);
    font-family: var(--popins);
    letter-spacing: 0.1px;
    font-size: 14px;
}

.siderbar-list button span {
    padding: 2px 8.5px;
    border-radius: 4px;
}

.siderbar-list button:hover span {
    background: var(--Blue-100);
}

.slide-sidebar {
    left: 0px;
    top: 0px;
    bottom: 0;
    right: 0;
    background: white;
    padding: 0 20px 20px 20px;
    z-index: 1;
    transition: 0.5s all;
    min-height: 100vh;
    width: 100%;
    transform: translateX(400PX);
}

.border-0.sidebar-title {
    padding-top: 20px;
    position: sticky;
    top: -20px;
    background: white;
    padding-bottom: 20px;
    z-index: 9;
}

.slide-sidebar.active {
    transform: unset;
}

.lead-details-sidebar {
    overflow: hidden;
    min-height: 100vh;
}

.card-activity .edit-delte {
    right: 0;
    top: -3px;
}

.card-activity {
    border-radius: 8px;
    color: var(--black-dark-700);
}

.card-activity:hover {
    background: #F5F9FF;
}

div.deletemodal .modal-content {
    border: 0;
}

div.deletemodal .modal-content h1 {
    font-family: var(--popins);
    font-size: 24px;
    margin-bottom: 8px;
}

div.deletemodal .modal-content p {
    color: #44444F;
    font-size: 16px;
    margin-bottom: 0;
}

div.deletemodal .modal-content .modal-footer {
    background: #FAFAFB;
    padding: 12px 20px;
}

div.deletemodal .modal-content .modal-footer button {
    padding: 8px 16px;
    border: 0;
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
    color: white;
}

div.deletemodal .modal-content .modal-footer button:nth-child(1) {
    border: 1px solid var(--primary-color);
    background: transparent;
    color: var(--primary-color);
    margin-right: 12px;
}

div.deletemodal .modal-content .modal-footer button:nth-child(2) {
    border: 1px solid transparent;
    background: var(--red);
}

div.deletemodal .modal-content .modal-body {
    padding: 20px;
}

.create-new-notes textarea.form-control {
    height: 120px !important;
}

.date-time h3 {
    color: var(--black-dark-700);
    font-weight: var(--po-regular);
}

.icon input[type=checkbox] {
    width: 16px;
    padding: 0;
    height: 16px;
    flex: 0 0 16px;
}

.overdue {
    color: var(--red);
}

.card-activity .desc.position-relative {
    width: 100%;
}


.nex-prv-count .active {
    color: black;
}

.nex-prv-count {
    color: #92929D;
    margin-top: 3px;
}

̰.siderbar-list:hover {
    background: #F0F6FF;
    border-radius: 8px;
    border-color: transparent;
}

.lines {
    height: 0.5px;
    margin: 12px 0;
    background: #D5D5DC;
}

.edit-follow textarea {
    color: var(--black-dark-700) !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: var(--roboto);
    padding: 8px !important;
}


.create-new-notes textarea,
.slide-sidebar textarea {
    padding: 10px 12px !important;
}

.slide-sidebar .desc {
    padding-left: 36px;
}

.slide-sidebar .edit-textarea {
    padding-left: 36px;
}

.new-active-btn a {
    font-size: 14px;
    color: var(--black);
    width: 240px;
    text-align: center;
    border: 1px solid #B5B5BE;
    padding: 8px 0;
    font-weight: 500;
}

.new-active-btn {
    padding: 0 60px;
}

.new-active-btn a+a {
    margin-top: 16px;
}

.site-visit-done form {
    width: 270px;
    margin: auto;
}

.site-visit-done .form-control::placeholder {
    font-size: 14px;
}

.cancle-ok-btn button {
    width: 125px;
}

.cancle-ok-btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.cancle-ok-btn .pr-btn {
    border: 1px solid var(--primary-color)
}

.site-visit-done input.form-control {
    background: #f1f1f5;
    padding-left: 10px !important;
}

.recent-activites .box {
    position: relative;
    display: flex;
    align-items: flex-start;
}

.recent-activites .box .left {
    margin-right: 14px;
    width: 46px;
    height: 46px;
    flex: 0 0 46px;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    border: 1px solid #E1E1E1;

}

.recent-activites .box+.box {
    margin-top: 24px;
}



.recent-activites .box.active:before {
    content: " ";
    position: absolute;
    left: 23px;
    height: 100%;
    width: 1px;
    background: #E1E1E1;
    top: 30px;
}


.recent-activites .box .left .icon {
    margin: 0;
    width: auto;
}

.recent-activites .card-activity {
    background: var(--black-dark-100);
}

.tag-show {
    display: flex;
    align-items: center;
}

.tag-show>button {
    opacity: 0;
}

.tag-show:hover>button {
    opacity: 9;
}

.board.position-relative {
    height: calc(100vh - 130px);
    overflow-x: hidden;
    overflow-y: auto;
}


.edit-details-active {
    color: var(--primary-color) !important;
}

.edit-details-active svg {
    color: var(--primary-color) !important;

    overflow-y: auto;
    overflow-x: hidden;
}

.edit-follow {
    background: #F5F9FF;
}

.edit-follow textarea {

    background-color: white !important;
}

.active-edit {
    color: var(--primary-color) !important;
}

.recent-activites .box .righ {
    width: 100%;
}

.recent-activites .box .righ h2 {
    margin-top: 11px;
    line-height: 1.5;
}

.recent-activites .box .righ h2+p {
    margin-top: 0 !important;

}

.active-edit svg path {
    fill: var(--primary-color);
}

.edit-input-text .edit-input input {
    padding-left: 8px;
    padding-right: 8px !important;
    height: 38px;
    max-width: 250px;
    flex: 0 0 250px;
    width: 250px;
    font-size: 24px;
    font-weight: 600;
    font-family: var(--popins);
    color: rgb(0, 27, 71);
}

.tags .edit-input-text {
    height: 29px;
}

.name-box {
    width: 253px;
    max-width: 253px;
    min-height: 70px;
    border-radius: 4px;
    padding: 9px 9px;
    position: relative;
}

.assignment-title .btn-toggle {
    width: 60px;
    height: 24px;
    margin-top: -10px;
}

.assignment-title .btn-toggle.btn-lg.active>.handle {
    left: 37px;
}

.assignment-title .btn-toggle.btn-lg>.handle {
    width: 20px;
    height: 20px;
}

.assignment-title .btn-toggle.btn-lg>span {
    font-size: 14px;
}


.css-6j8wv5-Input,
.css-6j8wv5-Input input {

    border: none !important;
    border: 0 !important;
    outline: 0 !important;
}


.name-box:hover {
    background: #F7F7F7;
}

.next-prev button:hover svg path {
    fill: var(--white);
}

.name-box button {
    opacity: 0;
}

.name-box button:hover svg rect {
    stroke: var(--primary-color);
}

.name-box:hover button {
    opacity: 9;
}

.next-prev button:hover svg circle {
    fill: var(--primary-color);
    stroke: var(--primary-color);
}


.edit-input-modal {
    background: #FFFFFF;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    padding: 6px 10px;
    width: 253px;
    max-width: 253px;
}

.main-box {
    width: 253px;
    max-width: 253px;
    height: 70px;
    position: relative;
}

/* .main-box>div:not(.active) {
    display: none;
} */
.name-pagination .pr-hover:hover {
    color: var(--primary-color) !important;
}

.main-box>div.active {
    display: block;
}

.card-activity.active {
    background: #F5F9FF;
}

.lead-detail-left {
    flex: 0 0 calc(100% - 440px);
    max-width: calc(100% - 440px);
    height: calc(100vh - 130px);
    overflow: auto;
}

.no-result-find {
    padding-bottom: 80px;
}

.lead-detail-right {
    width: 400px;
    /* margin-right: 17px; */
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

.no-result-find {
    margin-top: 60px;
}


.slide-sidebar.d-active {
    display: none;
}

.asign-del a.dropdown-item {
    background: var(--white);
    line-height: 22px;
    letter-spacing: 0.1px;
    color: var(--black-dark-700);
    border-radius: 4px;
    font-size: 14px;
    padding: 4px 8px;
}

.asign-del a.dropdown-item:hover {
    background: var(--black-dark-200);
}

.del-menu {
    color: var(--red) !important;
}

.del-menu:hover {
    background: var(--red) !important;
    color: white !important;
}

.asign-del a.dropdown-item+a.dropdown-item {
    margin-top: 4px;
}

.tags .edit-input-text input[type="text"] {
    padding-left: 8px;
}

.save-cancel-btn {
    margin-top: 16px;
}

.save-cancel-btn .cancel-btn {
    margin-right: 10px;
}

.save-cancel-btn button {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--popins);
}

.save-cancel-btn .save-btn {
    padding: 6px 34px;
}

.followups>div {
    position: relative;
}

.lead-detail-left .board .followups>div+div:before {
    content: " ";
    position: absolute;
    left: -1px;
    top: -11px;
    width: 1px;
    height: 60px;
    background: var(--black-dark-400);
}

.followups .latest-followup {
    padding-right: 52px;
}

.followups .latest-followup+.site-visit {
    padding-left: 52px;
}

.name-pagination {
    max-height: 100px;
}

.main-box .name-box h3 {
    color: var(--black-dark-600);
    font-weight: 500;
}

.main-box .name-box h4 {
    color: var(--black);
}

/* .tabs .tab-pane {min-height: 340px;} */
.SiteVisits .desc {
    padding-left: 0;
}


.block-content {
    padding-left: 34px;
}

.innner-timeline-box {
    padding: 9px 10px;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
}

.innner-timeline-box b {
    color: var(--black-dark-800);
    font-weight: 500;
    margin-right: 4px;
}

.innner-timeline-box p:last-child {
    margin-bottom: 0 !important;
}

.innner-timeline-box p svg {
    max-width: 16px;
    flex: 0 0 16px;
    margin-top: 4px;

}

.innner-timeline-box p {

    white-space: break-spaces;

}

.leadnametooltip {
    flex: 0 0 14px;
    max-width: 14px;
}



.tooltip[x-placement="right"].leadmanager-tooltip {
    inset: 0px auto auto 20px !important;
}