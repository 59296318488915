.projectdetailPage {
    width: calc(100% - 64px);
    flex: 0 0 calc(100% - 64px);
}

.offerlist {
    font-family: var(--inter);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #374151;
    text-indent: -24px;
    padding-left: 30px;
    padding-right: 30px;
}

.scrollspynavbar::-webkit-scrollbar {
    opacity: 0;
    display: none;
}

.detailedit {
    border-radius: 4px;
    border: 1px solid #DEDEDE;
    background: #FAFAFB;
    color: #696974;
    font-family: var(--inter);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;
    display: inline-flex;
    gap: 8px !important;
    align-items: center;
    padding: 4px 12px;

}

.detailedit:hover {
    background-color: var(--black-dark-200);
}

.multipledivgroup>div {
    margin-bottom: 64px;
}

.simpleinput:focus {
    outline: 0;
    box-shadow: unset;
}

.simpleinput {
    min-height: 120px;
}

.sticky_top {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    border-bottom: 1px solid #E2E2EA;
    background: #FFF;
    padding: 12px 20px;

}

.scrollspynavbar {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 10px;
}

.scrollspynavbar ul li a {
    font-size: 14px;
    color: var(--black) !important;
    padding: 0;
    font-family: var(--inter);
    position: relative;
}

.scrollspynavbar ul a:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
    background-color: var(--primary-color);
    height: 1px;
    opacity: 0;
}





.backbtn {
    display: inline-flex;

    align-items: flex-start;
    gap: 10px;
    background: white;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    flex: 0 0 32px;
    margin-right: 14px !important;
}

.backbtn:hover {
    background-color: var(--black-dark-200);
}



.backbtn:hover svg path {
    stroke: var(--black);
}

.backtotop {

    color: rgba(90, 90, 103, 1);
    font-size: 14px;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    position: fixed;
    right: 24px;
    bottom: 24px;
    padding: 8px 10px;
    z-index: 999;
    background-color: white;
}

.backtotop:hover {
    background-color: var(--black-dark-200);
    color: rgba(90, 90, 103, 1);
}

.AddYourListHeader {
    padding: 15px 20px;
    border-bottom: 1px solid #DEDEDE;
}

.AddYourListBody {
    padding: 20px;
}

.AddYourListBody p {
    letter-spacing: 0.8px;
    color: #44444F;
}

.AddYourListfooter {
    padding: 20px;
    border-top: 1px solid #DEDEDE;
}

.AddYourListfooter label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #44444F;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: var(--inter);
}

.AddYourListfooter button {
    background: #0062FF;
    border: 2px solid #0049BD;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding: 14px 0;
    margin-top: 16px;
}

.projectcardBlock {
    display: grid;
    gap: 26px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    margin: 0 8px;
}

.newUpdatetags {
    background-color: black !important;
    border: 1px solid rgba(255, 255, 255, 0.4) !important;
    backdrop-filter: blur(2px);
}

.projectcard {
    padding: 14px;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
    height: 390px;
    position: relative;
}

.added:hover span {
    margin-right: 8px;
}

.treadingtags {

    background: #1BCA84;
    border: 1px solid rgba(255, 255, 255, 0.4);

    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: white;
    display: inline-flex;
    gap: 12px;
    padding: 5px 10px;
    font-family: var(--inter);
}

.newtags {
    background: #0062FF;
    border: 1px solid rgba(255, 255, 255, 0.4);

    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: white;
    display: inline-flex;
    gap: 12px;
    padding: 6px 10px;
    font-family: var(--inter);
}

.prplus {
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    font-family: var(--inter);
    color: var(--black);
    padding: 5px 6px;
    display: inline-flex;

}

.projectpropertytype {
    color: #515151;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-shadow: 0px 0px 10px #E1EBFB;
    border-radius: 6px;
    padding: 4px 10px;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

}

.prplus span {
    transition: 0.2s all;
    opacity: 0;
    font-size: 0;

}

.prplus:hover span {
    opacity: 9;
    font-size: 12px;
    margin-left: 4px;
}

.tags_plusgroup {
    padding: 12px;
    left: 0;
    right: 0;
    top: 0
}

.projectsize {
    gap: 6px;
    display: inline-flex;
    flex-direction: column;
}

.costsize {
    color: #696974;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
    padding-top: 8px;
    border-top: 1px solid #DEDEDE;
    font-family: var(--inter);


}

.projectcost {
    gap: 6px;
    display: inline-flex;
    flex-direction: column;
}

.projectcard figure {
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 8px;
    height: 168px;

}

.projectcard figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tags_plusgroup {
    width: 100%;


}

.changerara .active {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
}

.clearbtn {
    color: #0062FF;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    font-family: var(--inter);
    width: 80px;
    height: 38px;
    background: var(--white);
    border-radius: 6px;
    white-space: nowrap;
    border: 0;
}

.projectbtngroup {
    display: flex;
    gap: 12px;
    align-items: start;
    position: absolute;
    left: 14px;
    right: 14px;
    bottom: 12px;

}

.righmodalfooter {
    padding-top: 22px;
    padding-bottom: 22px;
    grid-gap: 10px;
    gap: 10px;
}

.filter_btn {
    border: 0;
    background-color: white;
    border-radius: 4px;
    padding: 4.5px 16px;
    height: 32px;
    white-space: nowrap;
}

.filter_btn:hover {
    background: #F1F1F5;
    color: var(--black) !important;
}

.filter_btn:hover svg path {
    fill: var(--black)
}

.filter_btn_active {
    background-color: var(--Blue-100);
    color: var(--primary-color);
}

.filter_btn_active svg path {
    fill: var(--primary-color);
}

.viewbtn:hover {
    background-color: var(--black-dark-200);
}

.viewbtn:hover {
    color: rgba(23, 23, 37, 0.56);
}

.viewbtn {
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    width: 100%;
    color: rgba(23, 23, 37, 0.56);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 7px 0;
    background-color: white;
    text-align: center;
}

.contactbtn:hover {
    opacity: 0.9;
}

.contactbtn {
    background: #0062FF;
    border: 1px solid #0062FF;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: white;
    width: 100%;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    padding: 7px 0;
    align-items: center;

}

.clearbtn:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.changerara button {
    border: 1px solid #D0D5DD;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #344054;
    background-color: white;
    width: 92px;
    text-align: center;
    padding: 8px 0;
    font-family: var(--inter);
}

.changerara {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
}

.rerainfo p {
    margin-bottom: 0;
}

.rerainfo p span {
    font-weight: 500;
    color: var(--black);
}

.rerainfo>div {
    width: 20px;
    flex: 0 0 20px
}

.rerainfo {
    background: rgba(0, 98, 255, 0.05);
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #44444F;
    font-family: var(--inter);
    padding: 12px 16px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-top: 16px;
}

.lpNewCard {
    width: 394px;
    position: relative;
    height: 464px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px 0 rgba(23, 23, 37, 0.08);
    border-radius: 8px;
    overflow: hidden;
}

.dateCity {
    color: #696974;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-family: var(--inter);
}

.onlinebtn {
    background-color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: white;
    font-family: var(--inter);
    border-radius: 4px 0px 0px 4px;
    padding: 6px 8px;
    position: absolute;
    right: 0;
    top: 18px;

}

.lpNewCard figure {
    margin-bottom: 0;
    width: 100%;
    height: 348px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lpNewCard figure img {
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.absoluteDesc {
    background: white;
    box-shadow: 0px -4px 8px rgba(23, 23, 37, 0.04);
    backdrop-filter: blur(7px);
    position: absolute;
    bottom: 0;
    padding: 16px;
    left: 0;
    right: 0;
    /* transition: 1.5s all;
    transition: 0.6s all; */
    /* transform: translateY(0px); */
    transition: all 1s ease;
    max-height: 117px;

}

.hovered .hoveredata {
    opacity: 9;
}

.hoveredata {
    opacity: 0;
    transition: 1s ease-in-out;

}


.hovered {
    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.147) 100%); */
    /* backdrop-filter: blur(7px); */
    max-height: 100%;
    transform: translateY(0);

}

.absoluteDesc h1 {
    font-weight: 600;
    font-size: 20px;
    font-family: var(--inter);
    line-height: 28px !important;
    margin-bottom: 4px;
    margin-top: 8px;
    /* min-height: 56px; */

}

.registerdEventes {
    max-width: 1250px;
    margin: auto;
    margin-top: 40px
}

.pastEvents {
    margin-top: 34px;
}

.registerdEventesTitle {
    letter-spacing: -0.006em;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}



.hoveredata p {
    color: #44444F;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 12px;
    font-family: var(--inter);
}

.lucnchpad {
    padding: 0;
    width: calc(100% - 64px);
}

.lucnchpadHeader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 20px;
}

.videoIcon {
    border: 0;
    padding: 0;
    background: transparent;
    position: absolute;
    transition: 0.5s all;
}

.lpNewCard:hover .videoIcon {
    transform: scale(1.1);
}

.lucnchpadHeadermain {
    margin: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;
    padding-top: 20px;

}

.lptabgrp {
    display: flex;
    gap: 40px;
    margin: 0 20px;
    border-bottom: 2px solid #e2e2ea;
}

.PMcommingsoon {
    margin-top: 26px;
}

.PMcommingsoon figure {
    display: table;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
}

.lptagNav {
    color: var(--black-dark-700) !important;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 20px;
    position: relative;
    font-weight: 400;
    font-family: var(--inter);
    white-space: nowrap;
    padding-bottom: 16px;
}

.lptagNav:hover {
    color: var(--primary-color) !important;
    cursor: pointer;
}

.lptagNav:before {
    content: " ";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
}

.lptagNav.active {
    color: var(--primary-color) !important
}

.lptagNav.active::before {
    background-color: var(--primary-color);
}

/* .hedingElements {
    height: 99px;
    overflow: hidden;
} */

.lucnchpadHeader .w_50 {
    width: 50%;
    flex: 0 0 50%;
}

.kyctext {
    display: inline-flex;
    gap: 10px;
    align-content: center;
    font-weight: 500;
}

.kyc_block {
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    gap: 20px;
    padding: 10px 20px;
    margin-left: auto;
}

.kycbtn {
    padding: 7px 12px;
    font-size: 14px;
    line-height: 22px;
    font-family: var(--inter);
}

.lunchtodyaOwl {
    margin: auto;
    position: relative;
    margin: 0 24px
}

.lunchTodyCard {
    padding: 32px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    /* max-width: 1174px; */
    margin: auto;
    overflow: hidden;
    height: 400px;
    border: 1px solid #D5D5DC;
}

.lunchTodyCard:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #171725 40%, rgba(23, 23, 37, 0) 100%);
    z-index: 0;
}

.propertytype {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: var(--inter);
    color: white;
    border: 1px solid rgba(213, 213, 220, 0.3);
    border-radius: 6px;
    padding: 8px 10px;
    display: inline-flex;
    align-items: start;
    grid-gap: 10px;
    gap: 10px;
}


.date {
    font-size: 14px;
    font-weight: 600;
    color: white;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 6px;
    padding: 10px 10px;
    line-height: 16px;

}

.dateLogo {
    gap: 8px;
}

.card_heading {
    margin-top: 24px;
    line-height: 34px !important;
}

.card_block {
    max-width: 335px;
    position: relative;
    z-index: 1;

}

.card_subheading {
    color: rgba(255, 255, 255, 0.56);
    line-height: 20px;

}

.address {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: var(--inter);
    color: white;
    border: 1px solid rgba(213, 213, 220, 0.3);
    border-radius: 6px;
    padding: 8px 10px;
    display: inline-flex;
    align-items: start;
    gap: 10px;



}

.developer {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: var(--inter);
    color: white;
    border: 1px solid rgba(213, 213, 220, 0.3);
    border-radius: 6px;
    padding: 8px 10px;
    display: inline-flex;
    align-items: start;
    gap: 10px;
}

.address svg,
.propertytype svg {
    width: 16px;
    flex: 0 0 16px;
    margin-top: 1px;
}

.developer svg {
    width: 16px;
    flex: 0 0 16px;
    margin-top: 3px;
}

.zoom {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: var(--inter);
    color: white;
    border: 1px solid rgba(213, 213, 220, 0.3);
    border-radius: 6px;
    padding: 8px 10px;
    display: inline-flex;
    align-items: start;
    gap: 10px;

}

.zoom svg {
    width: 16px;
    flex: 0 0 16px;
    margin-top: 1px;
}

.zoom_address {
    display: flex;
    gap: 8px;
    margin-top: 22px;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.onlineevent {
    font-size: 14px;
    font-weight: 600;
    color: white;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 6px;
    padding: 10px 10px;
    line-height: 16px;
}

.reservbtn {
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 18px;

}


.reservbtn .btn {
    font-family: var(--inter);
    width: 200px;
    display: block;
    text-align: center;
    border-radius: 6px;
    padding: 8px 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

}

.lanuchHead {

    margin-top: 24px;
    margin-bottom: 26px;
    /* max-width: 1250px; */
    margin-left: 24px;
    margin-right: 24px;
    line-height: 24px !important;
}

.LPfiltergroup {
    padding: 20px 0px;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
}


.lpfilter {
    display: flex;
    align-items: flex-end;
    gap: 12px;
}

.lpfilterandsearch {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

/* .upcomingLunces {
    padding: 0 24px;
} */

.upccard {
    box-shadow: 0px 20px 50px rgba(18, 17, 39, 0.08);
    border-radius: 8px;
    max-width: 400px;
    flex: 0 0 400px;
    overflow: hidden;
    height: 560px;
    position: relative;
}

.upcomingLunces {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: start;
    gap: 34px;

}

.upcarddate {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: white;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.74), rgba(0, 0, 0, 0.74)), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.147) 100%);
    backdrop-filter: blur(7px);
    border-radius: 6px;
    position: absolute;
    left: 16px;
    top: 16px;
    padding: 10px 10px;
}

.upccard figure {
    margin-bottom: 12px;
    height: 172px;
    overflow: hidden;
}

.upccard figure img {
    object-fit: contain;
    height: 100%;
    margin: auto;
    display: table;
}

.upcardheading {
    font-weight: 700;
    font-size: 20px;
    color: var(--black);
    line-height: 28px !important;
    margin-bottom: 4px;

}

.upcardDesc {
    padding: 0 16px 16px 16px;
}

.upcardsubheading {
    font-weight: 500;
    font-size: 14px;
    color: #92929D;
    line-height: 19.6px;
    margin-bottom: 0px;
}

.line {
    border-top: 1px solid rgba(208, 213, 221, 0.6);
    display: block;
    margin: 12px 0px;
}

.uptags {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 10px;

}

.uptags>div {
    border: 1px solid rgba(208, 213, 221, 0.3);
    border-radius: 6px;
    padding: 10px;
    display: inline-flex;
    align-items: flex-start;
    gap: 10px;
}

.uptags>div svg {
    width: 16px;
    flex: 0 0 16px;
    margin-top: 3px;
}

.reserbtn {
    display: block;
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-top: 12px;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
}

.lunchtoday {
    height: calc(100vh - 49px);
    overflow: auto;
    padding-bottom: 32px;
}

.upcomingtitle {
    letter-spacing: -0.006em;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 26px;

}

.ReserveSeatSidebar {

    width: 400px;
    flex: 0 0 400px;
    background-color: white;
    z-index: 9;



}

.ReserveSeatHeader .heading {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px !important;
    font-family: var(--inter);
    margin: 0;
}

.ReserveSeatHeader {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DEDEDE;
}

.ReserveSeatHeader .close {
    border: 0;
    background: white;
    padding: 0;

}

.sidebarBody {

    padding: 0 16px;
    padding-top: 28px;
    height: calc(100vh - 146px);
    padding-bottom: 10px;
    overflow: auto;
}

.sidebarBody>figure {
    position: relative;
    margin: 0;
    border-radius: 6px;
    overflow: hidden;
    height: 134px;

}

.sidebarBody .imageupalodbtngrp {
    opacity: 0;
}

.sidebarBody h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px !important;
    letter-spacing: -0.006em;
    margin-top: 20px;
    font-family: var(--inter);
    margin-bottom: 16px;

}

.sidebarBody .kycfom {
    padding: 0;
    width: 100%;
    margin-top: 12px;
}

.sidebarBody .uploaddoc:hover .imageupalodbtngrp {
    opacity: 9;
}

.sidebarBody input {
    /* background: #FAFAFB; */
    border: 1px solid #F1F1F5;
    border-radius: 8px;
}

.sidebarBody>figure:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.736064) 100%);
    z-index: 1;
}

.figContent h3 {
    line-height: 20px;
    font-size: 18px;
    font-weight: 700;
    font-family: var(--inter);
    margin-bottom: 4px;
    color: #FDFDFE;
}

.figContent p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FDFDFE;
    margin-bottom: 0;
    font-family: var(--inter);
    max-width: 80%;
}

.figContent {
    position: absolute;
    left: 12px;
    bottom: 20px;
    z-index: 2;
    font-family: var(--inter);

}

.otpBox {
    background: #FFFFFF;
    border: 1px solid rgba(213, 213, 220, 0.5);
    padding: 20px;
    box-shadow: 0px 0px 106px rgba(0, 40, 100, 0.12);
    border-radius: 6px;
    font-family: var(--inter);

}

.otpBox p {
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.005em;
    color: #696974;
    margin-bottom: 4px;
    font-family: var(--inter);
}

.otpBox p.invalid {
    color: var(--red)
}

.otpBox h3 a:hover {
    color: var(--primary-color);
}

.otpBox h3 a {
    color: rgba(0, 98, 255, 0.4) !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    font-family: var(--inter);
    text-decoration: underline !important;
}

.otpBox h3 span {
    color: rgba(105, 105, 116, 0.7);
    font-size: 12px;
}

.otpBox h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(105, 105, 116, 0.7);
    letter-spacing: 0.005em;
    font-family: var(--inter);
}

.otp {
    margin-top: 20px;
}

.otp h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px !important;
    color: var(--black);
    margin-bottom: 8px;
    font-family: var(--inter);
}

.sidebarfooter {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 20px 16px;
    justify-content: space-around;
    padding: 20px 16px;
    border-top: 1px solid #DEDEDE;
    font-family: var(--inter);

}

.sidebarfooter button {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    height: 44px;
    border-radius: 8px;

}

.sbtbtn {
    width: 210px;
    flex: 0 0 210px
}

.cancbtn {
    width: 130px;
    flex: 0 0 130px;
}

.sucessufll .lpNewCard {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(23, 23, 37, 0.08);
    border-radius: 8px;
}

.sucesmg {
    text-align: center;
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #696974;
    font-family: var(--inter);
}

.sucesscard {
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    background-color: white;
}

.sucesscard .upcardDesc {
    padding: 12px;
}

.upcominglunchessection {
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 34px;
}

.loadmore {
    margin-top: 50px;
}

.loadmorebtn {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    font-family: var(--inter);
    color: #5A5A67;
    border: 1px solid rgba(0, 98, 255, 0.4);
    border-radius: 4px;
    padding: 11px 74px;
    background-color: white;
    display: table;
    margin: auto;
    font-family: var(--inter);
}

.loadmorebtn:hover {
    background: #FAFAFB;
}

.mobileImg {
    display: none;
}

.KYCModal .heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: var(--black);
    text-align: center;
    margin-bottom: 10px;
    font-family: var(--inter);
    margin-top: 40px;

}

.KYCModal .subheading {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-family: var(--inter);
    color: #92929D;
    margin-bottom: 30px;
}

.KYCModal .kycModbtn {
    font-size: 14px;
    line-height: 16px;
    padding: 9px 94px;
    font-family: var(--inter);
}

.lpfiltertags ul li {
    background: #FFFFFF;
    box-shadow: 0px 0px 12px -2px rgba(208, 213, 221, 0.6);
    border-radius: 6px;
    font-family: var(--inter);
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #44444F;
}

.lpfiltertags {
    margin-top: 24px;
}

.lpfiltertags ul {
    display: inline-flex;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 0;
}

.navigator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0;
    margin-top: 16px;
    margin-bottom: 10px;
}

.navigator li {
    width: 8px;
    height: 8px;
    background: #DEDEDE;
    border-radius: 50%;
    ;
}

.navigator li.active {
    background: var(--primary-color);
}

.kyctitle {
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 10px;
    margin: 0 20px;
    font-weight: 600;
    padding-top: 4px;
}

.kyctitle h3 {
    font-size: 24px;
    line-height: 29px !important;
    font-family: var(--inter);
    text-align: center;
    margin-bottom: 0;
    color: var(--black);
    font-weight: 600;

}

.kyctitle p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-family: var(--inter);
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;
    color: #696974;
}

.kycfom {
    width: 345px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.kycfom .uploaddoc {
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 8px;
    width: 100%;
    height: 154px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

}

.kycfom .uploaddoc .replacebtn {
    letter-spacing: 0.1px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    font-family: var(--inter);
    border-radius: 6px;
    padding: 3px 11px;
    display: inline-flex;
    gap: 4px;
    align-items: center;
    margin-right: 4px;
}

.kycfom .uploaddoc figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.kycfom .uploaddoc figure img {
    height: 100%;
    object-fit: contain;
    margin: auto
}

.kycfom .uploaddoc .removebtn {
    width: 34px;
    height: 34px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #F1F1F5;
    border-radius: 6px;
}

.kycfom .uploaddoc .removebtn svg {
    flex: 0 0 16px;
    width: 16px;
}

.imageupalodbtngrp {
    position: absolute;
}

.kycfom .uploaddoc .uploadbtn {
    position: absolute;
    color: #0062FF;
    letter-spacing: 0.1px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    font-family: var(--inter);
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    padding: 3px 11px;
}

.kycfooter {

    background: #FFFFFF;
    border-top: 1px solid #DEDEDE;
    padding-top: 20px;
    margin: 0 20px;

}

.kycformgroup {
    padding-top: 32px;
    padding-bottom: 32px;
    height: 420px;
    overflow: auto;
}

.KYCModal .otp {
    max-width: 347px;
    margin: auto;

}

.KYCModal .otp .otpBox {
    box-shadow: unset !important;
    border: 0;
}

.otpsucessfull figure {
    width: 64px;
    height: 64px;
    display: table;
    margin: auto
}

.otpsucessfull h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    font-family: var(--inter);
    letter-spacing: 0.1px;
    color: #696974;
    text-align: center;


}

.lpSearch input {
    height: 40px;
    border-radius: 6px;
}

.lpSearch>div>div {
    top: 7px !important;
}

.LPfiltersticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: white;
    z-index: 9999;
}

.removebtn:hover svg path {
    stroke: var(--red);
}

.Detailbanner {
    height: 278px;
    flex: 0 0 278px;
}

.project_card {
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    background-color: white;
    padding: 24px;
    display: flex;
    gap: 24px;
    align-items: flex-start;
    width: 245px;
    flex: 0 0 245px;
}

.projectcardgrp {
    display: flex;
    flex-wrap: wrap;
}

.rrabox {
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    background-color: white;
    padding: 24px;
    display: flex;
    gap: 24px;
    align-items: flex-start;
    width: 522px;
}

.propartyLocation {
    max-width: 696px;
}

.exprotcsv {
    font-size: 12px;
    font-weight: 500;
    font-family: var(--inter);
    line-height: 16px;


}

.exprotcsv:hover {
    background-color: var(--primary-color);
    color: white;
}

.exprotcsv:hover svg path {
    fill: white;
}

.listitems {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 8px;
    column-gap: 4px;
    width: 700px;
    list-style-type: disc;
    margin-left: 20px;
}

.listitems li {
    width: 332px;
    font-size: 18px;
    line-height: 28px;
    color: #374151;
    font-family: var(--inter);

}

.offerlistitems {
    display: flex;
    flex-direction: column;
}

.multipledivgroup {
    padding-left: 64px;
    padding-right: 32px;
}

.gallarygrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
    row-gap: 26px;
    column-gap: 26px;
}

.gallarygrid figure {
    height: 280px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #DBE2EF;

}

.gallarygrid figure img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.downloadbtn {
    position: absolute;
    right: 8px;
    top: 8px;
    background: #FFFFFF;
    border: 1.78442px solid #DEDEDE;
    border-radius: 8px;
    width: 44px;
    height: 44px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.downloadbtn svg path {
    stroke: var(--black);
}

.viewmorebtn:hover {
    background-color: var(--black-dark-100);
}

.gallarygrid figure:hover .downloadbtn {
    opacity: 9;
}

.documntBox {
    position: relative;
    border-bottom-left-radius: 7px;
    overflow: hidden;
    border-bottom-right-radius: 7px;
}

.documntfooter {
    position: absolute;
    bottom: 0;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    width: 100%;
    overflow: hidden;
    right: 0;
    left: 0;
    padding: 10px 12px;
}

.docdownBtn:hover {
    background-color: var(--black-dark-100);
}

.docIcon {
    border-radius: 8px;
    border: 1.78442px solid #DEDEDE;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 2px 100px rgba(0, 0, 0, 0.1);
    flex: 0 0 40px;

}

.documntBox figure {
    height: 222px;
    width: 336px;

}

.documntBox figure img {
    height: 100%;
    object-fit: cover;
}

.docdownBtn {
    border-radius: 8px;
    border: 1.78442px solid #DEDEDE;
    width: 44px;
    flex: 0 0 44px;
    height: 44px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.developerLogo {
    background-color: white;
    border: 1px solid #DEDEDE;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 330px;
    flex: 0 0 330px;
    height: 156px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.developerLogo img {
    height: 100%;
    object-fit: contain;
}


.bannerTags {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    font-family: var(--inter);
    display: inline-flex;
    align-items: center;
    gap: 4px;
    color: #48FFA7;
    padding: 7px 8px;
}

.detailcontent {
    padding-top: 32px;
    padding-right: 32px;
}

.developercontactHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.developerlogo {
    width: 64px;
    height: 64px;
    display: flex;
    flex: 0 0 64px;
    align-items: center;
    justify-content: center;
    border: 1px solid #DEDEDE;
    border-radius: 50%;
    padding: 5px;
}

.developercontact {
    max-width: 372px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-shadow: 2px 4px 16px rgba(226, 226, 234, 0.6);
    border-radius: 8px;
    padding: 16px;
    margin-top: -60px;
    z-index: 1;
    position: relative;

}

.newupdateblock {
    max-width: 372px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-shadow: 2px 4px 16px rgba(226, 226, 234, 0.6);
    border-radius: 8px;
    padding: 16px;
}

.developercontactHeader {
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 16px;
}

.developerContactbtn {
    font-size: 16px;
    font-family: var(--inter);
    line-height: 26px;
    display: inline-flex;
    justify-content: center;
    gap: 8px;
    padding: 10px 10px;
    align-items: center;
    border-radius: 4px;
    font-weight: 500;
}

.developerConOtBtn:hover {
    background-color: var(--black-dark-100);
}

.developerConOtBtn {
    font-size: 16px;
    font-family: var(--inter);
    line-height: 26px;
    display: inline-flex;
    justify-content: center;
    gap: 8px;
    color: #5A5A67;
    padding: 10px 10px;
    align-items: center;
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    background: white;
    font-weight: 500;
}

.reservseatbtn {
    font-size: 16px;
    font-family: var(--inter);
    line-height: 26px;
    padding: 10px 10px;
    align-items: center;
    border-radius: 4px;
    font-weight: 500;
    margin-top: 28px;
}

.projectlaunchblcok {
    max-width: 372px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-shadow: 2px 4px 16px rgba(226, 226, 234, 0.6);
    border-radius: 8px;
    padding: 16px;
}

.eventtags {
    background: #4BCD92;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    font-family: var(--inter);
    padding: 4px 6px;
}

.projectlaunchHead {
    padding-bottom: 12px;
    border-bottom: 1px solid #DEDEDE;

}

.updatestatus {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #696974;
    font-family: var(--inter);
}

.circle {
    width: 8px;
    height: 8px;
    flex: 0 0 8px;
    background-color: var(--primary-color);
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;

}

.updatedate {
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #A4A4A4;
    font-family: var(--inter);

}

.prtnericon {
    width: 24px;
    height: 24px;
    background: black;
    border-radius: 50%;
    flex: 0 0 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: -5px;
}

.galarybtn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0;
}

.ligghtboxModdalHeader {
    background-color: #40404A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 24px;

}

.lightbackbtn {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #40404A;
    border-radius: 4px;
    margin-right: 20px;
    border: 0;
    padding: 0;
}

.lightclosebtn {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #40404A;
    border-radius: 4px;
    padding: 0;
    border: 0;
}

.lightbackbtn:hover,
.lightclosebtn:hover {
    background-color: var(--black);
}

.gallarylist {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px
}

.gallarylistarea {
    max-width: 304px;
    flex: 0 0 304px;
    overflow: auto;
    height: 652px;
    background-color: white;
    padding: 24px;
}

.sidlerarea {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navLeft {
    padding-left: 24px;
}

.navright {
    padding-right: 24px;
}

.navright button,
.navLeft button {
    border: 0;
    width: 42px;
    height: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #828283;

}

.navright button:hover,
.navLeft button:hover {
    background-color: var(--white);
}

.navright button:hover svg path,
.navLeft button:hover svg path {
    fill: #44444F;
}

@media only screen and (max-width: 1350px) {
    .lucnchpadHeader .w_50 {
        width: 100%;
        flex: 0 0 100%;
    }

    .lucnchpadHeader .w_50.w_50:nth-child(2) {
        margin-top: 18px;
    }


    .lucnchpadHeader {
        padding-left: 20px;
        padding-right: 20px;


    }

    .registerdEventes {
        padding-left: 20px;
        padding-right: 20px;
    }


    .upcominglunchessection,
    .LPfiltergroup,
    .lanuchHead {

        padding-left: 24px;
        padding-right: 24px;
    }

    .upccard {
        max-width: 380px;
        flex: 0 0 380px;
    }

    .upcomingtitle {
        margin-top: 36px;
    }

    .lunchTodyCard {
        max-width: 1050px;
    }
}

@media only screen and (max-width: 1330px) {
    .upccard {
        max-width: 365px;
        flex: 0 0 365px;
    }
}

@media only screen and (max-width: 1280px) {
    .upccard {
        max-width: 345px;
        flex: 0 0 345px;
    }

    .mainprojectdetailblock,
    .project2ndblock,
    .multipledivgroup {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media only screen and (max-width: 1210px) {
    .upccard {
        max-width: 330px;
        flex: 0 0 330px;
    }
}

@media only screen and (max-width: 1180px) {



    .lunchTodyCard {
        max-width: 1000px;
        background-size: cover !important;
    }

}

@media only screen and (max-width: 1140px) {



    .lunchTodyCard {
        max-width: 950px;
    }

    .upccard {
        max-width: 300px;
        flex: 0 0 300px;
    }
}

@media only screen and (max-width: 1080px) {

    .upcominglunchessection,
    .LPfiltergroup {
        max-width: 100%;

    }

    .lunchTodyCard {
        max-width: 90%;
        background-size: cover !important;
    }

    .lanuchHead {
        max-width: 100%;

    }

    .upccard {
        max-width: 31.333%;
        flex: 0 0 31.333%;
    }

    .upcardheading {
        font-size: 18px;
    }


}

@media only screen and (max-width: 994px) {
    .lunchTodyCard {
        background-size: cover !important;
    }

    .upccard {
        max-width: 48.333%;
        flex: 0 0 48.333%;
    }
}

@media only screen and (max-width: 850px) {

    .paymentetbale table tbody tr td:nth-child(2),
    .paymentetbale table thead tr th:nth-child(2),
    .pricisngtable table thead tr th:nth-child(2),
    .pricisngtable table tbody tr td:nth-child(2) {
        max-width: unset !important;
    }




    .registerdEventes .lpNewCard {
        width: 310px;
    }

    .gallarylistarea {
        display: none;
    }


    .upcominglunchessection .lpNewCard {
        width: 320px;
    }

    .upcominglunchessection {
        margin-top: 36px;
    }

    .upccard {
        max-width: calc(50% - 12px);
        flex: 0 0 calc(50% - 12px);
    }

    .upcominglunchessection {
        margin-right: 16px;
    }



    .LPfiltergroup {
        margin-right: 16px;
    }

    .lpfilterandsearch {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 12px;
    }

    .lpfilter {
        flex-wrap: wrap;
        width: 100%;
    }

    .upcomingtitle {
        margin-top: 0px;
    }

    .upcardheading {
        font-size: 16px;
        line-height: 18px !important;
    }

    .mobileImg {
        display: block;
    }

    .lunchTodyCard {
        background-image: none !important;
        max-width: 90% !important;
    }

    .dateLogo {
        position: absolute;
        left: 16px;
        top: 16px;
    }

    .date {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.74), rgba(0, 0, 0, 0.74)), linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.147) 100%);
    }

    .lunchTodyCarddesc {
        background: #061C34;
        padding: 12px 14px;

    }

    .linedes {
        border: 0.834821px solid rgba(208, 213, 221, 0.6);
        transform: rotate(-0.21deg);
        display: block;
        margin: 16px 0;
    }

    .zoom_address {
        margin-top: 0;
    }

    .reservbtn .btn {
        width: 100%;
    }


    .card_heading {
        margin-top: 0;
        padding-top: 0px;
    }

    .lunchTodyCard::before {
        display: none;
    }

    .card_block {
        max-width: 100%;
        margin: auto;
        border-radius: 6px;
        overflow: hidden;
    }

    .lunchTodyCard {
        padding: 0;
        border-radius: 6px;
        overflow: hidden;
    }


    .lucnchpadHeader {
        border: none;
    }

    .lunchtoday {
        height: calc(100vh - 100px);
    }

    .sidebarBody {
        height: calc(100vh - 190px);
    }

    .lpfiltertags ul {
        flex-wrap: wrap;
    }

    .lpSearch,
    .lpSearch input {
        width: 100%;
    }

    .lunchtodyaOwl {
        padding-left: 24px;
        padding-right: 24x;
    }

    .absoluteDesc {
        position: relative;
        position: relative;
        max-height: unset;
        height: calc(100% - 200px);
    }

    .absoluteDesc button {
        position: absolute;
        bottom: 16px;
        left: 16px;
        right: 16px;
        width: auto !important;

    }

    .absoluteDesc h1 {
        font-size: 16px;
        line-height: 24px !important;
    }

    .lpNewCard figure {
        height: 200px;
    }

    .hoveredata {
        opacity: 9;
    }

    .registerdEventes .lpNewCard {
        height: 330px;
    }

    .lpNewCard {
        width: auto;
        height: 410px;
    }

    .mainprojectdetailblock,
    .project2ndblock,
    .multipledivgroup {
        padding-left: 16px;
        padding-right: 16px;
    }

    .developercontact {

        order: 2;
    }

    .developercontact,
    .projectlaunchblcok,
    .newupdateblock {
        width: 100%;
        max-width: 100%;
    }

    .detailcontent {
        padding-right: 0;
    }

    .listitems {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .projectcardgrp {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: auto;
        width: 100%;
        max-width: 100%;
        padding: 10px 5px
    }

    .projectcardgrp::-webkit-scrollbar {
        opacity: 0;
        display: none;

    }


}

@media only screen and (max-width: 767px) {
    .projectdetailPage {
        width: 100%;
        flex: 0 0 100%;
    }

    .detailedit {
        margin-right: 0;
        margin-right: 0px;
        white-space: nowrap;
        justify-content: center;
        padding: 12px 0;
    }
}

@media only screen and (max-width: 600px) {
    .msingleline {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        white-space: pre-wrap;
    }

    .offerlist {
        padding-right: 0;
    }

    .develoerdetail h1 {
        margin-bottom: 16px !important;
    }

    .developerLogo {
        margin-bottom: 16px;
    }

    .sticky_top {
        padding-right: 0;
        padding-left: 10px;
    }

    .scrollspynavbar ul {
        gap: 18px;
        padding-left: 10px;
        width: 100%;


    }

    .scrollspynavbar {
        padding-left: 0 !important;
    }

    .detailedit {
        margin-right: 0;
        margin-right: 0px;
        white-space: nowrap;
        justify-content: center;
        padding: 12px 0;
    }

    .upcominglunchessection .lpNewCard {
        width: 100%;
    }

    .registerdEventes .lpNewCard {
        width: auto;
    }

    .registerdEventes .lpNewCard {
        height: 410px;
    }

    .lpNewCard {
        width: 320px;
        height: 410px;
    }

    .openmodalinMobile {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .upcominglunchessection figure {
        height: unset !important;
    }

    .upcominglunchessection .lpNewCard {
        height: unset;

    }

    .upcominglunchessection .absoluteDesc {
        height: unset;
        max-height: unset;
    }

    .upcominglunchessection .absoluteDesc button {
        position: relative !important;
        width: 100% !important;
        left: 0;
        bottom: unset;

    }

    .upcomingLunces {
        gap: 16px;
    }

    .pastEvents {
        margin-top: 16px !important;
    }

    /* 
    .registerdEventes .hoveredata {
        display: none;
    } */

    .hoveredata p {
        font-size: 14px;
    }

    .dateCity {
        font-size: 12px;
    }

    .registerdEventesTitle {
        margin-bottom: 8px;
    }

    .registerdEventes {
        padding-left: 16px;
        padding-right: 0px;
        margin-top: 5px;
    }



    .upcominglunchessection,
    .LPfiltergroup,
    .lanuchHead {

        padding-left: 16px;
        padding-right: 16px;
        margin-top: 16px;
        margin-bottom: 0px;

    }

    .upcomingtitle {
        margin-bottom: 16px;
    }


    .lunchtodyaOwl {
        padding-left: 16px;
    }


    .lucnchpadHeader {
        padding-left: 16px;
        padding-right: 8px;
    }


    .lucnchpadHeader .w_50.w_50:nth-child(2) {
        margin-top: 12px;
    }

    .clearbtn {
        height: 36px;
    }

    .upcominglunchessection,
    .LPfiltergroup {
        margin-right: 0;
    }

    .lpSearch,
    .lpSearch input {
        width: 100%;
    }

    .ReserveSeatSidebar {
        width: 100%;
        flex: 0 0 100%;
    }

    .upccard figure {
        height: auto
    }

    .hedingElements {
        height: auto;
    }

    .upccard {
        height: unset;
    }

    .reserbtn {
        position: relative;
        left: unset;
        right: unset;
        bottom: unset;
        width: 100%;
    }

    .kyctitle h3 {
        font-size: 18px;
        line-height: 24px !important;
    }

    .kyctitle p {
        font-size: 12px;
    }

    .KYCModal .kycModbtn {
        padding-left: 0;
        padding-right: 0;
        width: 256px;
        text-align: center;
    }

    .kycformgroup {
        height: 300px;
        padding-top: 24px;

    }

    .KYCModal .heading {
        font-size: 20px;
        line-height: 24px !important;
        margin-top: 35px;
    }

    .KYCModal .subheading {
        margin-bottom: 12px;
        font-size: 14px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .kycfom {
        width: 100%;
        padding: 0 24px;
    }

    .lunchtoday {
        height: calc(100vh - 120px);
    }

    .upccard {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .lucnchpadHeader .w_50:nth-child(1) {
        width: 100%;
        flex: 0 0 100%
    }





    .lpfiltertags ul {
        flex-wrap: wrap;
    }

    .kyctitle {
        font-size: 18px;
        line-height: 20px !important;
    }

    .sidebarfooter button {
        width: 50%;
        flex: 0 0 50%;
    }

    .sidebarBody {
        height: calc(100vh - 220px);
    }

    .lpfilterandsearch>div>div {
        width: 100%;
    }

    .multipledivgroup>div {
        margin-bottom: 42px;
    }

    .detailcontent {
        padding-top: 24px;
    }

    .detailcontent h1 {
        font-size: 20px;
        margin-bottom: 12px;

    }

    .detailcontent p {
        font-size: 16px;
        line-height: 24px;
    }

    .projectcardgrp {
        margin-top: 10px;
    }

    .project2ndblock h1 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .rrabox {
        width: 100%;
    }

    .propartyLocation h1 {
        font-size: 20px;
        line-height: 24px !important;
        margin-bottom: 14px !important;
    }

    .propartyLocation p {
        font-size: 14px;
        line-height: 16px;
    }

    .configrationblocl h1,
    .paymentblock h1,
    .bakdetialblock h1,
    .listblock h1,
    .mediaBlock h1,
    .documnetblock h1,
    .develoerdetail h1 {
        font-size: 20px;
        line-height: 24px !important;

        margin-bottom: 0 !important;
    }

    .mediaBlock h2 {
        font-size: 16px;
        line-height: 20px !important;

    }

    .listitems li {
        font-size: 16px;
        line-height: 24px;
    }

    .offerlist {
        font-size: 16px;
    }

    .listitems {
        margin-bottom: 0;
    }

    .downloadbtn {
        opacity: 9 !important;
    }

    .documntBox {
        width: 100%;
    }

    .documntBox figure,
    .documntBox figure img {
        width: 100%;
    }

    .developerLogo {
        width: 100%;
        height: 150px;
        flex: 0 0 150px
    }

    .develoerdetail p {
        margin-top: 18px;
        font-size: 14px;
        line-height: 18px !important;
    }

    .listblock {
        overflow: hidden;
    }

    .develoerdetail {
        padding-bottom: 40px;
    }

    .rrabox h5 {
        white-space: normal;
        word-break: break-all;
    }
}