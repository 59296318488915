.completebtn {
    display: flex;
    align-items: center;
    white-space: normal;
    grid-gap: 12px;
    gap: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--inter);
    color: #92929D !important;
    border: 1px solid #D5D5DC;
    border-radius: 6px;
    padding: 7px 20px;
    background-color: white;

}

.btn_group .liveinbtn:hover {
    background-color: white !important;
    border: 1px solid #D5D5DC !important;
    color: #92929D !important;
}


.completebtn:hover {
    cursor: no-drop;
}


.videovisable:hover .videoplaybtn {
    opacity: 9;
}

.videoplaybtn {

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000060;
    border-radius: 6px;
    transition: 0.3s all;
    opacity: 0;

}

.LDP_breadcrumb ul li.pagetitle span {
    white-space: nowrap;
    width: 440px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.other_relivant_block {
    max-width: 950px;
}

.mentorOwl {
    max-width: 980px;
}

.mentorProfile {
    margin-bottom: 24px;
}

.mentorProfile p {
    padding-right: 0 !important;
}

.CourseDetailBlock {
    padding-right: 16px;
}

.learingMainSection {
    padding-right: 0 !important;

}

.coursDetailMainSection {
    padding-right: 0;
}

.CourseDetails_page {
    height: 100%;
    overflow-y: auto;
}

.LDP_breadcrumb a {
    color: #6E7C87;
    font-size: 14px;
    font-family: var(--inter);
    letter-spacing: -0.006em;
    line-height: 24px;
    font-weight: 400;

}

.LDP_breadcrumb a:hover {
    color: var(--black);
}

.LDP_breadcrumb a.active {
    color: var(--black);
}

.LDP_breadcrumb a:not([href]):not([tabindex]) {
    color: #6E7C87;
}

.LDP_breadcrumb ul {
    gap: 6px;
}

.LDP_breadcrumb ul li {
    display: flex;
    align-items: center;
    gap: 6px
}

/* 
.CourseDetailBlock {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
} */

/* 
.CourseDetailBlock .CourseDetail_left_block {
    max-width: 62%;
    flex: 0 0 62%;
} */

.CourseDetailBlock .CourseDetail_left_block h1 {
    letter-spacing: 0.15px;
}

.CourseDetailBlock .CourseDetail_left_block figure {
    border-radius: 6px;
    overflow: hidden;
}

.CourseDetailBlock .CourseDetail_left_block p {
    letter-spacing: 0.005em;
    line-height: 24px;
    padding-right: 50px;

}


.live_section .live_header {
    padding: 24px;
}

.live_section {
    /* max-width: 33%; */
    /* flex: 0 0 33%; */
    border: 1px solid #D5D5DC;
    box-shadow: inset 0px 0px 20px 20px rgba(226, 226, 234, 0.05);
    border-radius: 8px;
    margin-top: 62px;

}

.live_header_footer {
    gap: 16px
}

.livebtn {

    background: linear-gradient(131.62deg, #2976F6 -15.57%, #9800FF 172.51%);
    border-radius: 6px;
    padding: 8px 20px;
    font-family: var(--inter);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border: 0;
    color: white;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    white-space: nowrap;

}

.learnmorebtn {
    color: #92929D;
    padding: 8px 26px;
    border: 1px solid #D5D5DC;
    border-radius: 6px;
    font-family: var(--inter);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background-color: white;

}

.learnmorebtn:hover {
    color: #92929D;
    background-color: var(--black-dark-200);
}

.live_header h1 {
    letter-spacing: 0.15px;
    line-height: 26px;

}

.live_body {
    padding: 24px;


}

.live_header+.live_body {
    border-top: 1px solid #D5D5DC;
}

.live_mentro_group {
    display: flex;
    flex-direction: column;
    gap: 26px;


}

.mentorProfile {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.mentorProfile figure {
    max-width: 48px;
    flex: 0 0 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
}

.mentorProfile figure img {
    object-fit: cover;
    width: 48px !important;
    height: 48px;
    border-radius: 50%;
}

.mentorProfile .mentorProfileDescription {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px
}

.CourseOutline {
    overflow: hidden;
    margin-top: 48px
}

.liveRecordGuidTxt {
    gap: 14px
}

.liveRecordGuidTxt>div {
    gap: 4px
}

.live_Recording_guide_block {
    border: 1px solid #D5D5DC;
    border-radius: 6px;
    margin-top: 24px;
}


.CourseCard_mainBlock {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 24px;
}

.CourseCard_mainBlock h2 {
    line-height: 32px;
    margin-top: -5px;
}


.CourseCard .card_right p {
    line-height: 20px;

}

.CourseCard .card_right h3 {
    gap: 4px;

}

.CourseCard .card_right h3 span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 350px;
}

.CourseCard {
    padding: 24px;
    border-radius: 6px;

}

.CourseCard:hover {
    outline: 1px solid #3D39F5;
    border-radius: 6px !important;
}

.CourseCard {
    border-bottom: 1px solid #D5D5DC;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.live_Recording_guide_block .CourseCard:last-child {
    border-bottom: 0;
}

.btn_group {
    gap: 16px;
}

.btn_group a .btn_group button {
    background-color: white;
}

.btn_group button:hover,
.btn_group a:hover,
.viewCourseCardBtn:hover {
    background-color: #3D39F5 !important;
    color: white !important;
    border-color: #3D39F5 !important;
}


.btn_group button.active {
    background-color: #3D39F5;
    color: white;
    border-color: #3D39F5;
}

.btn_group .playbtn:hover svg circle {
    stroke: white;
}

.btn_group .playbtn:hover svg path {
    fill: white;
    stroke: white;
}

.btn_group .joinnow:hover svg rect,
.btn_group .joinnow:hover svg circle,
.btn_group .joinnow:hover svg path {
    stroke: white;
}

.btn_group .downloadbtn:hover svg path {
    fill: white;
}

.btn_group button,
.btn_group a {
    display: flex;
    align-items: center;
    white-space: normal;
    gap: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--inter);
    color: #92929D !important;
    border: 1px solid #D5D5DC;
    border-radius: 6px;
    padding: 7px 20px;
    background-color: white;
}

.viewCourseCardBtn {
    display: flex;
    align-items: center;
    white-space: normal;
    gap: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--inter);
    color: #92929D;
    border: 1px solid #D5D5DC;
    background-color: white;
    border-radius: 6px;
    padding: 7px 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;

}


.btn_group button:hover svg path,
.viewCourseCardBtn:hover svg path {
    fill: white;
}

.WhatYouLearn {
    max-width: 383px;
    flex: 0 0 383px;
    border: 1px solid #D5D5DC;
    border-radius: 8px;
    margin-top: 66px;
}

.WhatYouLearn ul li {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    font-size: 14px;
    line-height: 24px;
    font-family: var(--inter);
    color: #696974;
    padding-right: 20px;

}

.WhatYouLearn ul li svg {
    max-width: 32px;
    flex: 0 0 32px;
}

.WhatYouLearn ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.get_certified {
    padding: 20px;
    border-top: 1px solid #D5D5DC;

}

/* .MentorCard figure img {
    max-width: 230px;
} */

.MentorCard h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #101828;
    margin-bottom: 4px;
    font-family: var(--inter);
}

.MentorCard h3 {
    font-family: var(--inter);
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #667085;

}

.MentorCard p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--inter);
    margin-bottom: 0;
    color: #667085;
    padding-right: 10px;
}

.MentorCard ul {
    margin-top: 24px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: self-start;
    justify-content: flex-start;
}

.MentorCard ul li {
    width: calc((100% / 3) - 10px);
}

.mentor {
    margin-top: 58px;
}

.certificationBy {
    margin-top: 82px;
}

.certificationBy ul {
    list-style: disc;

    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 560px;

}

.certificationBy ul li {
    font-weight: 500;
    font-size: 16px;
    font-family: var(--inter);
    color: #696974;
    line-height: 24px;
    text-align: center;
}

.mentor .owl-theme .owl-nav {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    top: 47% !important;
}

.OtherRelevantCourses h1,
.OtherRelevantCourses p,
.OtherRelevantCourses div {
    font-family: var(--inter);
}

.releventcoursecard {
    padding-bottom: 0;

}

.faq {
    max-width: 885px;
    flex: 0 0 885px;
    margin: 58px auto 0 auto;
}

.faq_title {
    background: linear-gradient(131.62deg, rgba(41, 118, 246, 0.1) -15.57%, rgba(82, 0, 255, 0.1) 172.51%);
    border-radius: 20px;
    padding: 60px;
    font-weight: 600;
    font-size: 36px;
    line-height: 32px;
    font-family: var(--inter);
    color: #3D39F5;
}

.faq_ac_area {
    margin-top: -30px;
    width: calc(100% - 42px);
    margin-left: auto;
    margin-right: auto;
}

.faq_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: 600;
    font-size: 16px;
    color: var(--black-dark-800);
    line-height: 26px;
    font-family: var(--inter);
    cursor: pointer;
}

.faq_body {
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    color: var(--black-dark-700);
    line-height: 26px;
    font-family: var(--inter);
    padding-bottom: 20px;
}

.faq .card {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 2px 4px 16px rgba(226, 226, 234, 0.6);
    margin-bottom: 24px;
}

.faq_header[aria-expanded="true"] .faq_icon {
    transform: matrix(1, 0, 0, -1, 0, 0);
    background: #3D39F5;
}

.faq_icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex: 0 0 24px;
    cursor: pointer;
}

.faq_icon:hover {
    background: #3D39F5;

}

.faq_header[aria-expanded="true"] .faq_icon svg path,
.faq_icon:hover svg path {
    fill: white;
}


.releventcoursecard figure img {
    height: auto;
}



@media only screen and (min-width: 1600px) {
    .releventcoursecard figure img {
        max-height: unset !important;
    }


}

@media (max-width: 1280px) {

    .CourseDetailBlock {
        padding-right: 0;
        overflow: hidden;
    }
}

@media (max-width: 1200px) {
    .live_section {
        margin-top: 24px !important;
    }

    .mentorOwl {
        max-width: 92%;
    }

    .other_relivant_block {
        max-width: 82%;
    }

    .CourseDetailBlock {
        flex-wrap: wrap;
    }

    .CourseDetailBlock .CourseDetail_left_block,
    .CourseDetailBlock .live_section {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .CourseDetailBlock .live_section {
        margin-top: 0;
    }

    .CourseDetailBlock .CourseDetail_left_block p {
        padding-right: 0;
    }

    .WhatYouLearn {
        margin-top: 0;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .CourseOutline {
        margin-top: 24px;

    }

    .get_certified .row .col-5 {
        align-items: center;
        display: flex;
    }

    .get_certified p {
        font-size: 24px;
    }

    .MentorCard p {
        padding-right: 0px;
    }


}

@media (max-width: 992px) {
    .other_relivant_block {
        max-width: 100%;
    }

    .mentorOwl {
        max-width: 100%;
    }

    .viewCourseCardBtn:hover {
        color: #92929D !important;
        border: 1px solid #D5D5DC !important;
        background-color: white !important;
    }


}

@media (max-width: 768px) {
    .videoplaybtn {
        opacity: 9;
    }

    .get_certified p {
        font-size: 16px;
        margin-top: 12px;
        padding-left: 18px;
    }

    .get_certified img {
        margin-left: auto;
        margin-right: auto;
        display: table;

    }

    .certificationBy {
        margin-top: 36px;
    }

    .certificationBy ul {
        align-items: flex-start;
        margin-left: 24px;
    }

    .certificationBy ul li {
        text-align: left;
    }

    .relevantCourses {
        margin-top: 53px;
    }

    .get_certified img {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .completebtn {
        justify-content: center;
    }

    .viewCourseCardBtn svg path {
        fill: #696974 !important;
    }

    .LDP_breadcrumb ul li.pagetitle span {
        width: 120px;
    }

    .btn_group {
        width: 100%;

    }

    .CourseCard_mainBlock h2 {
        margin-top: 0;
    }

    .CourseDetailBlock {
        padding-right: 0;
        overflow: hidden;
    }

    .get_certified img {
        width: 100%;
    }

    .coursDetailMainSection {
        padding-left: 15px;
    }

    .CourseDetails_page {
        padding-right: 12px;
    }

    .OtherRelevantCourses {
        padding-bottom: 0px;
    }

    .OtherRelevantCourses .releventcoursecard {
        padding-bottom: 0 !important;
    }

    .faq .card {
        padding: 15px;
    }

    .live_section .live_header {
        padding: 24px 14px;
    }

    .CourseCard {
        padding: 14px
    }

    .mentor {
        margin-top: 24px;
    }

    .btn_group {
        flex-direction: column;
        flex-wrap: wrap;
    }

    .btn_group a,
    .btn_group button {
        width: 100%;
        justify-content: center;
        background: #3D39F5;
        color: white !important;
    }

    .get_certified {
        padding: 14px;
    }

    .btn_group .playbtn svg path {
        fill: white;
        stroke: white;
    }

    .btn_group .playbtn svg circle {
        stroke: white;
    }

    .btn_group .joinnow svg rect,
    .btn_group .joinnow svg circle,
    .btn_group .joinnow svg path {
        stroke: white;
    }

    .btn_group .downloadbtn:hover svg path {
        fill: white;
    }

    .btn_group button svg path,
    .viewCourseCardBtn svg path {
        fill: white;
    }

    .btn_group .downloadbtn svg path {
        fill: white;
    }

    .LDP_breadcrumb ul li span.active {
        white-space: nowrap;
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .CourseCard .card_right h3 span {
        width: 250px;

    }

    .live_header_footer a,
    .live_header_footer button {
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 8px 20px;
    }

    .CourseCard_mainBlock .card_right {
        width: 100%;
        display: block;
    }

    .faq {
        margin-top: 48px;
    }

}

@media (max-width: 320px) {

    .LDP_breadcrumb ul li.pagetitle span {
        width: 60px;
    }
}