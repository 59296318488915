#collapseSize .form-control {
    padding-left: 8px !important;
    border-radius: 8px !important;
}

.selectsize div {
    font-family: var(--popins) !important;
}

.filter-applyed .owl-theme .owl-nav {
    top: 1px;
}

.filter-applyed .owl-theme .owl-nav button.owl-prev {
    left: -4px;
}

.filter-applyed .owl-theme .owl-nav button.owl-next {
    right: 0;
}

.owl-theme .owl-nav [class*=owl-]:hover img,
.filter-applyed .owl-theme .owl-nav button.owl-next:hover img {
    filter: grayscale(1);
}

.filter-applyed .owl-carousel .owl-stage-outer {
    padding: 2px 4px;
    margin-right: 40px;
}

.assigned-drop>button span>span {
    font-size: calc(var(--value) * 6);
    margin-left: 5px;
    color: var(--primary-color);
    background: var(--Blue-100);
    padding: 1px 8px;
    line-height: 22px;
    letter-spacing: 0.1px;
    border-radius: 14px;
}

#collapseSize .css-319lph-ValueContainer {
    padding-right: 0 !important;
}

.display-col-tab-header .owl-nav button {
    width: 20px;
    height: 20px;
    top: -16px !important;
}

.display-col-tab-header ul li a:hover {
    color: var(--primary-color);
}

.filter .all-fliters .css-1s2u09g-control,
.filter .all-fliters .css-1pahdxg-control {
    min-height: 44px;
    border-radius: 8px;
}

.display-col-tab-header ul#pills-tab li a:hover {
    color: var(--primary-color);
}


.filter .all-fliters .search-filter .search-input .form-control {
    height: 44px;
}

.filter .all-fliters .search-filter .search-input .close-icon {
    top: 10px;
}

.filter .all-fliters .search-filter .search-input .serch-icon {
    top: 11px;
}

.display-col-tab-header .btn-right:hover svg path:nth-child(1),
.display-col-tab-header .btn-left:hover svg path:nth-child(1) {
    fill: #44444F;
}

.display-col-tab-header .btn-right:hover svg path:nth-child(2),
.display-col-tab-header .btn-left:hover svg path:nth-child(2) {
    fill: white;
}

.display-col-tab-header .owl-nav button {
    top: -20px;
}

.leadsDisplayColumns .search-input .close-icon {
    top: 15px;
}

.main-filter.leadsDisplayColumns .tab-pane .search-input input.searchinput {
    font-size: 16px !important;
    border-radius: 4px;
    height: 44px;
    padding-left: 40px !important;
}

.main-filter.leadsDisplayColumns .tab-pane .search-input.form-group {
    margin-left: 22px;
    margin-right: 10px;
    margin-bottom: 16px !important;
}

.leadsDisplayColumns .search-input .serch-icon {
    top: 9px
}

.main-filter.leadsDisplayColumns .search-input input.searchinput::placeholder {
    font-size: 16px;
}

.leadsDisplayColumns .search-input input {
    font-size: 16px;
}


.display-col-tab-header ul li a {
    position: relative;
}

.display-col-tab-header ul li a.active::before {
    opacity: 9;
}

.display-col-tab-header ul li a:before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    /* background: var(--primary-color); */
    bottom: 0px;
    z-index: 99;
    opacity: 0;
}

/* .display-col-tab-header .owl-carousel .owl-stage-outer {
    padding-bottom: 8px;
} */

.display-col-tab-header {
    width: 100%;
    margin-top: 16px;
    position: relative;
    padding-left: 0px;
    padding-right: 26px;
    border-bottom: 1px solid var(--black-dark-400);
}

.display-col-tab-header .btn-left {
    position: absolute;
    left: -20px;
    top: 0;
    z-index: 9;
}


.display-col-tab-header .btn-right {
    position: absolute;
    top: 0px;
    right: -10px;
}



.display-col-tab-header ul li a {
    padding: 0;
    color: #696974;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.006em;
    padding-bottom: 8px;
}

.display-col-tab-header ul#pills-tab {
    width: 100%;
    overflow-x: scroll;
    flex-wrap: nowrap;
}

.display-col-tab-header ul#pills-tab::-webkit-scrollbar {
    display: none;
}

.display-col-tab-header ul li a.active {
    color: var(--primary-color) !important;
    font-weight: 600 !important;
    background-color: transparent !important;
}

.display-col-tab-header ul#pills-tab li+li {
    margin-left: 32px;
}




.disbale-column:hover {
    background: white !important;
}

.disbale-column:hover .item {
    border-color: #E2E2EA !important;
}

button.cust-col-btn {
    border: 1px solid #D5D5DC;
    border-radius: 2px;
    width: 22px;
    height: 22px;
    padding: 0;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
}

button.cust-col-btn:hover {
    background: #E2E2EA;
}

.leadsDisplayColumns .itms-body>div:not(.dragable),
.leadsDisplayColumns .itms-body .dragable>div {
    margin-bottom: 5px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 10px;
    padding-left: 2px;
}

.leadsDisplayColumns .itms-body>div:not(.dragable):hover,
.leadsDisplayColumns .itms-body .dragable>div:hover {
    background: #F1F1F5;
}

.leadsDisplayColumns .filter-sticky .close {
    position: absolute;
    right: 10px;
    top: 22px;
}

.leadsDisplayColumns .itms-body label.item {
    margin-bottom: 0;
}

/* .leadsDisplayColumns .filter-sticky {
    border-bottom: 1px solid var(--black-dark-400);
} */

.leadsDisplayColumns .itms-body .dragable>div {
    margin-bottom: 5px !important;
}

.disbale-column .item .item-label {
    color: #92929D !important;
}


.leadsDisplayColumns .itms-body>div:not(.dragable):hover .item,
.leadsDisplayColumns .itms-body .dragable>div:hover .item {
    border-color: var(--primary-color);
}

.leadsDisplayColumns .itms-body>div:not(.dragable):hover .item,
.leadsDisplayColumns .itms-body .dragable>div:hover .item {
    background: white;
    border-color: 0;
}

.leadsDisplayColumns .itms-body .item .item-box {
    margin-bottom: 0;
}

.leadsDisplayColumns .itms-body .item .item-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #44444F;
    letter-spacing: 0.5px;
    font-family: var(--roboto);
}

.leadsDisplayColumns .itms-body .item {
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    padding: 10px 12px;
    width: 100%;

}

.edit-input-modal button {
    padding-right: 0;

}

.leadsDisplayColumns .itms-body .item input {
    width: 16px;
    height: 16px;
    position: relative;
}

.leadsDisplayColumns .itms-body .item .item-label+div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tabs #customer .col-xl-4:nth-child(4) input {
    height: unset;
}

.enablereengagementmodal .modal-dialog.modal-dialog-centered {
    max-width: 660px;
}

.enablereengagementmodal p {
    text-align: center;
    padding: 0 15px !important;
}

.enablereengagementmodal .csv-export-modal .modal-footer button {
    padding-left: 16px;
    padding-right: 16px;
}

.duplicate-leads-blocks .svg-icons {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 20px;
}

.duplicate-leads-blocks {
    padding-top: 8px;
    padding-right: 38px;
    padding-bottom: 16px;
    border: 1px solid #E2E2EA;
    border-left: 4px solid #0062FF;
    border-radius: 4px;
}

.map-fileds .row .col-md-6:nth-child(1) {
    color: #171725;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-family: var(--popins);
}

.map-fileds .row .col-md-6:nth-child(2) {
    padding-left: 20px;
}

/* .modal-open #root {
    backround-blend-mode: overlay, normal;
    -webkit-backdrop-filter: blur(40px);
    filter: blur(1px);
}

.fade.modal-backdrop.show {
    background: rgba(0, 0, 0, 0.45);
    backdrop-filter: blur(2.5px);
} */

.duplicat-lead-list {
    width: 336px;
    margin: auto;
}

.duplicat-lead-list .row {
    border-bottom: 1px solid #E2E2EA;
    padding: 10px 0;
}

.duplicat-lead-list .row .col-5 {
    color: #92929D;
}

.duplicat-lead-list .row .col-5,
.duplicat-lead-list .row .col-7 {
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #44444F;
    font-family: var(--roboto);
}

.duplicat-lead-list .row .col-7 {
    font-weight: 500;
}

.leadduplication .modal-footer {
    margin-top: 24px;
}

.leadduplication .modal-footer button.outline-btn {
    padding: 8px 30px;

}

.leadduplication .modal-footer button {
    font-weight: 500;
    font-size: 16px;
}

.leadduplication .modal-footer button.pr-btn {
    padding: 8px 30px;
    margin-left: 18px;

}

.leadduplication .modal-dialog {
    max-width: 500px;
    overflow: hidden;
}

.leadduplication .modal-body {
    padding: 35px 30px 24px 30px;
    border: 0;
}

.leadduplication .modal-body a.close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.manage-account-title p {
    margin-left: 32px;
}

.user-leads-details .main-section {
    background-color: var(--black-dark-100);
}

ul.tags {
    display: flex;
    margin-bottom: 0;
    padding: 0;
    max-width: 100%;
    overflow: auto;
    position: relative;


}

.tag-scrolling.tags {
    max-width: 86%;
    margin-left: 40px;
}


.filter-applyed .btn-left,
.filter-applyed .btn-right {
    position: absolute;
}

.filter-applyed .btn-left {
    left: 100px;
    top: 13px;
}

.filter-applyed .btn-right {
    right: 10px;
    top: 13px;
}

.filter-applyed .tags {
    padding-top: 12px;
    padding-bottom: 4px;
}

.intent-drop .dropdown-menu a {
    color: var(--black-dark-700);
    padding: calc(var(--value) * 2) calc(var(--value) * 4);
    border-radius: calc(var(--value) * 2);
    font-size: calc(var(--value) * 7);
    line-height: calc(var(--value) * 11);

}

ul.tags>button {
    position: absolute;
}

ul.tags>button.btn-left {
    left: 0;
}

ul.tags>button.btn-right {
    right: 0;
}


ul.tags li {
    margin-right: 12px;
    padding: calc(var(--value)*3) calc(var(--value)*4);
    color: var(--black-dark-800);
    font-size: 12px;
    margin-right: 12px;
    box-shadow: 0 0 2px #92929d;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: relative;
    flex-wrap: nowrap;
    FLEX: 0 0 auto;
}

ul.tags li svg {
    margin-left: 8px;
    flex: 0 0 16px;

}

ul.tags::-webkit-scrollbar {
    display: none;
}

.filter-applyed>span {
    flex: 0 0 auto;
    margin-top: 6px;

}

ul.tags li svg:hover rect {
    stroke: var(--primary-color);

}

ul.tags li svg:hover path {
    fill: var(--primary-color);
}

.filter-applyed .tags .btn-primary {
    position: absolute;

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
}

ul.tags li:hover {
    box-shadow: 0 0 2px var(--primary-color) !important;
}

.intent-drop .dropdown-menu a {
    background: transparent;
}

.intent-drop .pr-btn:hover {
    background-color: var(--primary-color);
    color: white;
}

.assigned-drop>button {
    border-color: var(--black-dark-500);
    color: var(--black);
}

.intent-drop .dead-btn:hover {
    background-color: var(--red);
    color: white;
}

.short-btn .assigned-drop button {
    width: 100%;
}

.intent-drop .hot-btn:hover {
    background-color: var(--green);
    color: white;
}

.intent-drop .warm-btn:hover {
    background-color: var(--orange);
    color: white;
}

.intent-drop .cold-btn:hover {
    background-color: var(--blue);
    color: white;
}

.short-btn>button:after,
.filter-dropdown > button:after {
    display: none;
}


.intent-drop>button {
    display: flex;
    justify-content: space-between;
    min-width: 127px;
    max-width: 127px;
    align-items: center;
    height: 34px;
    font-weight: 500;
}

.lead-title>a {

    color: var(--black-dark-800);
    padding: 4px 8px;
    line-height: 22px;
    border-radius: 4px;
}

.lead-title a img {
    margin-left: 4px;
}

.lead-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.lead-box .box {
    max-width: 254px;
    flex: 0 0 254px;
    background: white;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.lead-box .box+.box {
    margin-left: 20px;
}

.lead-box .box h2 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: var(--black-dark-700);
    margin-bottom: 12px;
    font-weight: var(--po-medium);
}

.lead-box .box p {
    margin: 0;
    font-size: 24px;
    color: var(--black);
    letter-spacing: 0.24px;
    line-height: 30px;
    font-weight: var(--po-semi);
}

.all-leads,
.permission-table {
    position: relative;
    background: white;
    height: 100%;
    overflow: hidden;
}

.all-leads-head {

    z-index: 9;
    background: white;
}


.table tbody tr td:nth-child(1) {
    padding-left: 0px !important;
    padding-right: 0 !important;
    text-align: center;
}

.lead-table .table {
    color: var(--black) !important;
    font-size: 14px;
    line-height: 22px;
    left: 0.1px;
}

.lead-table .table thead {
    background-color: #FAFAFB;
    position: sticky;
    top: 0;
    z-index: 9;
}

.column-filter button:hover svg path,
.column-filter button.active svg path {
    fill: var(--primary-color);
}

.lead-table .table thead tr {
    border-bottom: 0.5px solid rgb(213 213 220 / 60%) !important;
}

.recent-activites .box:last-child:before {
    display: none;
}

.lead-table .table thead tr th {

    color: var(--black-dark-800);
    background-color: white;

}

.lead-table .table thead tr th span {
    margin-left: 0 !important;
}

.lead-head {

    z-index: 2;

}

.lead-table .table {
    margin-bottom: 0;
}


.lead-table {
    position: relative;
    min-width: 100%;
    overflow: auto;
    padding: 0 0px 0px 0px;
    z-index: 1;
    overscroll-behavior: none;
}

.inventory-table {
    position: relative;
    min-width: 100%;
    overflow: auto;
    padding: 0 0px 0px 0px;
    min-height: calc(100vh - 322px);
    max-height: calc(100vh - 322px);
    z-index: 1;
    overscroll-behavior: none;

}



.table tbody tr:nth-child(1) td {
    border: 0;

}

.table tbody tr td:nth-child(2) {
    padding-left: 16px !important;

}

.table tbody tr th {
    border-top: 0.5px solid #D5D5DC;
    padding-top: 16px;
    padding-bottom: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    font-size: 14px;
    vertical-align: middle;
    font-weight: 400;
    white-space: nowrap;
}

.new-leads a.v-line,
.old-leads a.v-line {
    padding-left: 16px;
}

a.v-line {

    position: relative;
}

.table tbody tr:not(.new-leads, .old-leads) .v-line::before {
    display: none;
}

.project-td span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
}

.project-td .dropdown-item:hover {
    background: transparent !important;
}

.v-line:before {
    content: " ";
    position: absolute;
    left: 0;
    top: -10px;
    bottom: 0;
    width: 4px;
    height: 40px;
    border-radius: 30px;
}

.new-lead:before {
    background: var(--primary-color);
}

.cold-lead:before {
    background: var(--blue);
}

.warm-lead:before {
    background: var(--orange);
}

.dead-lead:before {
    background: var(--red);
}

.hot-lead:before {
    background: var(--green);
}

.sorry-not-found {
    padding: 100px 0;
}

.sorry-not-found p {
    color: #92929D;
    font-size: 14px;
    font-weight: 400;
}

.create-new-leads {
    width: 360px;
    background: white;
    padding: 20px;
    margin-left: auto;
    transition: 0.5s all;
    height: 100vh;
}

.create-new-leads label {
    font-weight: 400;
    margin-bottom: 8px;
    color: #696974;
}

.create-new-leads .form-control {
    border: 1px solid #F1F1F5;
    border-radius: 8px;
    background: #FAFAFB;
    height: 44px;
    padding-left: 16px;
    padding-right: 30px;
    font-family: var(--popins);
}

.create-new-leads .form-control:focus {
    border-color: var(--primary-color);
    outline: 0 !important;
    box-shadow: unset;
}

.create-new-leads .form-group+.form-group {
    margin-top: 16px;
}

.create-new-leads .outline {
    width: 100%;
    padding: 9px 10px;
    background: #FAFAFB;
    border-color: #F1F1F5;
    border-radius: 8px;
    color: var(--black);
}

.create-new-leads .form-inline button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
}

.create-new-leads .form-inline {
    justify-content: space-between;
}

div#lead-modal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100vh;
    background: rgb(23 23 37 / 70%);
    z-index: 999;
    display: none;
    opacity: 0;
    transition: opacity .15s linear;
}

.show-new-leads div#lead-modal {
    display: block;
    opacity: 9;
}

.show-new-leads div#lead-modal .create-new-leads {
    transform: translate(0px, 0px);
}

.lead-table .table tbody .name {
    color: var(--black);
    position: relative;
    text-transform: capitalize;
    display: flex;


}

.lead-table .table tbody .name span.lead_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.lead-table .table tbody .name:hover {
    color: var(--primary-color);
}

.main-section .show-new-leads {
    overflow: hidden;
    padding-right: 5px !important;
}


.create-new-leads .dropdown-menu {
    width: 323px;
    border: none;
    box-shadow: var(--box-shadow);
    padding: 12px;
}

.create-new-leads .dropdown-menu a {
    background: #fff;
    background: var(--white);
    line-height: 22px;
    letter-spacing: .1px;
    color: #44444f;
    color: var(--black-dark-800);
    border-radius: 4px;
    font-size: 14px;
    padding: 4px 8px;
}

.create-new-leads .dropdown-menu a:hover {
    background: #f1f1f5;
    background: var(--black-dark-200);
}

.create-new-leads .dropdown-menu a+a {
    margin-top: 8px;
}

.create-new-leads .form-inline button:nth-child(1) {
    padding-left: 22px;
    padding-right: 22px;
}

.create-new-leads .form-inline button:nth-child(2) {
    padding-left: 30px;
    padding-right: 30px;
}

.create-new-leads .form-inline button:nth-child(1):focus {
    border: 1px solid var(--primary-color) !important;
}

.serch-btn {
    color: var(--black-dark-800);
    background: var(--black-dark-200);
    padding: 5px 12px;
    letter-spacing: 0.5px;
    border-radius: 4px;
}

.copy-btn {
    display: table;
    background: no-repeat;
    color: #92929D;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    letter-spacing: 0.1px;
    margin-left: 24px;
    padding: 2px 7px;
}

.copy-btn:hover {
    color: var(--primary-color);
    border-color: var(--primary-color)
}

.copy-btn:hover svg path {
    fill: var(--primary-color);
}

.serch-btn:hover {
    background-color: var(--primary-color);
    color: var(--white);
}

.serch-btn.active {
    background: var(--Blue-100);
    color: var(--primary-color);
}

.all-project-main.housing-table {
    height: calc(100vh - 210px);


}

.uder-table-main.lead-assignment_table,
.uder-table-main.agency-table {
    height: calc(100vh - 180px);
}

.search-active input {
    border-color: var(--primary-color);
}

.search-active svg path {
    fill: var(--primary-color);
}

.search-active .search-input .close-icon {
    opacity: 9;
}

.search-active .serch-btn {
    background: var(--primary-color);
    color: white;
}

.all-tabs .nav-tabs .nav-link {
    border: 0 !important;
    padding: 16px;
    color: var(--black-dark-700);
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 20px;
    position: relative;
    font-weight: 400;
    font-family: var(--popins);
    white-space: nowrap;
}

.all-tabs .nav-tabs .nav-link:hover {
    color: var(--primary-color);
}

/* .all-tabs .nav-tabs .nav-link span {
    margin-left: 4px;
} */

.all-tabs .nav-tabs .nav-item {
    margin-bottom: 0 !important;
}


.all-tabs .nav-tabs {
    border-bottom: 2px solid #E2E2EA;
    display: inline-flex;
    flex-wrap: nowrap;
}

.all-tabs .nav-tabs .nav-link:before {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 2px;
    background: transparent;
}

button.scrbtn:hover svg path:nth-child(1) {
    fill: #44444F;
}

button.scrbtn:hover svg path:nth-child(2) {
    fill: white;
}

.all-tabs .nav-tabs .nav-link.active:before {
    background: var(--primary-color);
}

.all-tabs .nav-tabs .nav-link.active {
    background: transparent;
    color: var(--primary-color);
}

.view-more .dropdown-item {
    color: var(--black-dark-800);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 22px;
    border-radius: 4px;
    padding: 4px 8px;
    font-family: var(--popins);
}

.view-more .dropdown-item.del-menu-itme {
    color: var(--red);
}

.view-more .dropdown-item.del-menu-itme:hover {
    background: var(--red);
    color: white;
}

.view-more .dropdown-item:hover {
    background: var(--black-dark-200);
    color: var(--black-dark-800);
}

.view-more .dropdown-item+.dropdown-item {
    margin-top: 4px;
}


.column-filter .dropdown-menu a {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 22px;
    border-radius: 4px;
    padding: 4px 8px;
}


/* Filter css */

.filter {
    padding: 0px 20px;
    background: var(--white);
    height: 100%;
    overflow: auto;
    margin-bottom: 16px;
}

.all-fliters {
    height: 100%;
}

.filter #accordion .search-filter input.form-control.searchinput::placeholder {
    font-size: 16px;
}

.filter #accordion .search-filter .close-icon {
    position: absolute;
    right: 10px;
    top: 15px;
}

.filter-active:before {
    background: #171725;
    content: " ";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 99;
    opacity: 0.7;
}

.search-filter>.form-inline button {
    color: var(--black-dark-800);
    background: var(--black-dark-200);
    letter-spacing: 0.1px;
    font-size: 14px;
    line-height: 22px;
    padding: 7px 10px;
    margin-left: 6px;
    border-radius: 4px;
}

.clear-btn {
    font-size: 14px;
    line-height: 22px;
    color: var(--black-dark-700);
    margin-bottom: 8px;
}

.all-fliters .filter-body {
    background: var(--black-dark-100);
    padding-bottom: 14px;
}

.filter .all-fliters .filter-header {
    padding: 18px 20px;
    font-size: 16px;
    color: var(--black);
    border-bottom: 0.5px solid #D5D5DC;
    border-top: 0.5px solid transparent;
}

.filter .all-fliters .filter-header[aria-expanded="true"] {
    border-color: transparent;
    background: var(--black-dark-100);
    border-top: 0.5px solid #D5D5DC;
}

.filter .all-fliters .filter-header[aria-expanded="true"] .icon {
    transform: rotate(180deg);
}

.filter .all-fliters .filter-header .icon {
    transition: 0.5s all;
}

.filter .all-fliters .filter-header[aria-expanded="true"] .icon svg path {
    fill: var(--black);
}

.filter .all-fliters .filter-header h2 span {
    margin-left: 12px;
    font-size: 14px;
    line-height: 22px;
    background: var(--pr-light-blue);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    padding: 2px 10px;
    border-radius: 20px;
}

.filter .all-fliters .filter-header[aria-expanded="true"] h2 span {
    background: white;
}

ul.select-filter li {
    box-shadow: 0px 0px 2px #92929D;
    padding: 10px;
    background: white;
    cursor: pointer;
    border-radius: 4px;
}

ul.select-filter li+li {
    margin-top: 10px;
}

ul.select-filter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
}

.filter-list .form-check {
    padding: 10px;
}

.lead-detail-left .name-row .dead-reason-notes {
    top: 0px;
}

.lead-detail-left .name-row .dead-btn:hover+.dead-reason-notes {
    display: inline-table !important;
}

.filter-list .form-check .form-check-label {
    color: var(--black-dark-800);
    letter-spacing: 0.8px;
    font-size: 12px;
    line-height: 14px;
    margin-left: 12px;
    font-weight: 500;
}

.filter-list .form-check .form-check-input {
    margin-left: -12px;
    width: 14px;
    height: 14px;
    top: 10px;
}

.filter-ok-cancel button {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    text-align: center;
    border-radius: 8px;
}

.filter-ok-cancel button:nth-child(2) {
    padding-left: 20px;
    padding-right: 20px;
    min-width: 230px;
}

.filter-ok-cancel button:nth-child(1) {
    padding-left: 42px;
    padding-right: 42px;
}


.view-more>button:hover svg path {
    fill: #44444F;
}

/* End filter css */
/* .lead-table .table thead tr th:last-child {
    position: sticky;
    right: -12px;
} */

.table tbody tr td:nth-child(1) {
    position: sticky;
    left: 0px;

}

.lead-table .table tbody tr td:nth-child(2) {
    left: 65px;
}

.lead-table .table tbody tr td:last-child,
.lead-table .table thead tr th:last-child,
.inventory-table .table tbody tr td:last-child,
.inventory-table .table thead tr th:last-child {
    position: sticky;
    right: 0px;
}

.all_lead-table .table tbody tr:not(.new-leads) td:last-child {
    background: #f7f8fa !important;
}

tr.booking-complete {
    background: #F7F8FA;
}




.table tbody tr td.sticky-td {
    position: sticky;
    left: 65px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    margin-right: 30px;
    min-width: 200px;
}





.all-leads .all_lead-table .table thead tr th:nth-child(2) {
    padding-left: 34px !important;
}

.table thead tr th:nth-child(1) {
    position: sticky;
    left: 0px;
    text-align: center;
    min-width: 65px;
    max-width: 60px;

}

.table thead tr th.sticky-th {
    position: sticky;
    left: 65px;
    padding-left: 16px !important;

}




/* Loader css  all table data in span tag and add the class in table "loader"*/
.loder tr span,
.loder tr button,
.loder tr a {
    color: transparent;
    appearance: none !important;
    -webkit-appearance: none;
    background-color: #eee;
    border-color: #eee;
}

.loder {
    pointer-events: none;
    animation: loading-skeleton 1s infinite alternate;
}



.loder svg {
    opacity: 0;
}

.lead-table .css-b62m3t-container {
    WIDTH: 210PX;
}

.short-btn>button:focus {
    box-shadow: unset;
    outline: 0;
}

.short-btn>button:hover svg path {
    stroke: var(--black);
}

.filter-btn>button:hover svg path {
    fill: var(--black)
}

.pagination-custom {
    position: fixed;
    left: auto;
    right: 0;
    bottom: 0;
    z-index: 9;
    flex-basis: calc(100% - 64px);
    width: calc(100% - 64px);
}

.main-filter .modal-dialog {
    max-width: 440px;
    border: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
}

.main-filter .modal-dialog .modal-content {
    border: 0;
    box-shadow: unset;
    border-radius: 0;
    height: 100%;
    overflow: hidden;
}





.main-filter .modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
    height: 100%;
}

.filter-sticky {
    padding-top: 20px;
    padding-bottom: 8px;
    background: white;
    z-index: 2;
}

.main-filter .search-input input {
    max-width: 100%;
    height: 32px;
    padding: 5px 30px !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-family: var(--worksans);
    border-radius: 8px;
}

.main-filter .search-input input:focus~.serch-icon svg path {
    fill: var(--primary-color);
}



.filter-list .row .col-6:nth-child(even) {
    padding-right: 0 !important;
    padding-left: 0;
}

.name-filter-list li {
    font-size: 12px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
    width: 100%;
}

.name-filter-list li label {
    padding: 10px 10px;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.name-filter-list {
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex: 0 0 100%;
    margin-top: 12px;
    max-height: 160px;
    overflow-y: scroll;

}

.name-filter-list li input {
    margin-top: 2px;
}

#collapseAssignedTo .name-filter-list {
    max-height: unset !important;
    overflow: auto !important;
}



.filter-ok-cancel {

    background: white;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px -5px 10px #00000017;
    display: flex;
    flex-flow: row nowrap !important;
    gap: 32px;

}





.company-info .css-qc6sy-singleValue {
    color: var(--black-dark-700) !important;
    color: #44444F !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}



.company-info .css-1s2u09g-control {
    height: 45px !important;
}

.head-itme {
    font-family: var(--worksans);
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin-bottom: 8px;
    display: block;
    display: flex;
    align-items: center;
}

.head-itme span {
    width: 100%;
    height: 1px;
    background: #F1F1F5;
    display: inline-block;
    margin-left: 8px;
}

.merge-del-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.merge-del-btn button {
    font-size: 14px;
    padding: 4px 8px;
    line-height: 22px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    background-color: var(--primary-color);
    color: white;
    /* box-shadow: 4px 4px 8px #F1F1F5; */
    border-radius: 4px;
}

.merge-del-btn button span {
    margin-left: 8px;
    border-left: 1px solid white;
    padding-left: 8px;
}

.del-btn {
    font-size: 14px;
    padding: 4px 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: white;
    border-radius: 4px;
    border: 1px solid var(--red);
    background-color: var(--red);
}

.merge-del-btn .del-btn {
    background-color: var(--red);
}

.merge-del-btn button.cancel-btn {
    border: 0;
    background: var(--black-dark-200);
    border-radius: 4px;
    color: #696974;
    padding: 5px 8px;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin-left: 12px;
}

.merge-del-btn button.cancel-btn span {
    border-left: 0;
    margin-left: 0;
}

.remove a:hover {
    color: var(--red);
}

.remove a:hover svg path {
    fill: var(--red);
}


.short-btn .css-1s2u09g-control {
    cursor: pointer;
}

.view-notes.btn-link {
    color: var(--dark-black-700);
}

.view-notes.btn-link:hover {
    text-decoration: none;
    color: var(--primary-color);
}

.view-notes button:after {
    display: none;
}

.view-notes button {
    border: none;
    background: transparent;
    color: var(--black);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.05px;
    cursor: pointer;
    padding: 0;
    font-family: var(--worksans) !important;
}

.main-filter input[type=date] {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: var(--worksans);
    width: 155px;
}

.view-notes button:focus {
    outline: 0 !important;
    box-shadow: unset !important;
    background: transparent !important;
    color: var(--primary-color) !important;
}

.view-notes button:hover {
    color: var(--primary-color);
    background: transparent;
}

.view-notes .show>.btn-primary.dropdown-toggle {
    background: transparent !important;
    color: var(--primary-color);
}

.view-notes .dropdown-menu {
    background: #FFFFFF;
    box-shadow: 0px 25px 80px rgba(226, 226, 234, 0.6);
    border-radius: 8px;
    border: 0;
    width: auto;
    min-width: 190px;
    padding: 16px !important;
}

.view-notes .notes {
    display: flex;
}

.view-notes .notes .left-icon {
    flex: 0 0 24px;
    margin-right: 12px;
}

.del-modal .modal-header {
    padding: 20px 20px 8px 20px;
    border: none;
}

.del-modal .modal-header .modal-title {
    font-size: 24px;
    font-weight: 400;
    color: var(--black)
}

.del-modal .modal-body {
    padding: 0;
}

.del-modal .modal-body p {
    padding: 0px 32px 0px 20px;
}

.del-modal .modal-footer {
    background: #FAFAFB;
    border: 0;
    border-top: 1px solid #E2E2EA;
    padding: 16px 20px 16px;
}

.del-modal .modal-footer .cancel.btn {
    border-color: var(--primary-color);
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    min-width: 120px;
}

.del-modal .modal-footer .cancel.btn:hover {
    background: var(--primary-color);
    color: white;
}

.del-modal .modal-footer button.del-btn.btn {
    background: var(--red);
    color: white;
    font-size: 14px;
    margin-left: 8px;
}

.all-pr .all-projects-table::-webkit-scrollbar {
    width: 0;
    opacity: 0;
}

.del-modal .modal-footer button {
    padding-top: 4px;
    padding-bottom: 4px;
    min-width: 120px;
}


.notes.drop-notes {
    min-width: 300px;
    max-width: 230px;
    overflow: hidden;
}

.drop-notes p {
    display: block;
    max-width: 300px;
    white-space: normal;
}

.drop-notes .right-text {
    display: flex;
    flex-wrap: wrap;
}

.name-pagination .pagination-custom {
    margin-right: 0;
    box-shadow: unset;
    border: 0;
}

.notification {
    margin-right: 24px;
}

header button {
    border: 0;
    background: transparent;
}

.user_profile button {
    color: var(--black-dark-800);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 22px;
    border-radius: 4px;
    padding: 4px 8px;
    font-family: var(--popins);
}

.dead-info-icon {
    border: 2px solid #fff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.whatsapp-hover {
    position: absolute;
    opacity: 0;
    left: -30px;
    z-index: 1;
    background: #44444F;
    color: white;
    padding: 4px 9px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;
    transition: 0.5s all;
    font-weight: 500;
    bottom: -38px;
}

.whatsapp-hover:before {
    content: " ";
    width: 20px;
    height: 11px;
    background: #44444f;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    z-index: 1;
    position: absolute;
    top: -10px;
    left: 32px;
}

.whatsapp:hover .whatsapp-hover {
    opacity: 9;
}

.assigned-drop.new-lead-status>button {
    border-color: var(--black-dark-800);
    font-weight: 500;
}

.whatsapp {
    overflow: hidden;
}

.whatsapp:hover {
    overflow: visible;
}



.hover-edit span {
    position: absolute;
    opacity: 0;
    left: 30px;
    z-index: 0;
    background: #44444F;
    color: white;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
    transition: 0.5s all;
    bottom: -50px;
}

.hover-edit {
    position: relative;
}

.hover-edit span:before {
    content: " ";
    width: 14px;
    height: 17px;
    background: #44444F;
    position: absolute;
    left: 36px;
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
    top: -15px;
    transform: rotate(90deg);
}

.hover-edit:hover span {
    opacity: 9;
}

.hover-edit {
    overflow: hidden !important;

    display: table;
}

.hover-edit:hover {
    overflow: visible !important;
}

figure.erro-img svg {
    width: 90% !important;
    height: auto !important;
    display: table;
    margin: 0 auto;
}

.dead-reason-notes {
    background: #fff;
    box-shadow: 0 25px 80px rgb(226 226 234 / 60%);
    border-radius: 8px;
    border: 0;
    width: auto;
    min-width: 270px;
    padding: 7px 14px !important;
    bottom: 0px;
    max-width: 250px;
    transform: translate(132px, 2px);

}

.dead-reason-notes p {
    display: block;
    white-space: normal;
}

.card-btn-notes {
    box-shadow: 0 5px 12px rgba(213, 213, 220, 80%) !important;
    bottom: 60px;
}

.dead-reason-hide {
    display: none;
}

.dead-reason-hide svg {
    flex: 0 0 26px;
}



.dead-reason:hover+.dead-reason-hide {
    display: flex;
    z-index: 2;
}

.tab-content .row .col-xl-4 {
    margin-bottom: 24px;
}

@media (max-width: 1200px) {

    .fbintegration .short-btn>button span,
    .fbintegration .filter-btn>button span {
        display: inline-block;
    }

    .fbintegration .serch-and-filter-blcok .filter-btn.mr-12 {
        margin-right: 12px;
    }

    .fbintegration .new-leads {
        margin-right: 12px;
    }
}

@media (max-width: 992px) {

    .fbintegration .short-btn>button span,
    .fbintegration .filter-btn>button span {
        display: none;
    }

}

@media (max-width: 768px) {

    .fbintegration .short-btn>button span,
    .fbintegration .filter-btn>button span {
        display: inline-block;
    }

    .fbintegration .table tbody tr td:nth-child(2),
    .fbintegration .table thead tr th:nth-child(2) {
        left: -4px;
    }

    .fb-intr-modal.modal-dialog {
        max-width: 95% !important;
        padding: 0 !important;
        margin-top: -30px !important
    }

}

@media (max-width: 600px) {
    .fb-leads-add-accoung .form-group .fz16 {
        font-size: 15px;
    }

    .fb-leads-add-accoung {
        padding: 14px !important;
    }

    .import-pages-and-form {
        padding: 15px !important;
    }




    .final-step {
        padding: 15px !important;
    }

    .conform-fb-integration h1 {
        font-size: 20px;
    }

    .conform-fb-integration {
        height: 280px;
    }

    .contact-account .css-1s2u09g-control,
    .mpaing-body .css-1s2u09g-control,
    .final-step .css-1s2u09g-control {
        min-height: 40px !important;
    }

    .fb-leads-add-accoung .form-group .fz16 {
        font-size: 14px;
    }

    .fb-leads-add-accoung {
        padding: 14px;
    }

    .import-pages-and-form {
        padding: 15px;
    }



    .contact-account .css-1s2u09g-control {
        min-height: 40px !important;
    }

    .maphead>.row {
        display: none !important;
    }

    .map-fileds .row .col-md-6:nth-child(1) {
        margin-bottom: 4px;
    }

    .facebook-integration .footer-modal .secure-for-modal {
        bottom: 15px;
        left: 10px;
    }

    .mpaing-body {
        padding: 0 0 20px 0 !important;
    }

    .sucess-table .map-fileds .row {
        GAP: 16PX;
    }

    .sucess-table .map-fileds .row button {
        width: 100%;
        padding: 8px 0;
        text-align: center;
    }

    /* .map-fileds {
        min-height: 165px !important;
        max-height: 165px !important;
    } */



    .fbintegration .short-btn>button span,
    .fbintegration .filter-btn>button span {
        display: none;
    }

    .fbintegration .serch-and-filter-blcok .filter-btn.mr-12 {
        margin-right: 0;
    }

    .fbintegration .new-leads {
        margin-right: 0;
    }

    .fbintegration .dropdown.view-more.ml-8 {
        margin-left: 0;
    }
}

.resent-notes .desc p {
    white-space: break-spaces;
    word-break: break-all;
}

.search-margin {
    margin-bottom: 10px !important;
}

.btn-primary-bg {
    background: linear-gradient(180deg, #2064FA 0%, #3B37F5 100%) !important;
}

.subscription-expiry-modal .modal-content {
    border-radius: 16px !important;
}