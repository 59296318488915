#guides .learing-center-grid {
    grid-row-gap: 32px;
}

.video-time-date span {
    width: 8px;
    height: 8px;
    background: var(--black-dark-800);
    border-radius: 50%;
    margin: 6px 8px 0 8px;
}

.video-time-date>div {
    display: inline-flex;
}

.liveintimeonly {
    padding-top: 2px;
    padding-bottom: 2px;
}


#guides .video-cards .card {
    padding: 0;

    box-shadow: unset;
}

#workshops .learing-center-grid .mentor,
#recording .learing-center-grid .mentor {
    line-height: 1.5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.joinnow {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000040;
    opacity: 0;
    transition: 0.5s all;
}

.joinnow .compbtn {
    background: #92929D;
    color: white;
}

.joinnow .joinbtn {
    background: #0062FF;
    color: white;
}

.joinnow span,
.joinnow button {
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 8px;
    border: 0;
    font-family: var(--inter);
}

.learning-block .card :hover .joinnow {
    opacity: 9;
}



#workshops .video-cards,
#recording .video-cards {
    padding-right: 30px;
}

#workshops .card {
    padding: 0;
    margin: 0;
    box-shadow: unset;

}

#workshops .video-cards .card h1 {
    -webkit-line-clamp: 2;
    cursor: default;
}

#workshops .learing-center-grid .mentor {
    cursor: default;
}

#workshops .learing-center-grid,
#recording .learing-center-grid {
    grid-row-gap: 32px;
    margin: 0;
}

#workshops .card figure,
#recording .card figure {
    border-radius: 8px;
}

.livein {
    position: absolute;
    right: 0;
    top: 22px;
    background: #0062FF;
    border-radius: 6px 0px 0px 6px;
    color: white;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    font-family: var(--inter);
    display: inline-flex;
    align-items: center;
    gap: 6px;
    text-transform: uppercase;
}

nav.inner-page-breadcrumb ul li,
nav.inner-page-breadcrumb ul li a {
    letter-spacing: -.006em;
    color: #696974;
    font-size: 14px;
    font-weight: 400;
}

nav.inner-page-breadcrumb ul li a:hover {

    color: var(--primary-color) !important;
}

.courseH2dots {
    line-height: 24px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.courses .card h2 {
    white-space: normal;
    line-height: 24px;
}

.Lctags .owl-nav {
    top: 4px !important;
}

.tag-group {
    border: 1px solid #E2E2EA;
    border-radius: 8px;
    color: #92929D;
    padding: 9px 8px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.2px;
}

.tag-group:not(.active) button {
    display: none;
}

.tag-group:hover {
    color: #25282B;
    cursor: pointer;
}

.tag-group button {
    margin-left: 8px;
}

.tag-group.active {
    color: #44444F;
    background: #F2F3F6;
    border-radius: 6px;
}

.tag-group.active button:hover svg path {
    fill: var(--primary-color);
}

.Lctags {

    background: white;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9;
}

.Lctags button.owl-next {
    right: 4px !important;
}

.link-card a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.video-cards.courses .card h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.video-cards.courses .learing-center-grid {
    margin-right: 30px;
    margin-left: 0;
}

.learing-center-grid {
    display: grid;
    grid-column-gap: 24px;
    column-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
    margin: 0 8px;

}


.video-cards #guides .card h1 {
    -webkit-line-clamp: 1;
    min-height: unset;
}


#guides .card:hover {
    cursor: pointer;
}

#guides {
    padding-bottom: 50px;
}

#guides .video-card .card {
    padding: 0;
    box-shadow: unset;
}

#guides .card figure {
    border-radius: 4px;
    overflow: hidden;
}

#guides .card figure img {
    max-height: unset !important;
}

#guides .card:hover figure img {
    transform: scale(1.1);

}

#guides .card figure img {
    transition: 0.8s all;
}

.video-tabs.all-tabs .nav-tabs .nav-link {
    padding: 0 0 16px 0;
    font-family: var(--inter);
}

.video-tabs.all-tabs .nav-tabs {
    gap: 40px
}



.video-cards .card {
    padding: 0;
    border: 0;
    border-radius: 4px;
    position: relative;
    box-shadow: unset;
}

.learning-center .main-section,
.workshop-detail .main-section {
    padding-right: 0;
}


.video-cards .row {
    margin-right: -12px;
    margin-left: -12px;
}

.video-cards .card figure .video-lenth {
    position: absolute;
    right: 12px;
    bottom: 12px;
    background: rgba(17, 17, 17, 0.7);
    border-radius: 4px;
    color: white;
    font-size: 16px;
    font-family: var(--worksans);
    padding: 6px 10px;
}

.video-cards .card figure .video-lenth svg {
    margin-right: 8px;
}

.video-cards-main {
    height: calc(100vh - 180px);
    overflow-x: visible;
    padding-bottom: 0;
    overflow-y: auto;
}

.video-close {
    background: var(--black-dark-800);
    border-radius: 4px;
    right: 8px;
    top: 8px;
    padding: 4px 15px;
    opacity: 9 !important;
    cursor: pointer;
    z-index: 9;
}

.video-cards .card .pr-btn {
    font-weight: 500;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
    text-align: center;
}

.video-cards .card .dead-reason-notes {
    top: unset;
    left: 0;
    transform: translate(20px, -7px);
}

.short-card .card h1 {
    margin-top: 16px;
}

.short-card .card {
    padding-bottom: 45px;
}

.guide-card .card {
    padding: 0;
    box-shadow: unset !important;
    background: transparent;
}

.guide-card .card .date {
    color: #696974;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
}

.guide-card .card h1 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #44444F;
}

.guide-card .card:hover figure img {
    box-shadow: 4px 8px 24px rgba(226, 226, 234, 0.8);
}

.guide-card {
    padding-bottom: 45px;
}

.hover-btn {
    width: 100%;
    position: absolute;
    bottom: 13px;
    padding: 0 26px;
}

.hover-btn .pr-btn {
    width: 100% !important;
    display: inline-block;
    text-align: center;
    padding: 10px 0 !important;
    line-height: 16px;
    opacity: 0;
    transition: 0.5s all;
}

.guide-card .card:hover a.pr-btn {
    opacity: 9;
}

.video-cards .card h1 {
    line-height: 1.4;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

}

video.learnvideo {
    object-fit: cover !important;
}

.coming-soon-btn {
    background: linear-gradient(131.62deg, #2976F6 -15.57%, #5200FF 172.51%);
    border-radius: 6px 0px 0px 6px;
    color: var(--white);
    padding: 8px 10px;
    font-family: var(--inter);
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 22px;
    right: 0px;
    z-index: 1;
    text-transform: uppercase;
}

a.coming-soon-btn:hover {
    color: var(--white);
}

a.coming-soon-btn svg {
    margin-right: 6px;
}

.courses .card figure .view-details {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: -1;
    transition: 0.2s all;
    background: #00000060;
}

.courses .card figure .view-details span {
    background: linear-gradient(131.62deg, #2976F6 -15.57%, #5200FF 172.51%);
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    font-family: var(--worksans);
    color: white;
    padding: 12px;

}

.courses .card:hover .view-details {
    opacity: 9;
    z-index: 1;
}

.courses .video-time-date span {
    width: 8px;
    height: 8px;
    background: var(--black-dark-800);
    border-radius: 50%;
    margin: 6px 8px 0 8px;
    flex: 0 0 8px;
}

.courses .mentor {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.live-now {
    position: absolute;
    right: 0;
    top: 22px;
    background: linear-gradient(131.62deg, #2976F6 -15.57%, #5200FF 172.51%);
    border-radius: 6px 0px 0px 6px;
    padding: 6px 10px;
    color: white;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--inter);
    z-index: 1;
    text-transform: uppercase;
}

.live-now:hover {
    color: white;
}

.courses .learing-center-grid {
    margin-left: 0;
    grid-row-gap: 14px;
}