.facebook-integration .footer-modal button div[data-testid="oval-loading"] {
  justify-content: center;
}

.fb-integrationnofound {
  height: calc(100vh - 340px);
}

.integrationbrandcrumb li a svg {
  margin-right: 10px !important;
}

/* .facebook-page-table {
  max-width: 890px;
} */

.integrationbrandcrumb li a:hover svg path {
  fill: #171725;
}

.form-maping-left .forminpuline .invalid .form-group span+div {
  border-color: var(--red) !important;
}


.modal-bradcum .breadcrumb-item a {
  cursor: auto;
}

.facebook-integration .footer-modal button.outline-btn {
  border: 1px solid #E2E2EA;
  border-radius: 6px;
  letter-spacing: 0.4px;
  color: #696974;
  margin-right: 10px;
}

.facebook-integration .footer-modal button.outline-btn:hover {
  background-color: #fafafb;
}

.facebook-integration .footer-modal button.outline-btn:focus {
  border: 1px solid #E2E2EA !important;
}

.facebook-integration .footer-modal button,
.facebook-integration .footer-modal a {

  text-align: center !important;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 112px;
  font-size: 16px;
  font-weight: 500;
}

.test_integration {
  max-width: 690px;
  flex: 0 0 690px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.test_integration .s_no {
  max-width: 35px;
  flex: 0 0 35px;

}

.test_integration .map-fileds .test_integration_row .s_no {
  padding-left: 10px;
}

.test_integration .maphead {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.test_integration .map-fileds .test_integration_row {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-left: 20px;
  padding-right: 20px;
  color: #44444F;
}

.test_integration .form-name {
  max-width: 115px;
  flex: 0 0 115px;
}

.test_integration .form-id {
  max-width: 130px;
  flex: 0 0 130px;
}

.test_integration .form_status {
  max-width: 126px;
  flex: 0 0 126px;
}

.test_integration .form_sendTest {
  max-width: 140px;
  flex: 0 0 140px;
}

.leadMapping .ledmapingrow .s_no {
  padding-left: 12px;
}

.leadMapping {
  max-width: 590px;
  flex: 0 0 590px
}

.leadMapping .maphead {
  height: 44px;
  align-items: center;
  display: flex;
  gap: 24px;
}

.leadMapping .s_no {
  width: 45px;
  flex: 0 0 45px;
}

.leadMapping .forms_fields {
  max-width: 240px;
  flex: 0 0 240px;
}

.leadMapping .forms_fields span {
  word-break: break-word;
}

.leadMapping .ledmapingrow {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-left: 20px;
  padding-right: 20px;
}

.leadMapping .platform_fields {
  max-width: 220px;
  flex: 0 0 220px;
}

/* .fb-manage-account {
  max-width: 838px;
} */

.form-maping-left .lead-maping .maphead {
  display: flex;
  align-items: center;
  gap: 24px;
  height: 44px;
  max-height: 44px;
}

.form-maping-left .forminpuline {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 24px;
  padding-left: 20px;
  padding-right: 20px;
}

.form-maping-left .facebook_forms {
  width: 240px;
  flex: 0 0 240px;
}

.form-maping-left .facebook_forms span {
  white-space: break-spaces;
  word-break: break-all;
}

.form-maping-left .facebook_forms>div>button {
  margin-left: 10px;
}

.form-maping-left .facebook_forms>div>button:hover svg path {
  fill: #171725;

}

.form-maping-left .facebook_forms .search-input input {
  padding-left: 32px;
}

.receive-unmapped-forms button {
  margin-top: 16px;
}

.form-maping-left .platform_projects {
  max-width: 200px;
  flex: 0 0 200px;
}

.form-maping-left .marketing_agency {
  max-width: 200px;
  flex: 0 0 200px;
}

.map-fileds>div {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #D5D5DC;
}


.form-maping-left .s_no {
  width: 30px;
  flex: 0 0 30px;

}

.form-maping-left {
  max-width: 1010px;
  flex: 0 0 1010px;
  width: auto;
}

.unmapedform {
  max-width: 252px;
  flex: 0 0 252px;
}

.formmapingmain {
  display: flex;
  gap: 20px;
}

.fb_account_connect {
  gap: 12px;
}

.fb_account_connect .fb_account_select-dropdown {
  max-width: 434px;
  width: 100%;
}

.fb-contect-form {
  max-width: 723px;
  flex: 1 1 700px;
  padding-right: 20px;
}


.modal-bradcum ol.breadcrumb {
  gap: 12px;
}

.modal-bradcum .breadcrumb-item+.breadcrumb-item {
  margin: 0;
}


.modal-bradcum .breadcrumb li a span {
  margin-right: 0px;
}

.FormMapingRulesModal .modal-dialog {
  max-width: 460px;
}

.FormMapingRulesModal ul {
  list-style: devanagari;
  text-indent: 0px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 0;
}

.FormMapingRulesModal ul li {
  color: #696974;
  font-size: 14px;
  padding-left: 10px;
}

.test-btn:hover {
  background: var(--primary-color) !important;
  color: white;
}

.PauseIntegrationModal .modal-dialog {
  max-width: 400px;
}

.unprocessed-leads .outline-btn {
  border: 1px solid #D5D5DC;
  color: #344054;
}

.requestraised .modal-dialog {
  max-width: 345px;
}

.unprocessed-leads.requestraised .modal-content {
  padding-left: 24px;
  padding-right: 24px;
}

.unprocessed-leads .outline-btn:hover {
  background-color: #fafafb;
}

.unprocessed-leads .outline-btn:focus {
  border: 1px solid #D5D5DC !important;
}

.unprocessed-leads button {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
}


.IntegrationCreatedModal .modal-dialog {
  max-width: 550px;
}

.unprocessed-leads .modal-content {
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 18px;
  padding-right: 18px;
}

.unprocessed-leads .modal-content .sucesstable {
  margin: 0;

}


.unprocessed-leads.errormoadl .modal-dialog {
  max-width: 600px;
}

.unprocessed-leads.errormoadl .sucesstable .def-table {
  height: 300px;
  overflow: auto;
  --webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  padding-right: 5px;
}

.unprocessed-leads.errormoadl .sucesstable .def-table::-webkit-scrollbar-button:vertical:start:decrement {
  height: 48px !important;
  display: block !important;
  color: #d9d9d9;
}

/* 
.unprocessed-leads.errormoadl .sucesstable .def-table::-webkit-scrollbar-track {
  border: 1px solid var(--black-dark-300);
  border-radius: 4px;

}


.unprocessed-leads.errormoadl .sucesstable .def-table::-webkit-scrollbar-thumb {
  background: var(--black-dark-600);
  border: 2px solid var(--black-dark-300);

}

.unprocessed-leads.errormoadl .sucesstable .def-table::-webkit-scrollbar-thumb:hover {
  border: 1px solid var(--black-dark-600) !important;
} */

.errorEncounted .outline-btn {
  border: 1px solid #D5D5DC;
}

.errorEncounted .outline-btn:hover {

  background-color: #fafafb;
}

.unprocessed-leads.errormoadl button {
  margin-top: 17px;
  width: 120px;
  font-weight: 500;
}


.fb-intr-modal.previous-leads-import {
  max-width: 660px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.page-error {
  border: 1px solid #DEDEDE;
  border-radius: 4px;
  padding: 12px 16px;
}

.page-error p {
  line-height: 22px;
  letter-spacing: 0.1px;
}

.lead-igone .right-txt h2 {
  font-size: 14px;
  color: var(--black);
  font-weight: 500;
  margin-bottom: 6px;
}

.lead-igone .bottom-desc p {
  color: #696974;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--roboto);
  line-height: 22px;
  margin-bottom: 0;
}

.lead-igone a {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lead-igone {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 16px;
  display: flex;
  align-items: flex-start;
  background: #FAFAFB;
  border-radius: 4px;
  margin-bottom: 8px;
  flex-direction: column;
}

.leadmodalnotes {
  max-width: 550px;
  margin: auto;
  border-radius: 8px;
  background: rgba(0, 98, 255, 0.04);
  padding: 16px 16px;
  border-left: 8px solid var(--primary-color);
  margin-top: 16px
}

.leadmodalnotes p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #44444F;

}

.lead-igone .top-btn {
  width: 100%;
  margin-bottom: 6px;
  margin-left: 5px;
}

.lead-igone .top-btn label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.lead-igone .top-btn label:hover {
  cursor: pointer;
}

.right-date .form-group>div :hover {
  cursor: pointer;
}

.lead-igone .top-btn label h2 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--black);
  margin-left: 13px;
}

.lead-igone .bottom-desc {
  margin-left: 32px;
}



.lead-igone .left-btn {
  flex: 0 0 24px;
  max-width: 24px;
  margin-right: 8px;
}

.lead-igone .right-txt {
  width: 100%;
}

.date-range {
  display: flex;
  align-items: flex-start;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  background: #FAFAFB;
  border-radius: 4px;
  padding: 12px 16px;
}

.date-range .right-date {
  width: 100%;
}

.date-range .left-ic {
  flex: 0 0 24px;
  width: 24px;
  margin-right: 8px;
}

.date-range .right-date .form-group label {
  color: var(--black);
  font-weight: 500;
}

.sucesstable tr td,
.sucesstable tr th {
  position: unset !important;
  padding-left: 16px !important;
}

.sucesstable .table tbody tr td:nth-child(1) {
  padding-left: 16px !important;
}

.test-btn>div {
  display: table !important;
  margin: auto !important;
  padding: 0 !important;
}

.form_status button {
  width: 126px;
  padding: 4px 0;
  text-transform: capitalize;
  text-align: center;
}

.test-btn {
  background: rgba(0, 98, 255, 0.1) !important;
  border-radius: 4px;
  color: var(--primary-color);
  padding: 4px 0px;
  font-weight: 500;
  border: 0 !important;
  line-height: 22px;
  min-width: 126px;

}

.test-btn:disabled:hover {
  background: rgba(0, 98, 255, 0.1) !important;
}


.sucesstable .table {
  margin-bottom: 0 !important;
}

.receive-unmapped-forms {
  background: #FAFAFB;
  border: 1px solid #D5D5DC;
  border-radius: 4px;
  padding: 16px;
  position: sticky;
  top: 0
}



.fb-lead-integration .table tbody tr td:nth-child(2) {
  overflow: visible;
  text-overflow: unset;
  max-width: 250px;
}

.fb-lead-integration .table tbody tr td:nth-child(2) span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  display: inline-block;
  flex: 0 0 140px;
}


.fb-lead-integration .table tbody tr td:nth-child(2) svg {
  margin-left: 12px;
}

.btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #F1F1F5;
}


.btn-toggle>.handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.active {
  transition: background-color 0.25s;
}

.btn-toggle.active>.handle {
  left: 1.6875rem;
  transition: left 0.25s;
}


.btn-toggle.active {
  background-color: var(--primary-color) !important;
}

.btn-toggle.btn-lg {
  padding: 0;
  position: relative;
  border: none;
  height: 22px;
  border-radius: 2.5rem;
  width: 50px;
  margin-right: auto;
  margin-left: 0;
  background: #F1F1F5;
}


.btn-toggle.btn-lg>.handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  border-radius: 1.875rem;
  background: #F1F1F5;
  border: 2px solid #FFFFFF;
  box-shadow: inset 0px -4px 4px #ffffff, inset 0px 1px 3px rgb(68 68 79 / 15%);
  transition: left 0.25s;

}

.btn-deactive .handle {
  background: #F1F1F5 !important;
}

.btn-toggle.active.btn-deactive {
  background: var(--black-dark-400) !important;
}

.btn-toggle.btn-lg.active>.handle {
  left: 30px;
  transition: left 0.25s;
}

.btn-toggle.btn-sm>.handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.btn-sm.active>.handle {
  left: 1.6875rem;
  transition: left 0.25s;
}

.btn-toggle.btn-xs>.handle {
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.btn-xs.active>.handle {
  transition: left 0.25s;
}

.btn-toggle.btn-lg>span {
  position: absolute;
  top: 2px;
  right: 6px;
  border-radius: 1.875rem;
  transition: left 0.25s;
  font-size: 12px;
  color: #92929D;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: var(--popins);
}

.btn-toggle.btn-lg.active>span {
  left: -10px;
  transition: left 0.25s;
  color: white;
}

.lead-assignment_table .btn-toggle.btn-lg.active>span {
  left: -13px;
}

.modal+.modal-backdrop {
  opacity: 0.7;
  z-index: 99999;
}

.modal+.modal-backdrop+.modal {
  z-index: 99999;
}

.testleadProcess .modal-content {
  padding: 24px;
  overflow: hidden;
}

.testleadProcess.modal-sm {
  max-width: 400px;
}

.testleadProcess .modal-content .skiptbtn-group .skip-btn {
  border: 1px solid #D5D5DC;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 10px;
  width: 100%;
  margin-right: 12px;
  background: white;
}

.testleadProcess .modal-content .skiptbtn-group .pr-btn {
  padding: 10px 25px;
}

.fb-integration-complete .pr-btn {
  margin-top: 32px;
  padding: 10px 40px;
  font-size: 16px;
  font-weight: 500;
}

.testleadProcess .modal-content .skiptbtn-group button {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
}

.testleadProcess .modal-content .skiptbtn-group {
  justify-content: space-between;
  margin-top: 24px;
}

.testleadProcess .modal-content .skiptbtn-group .skip-btn:hover {
  border-color: var(--primary-color);
}

.fb-intr-modal {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}


.fb-leads-add-accoung .form-group .pr-btn:hover {
  opacity: 0.9;
}

.fb-leads-add-accoung .form-group .pr-btn {
  font-weight: 500;
  font-size: 14px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: #1877F2;
  color: var(--white);
  border: 1px solid transparent !important;
  display: inline-flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 6px;
}

.fb-intr-modal {
  max-width: 780px !important;
  padding: 36px 0 0 24px;
}

.facebook-integration .footer-modal {
  position: relative;
  width: 100%;
  padding-bottom: 6px;
  padding-top: 6px;
  border-top: 1px solid #E2E2EA;
  background-color: white;
  z-index: 9;
}

.facebook-integration .footer-modal .secure-for-modal {
  bottom: 10px;
}

.fb-intr-modal .modal-content {
  padding-top: 36px;
  padding-bottom: 24px;
}

.fb-leads-add-accoung {
  padding: 20px 0px 20px 24px;
  border-bottom: 1px solid #E2E2EA;
}

.fb-intr-modal .footer-modal {
  padding: 0;
}

.fb-intr-modal a.close {
  position: absolute;
  right: 20px;
  top: 20px;
}


.fbintegration ol.breadcrumb li:before {
  display: none !important;
}

.fbintegration ol.breadcrumb li:nth-child(1) a:before {
  width: 100%;
}

.fbintegration ol.breadcrumb li a:before {
  width: calc(100% - 34px);
  background-color: #171725 !important;
  height: 1px !important;
  left: auto;
  right: 0;
  bottom: -13px;
}



.fbintegration ol.breadcrumb li a svg {
  margin-right: 12px !important;
}

.fbintegration ol.breadcrumb li.active a {
  color: #171725 !important;
}


.fbintegration ol.breadcrumb li a {
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #696974;
}



.import-pages-and-form {
  padding: 20px 0 20px 24px;
}

.import-forms {
  border-top: 1px solid #E2E2EA;
}

.blankdiv {
  display: block;
  height: 20px;
  background: white;
}

svg.css-tj5bde-Svg:hover {
  fill: var(--black);
}

div[aria-hidden="true"]+span.css-1okebmr-indicatorSeparator {
  display: block !important;
}

.maphead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.platform_projects .form-group.position-relative div,
.forminpuline .marketing_agency div,
.fb_account_select-dropdown .form-group.position-relative div,
.import-pages-and-form .form-group.position-relative div,
.platform_fields .form-group.position-relative div {
  cursor: pointer;
}

.scroll-div .css-1s2u09g-control,
.fb-contect-form .css-1s2u09g-control {
  cursor: pointer !important;
}

.scroll-div {
  height: 100%;
  overflow: auto;
  --webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}

.maping-hader {
  padding: 20px;
  background: #FAFAFB;
  border-bottom: 1px solid #E2E2EA;
}

.maping-hader h1 {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 24px;
}


.maphead {
  padding: 5px 20px;
  background: #FAFAFB;
  border: 1px solid #D5D5DC;
  border-radius: 4px;
  letter-spacing: 0.5px;
  color: #44444F;
  font-size: 14px;
  font-weight: 500;

}

.map-fileds .row {
  align-items: center;
  border-bottom: 0.5px solid #D5D5DC;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.final-step+.footer-modal {
  margin-top: 45px !important;
  margin-bottom: 15px;
}


.map-fileds .row .col-md-8 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.final-step {
  margin: 16px 24px 0 24px;
  padding: 20px 24px;
  border: 1px solid #E2E2EA;
  margin-bottom: 24px;
  border-radius: 8px;
}

.final-step>div+div {
  margin-top: 24px;
}

.conform-fb-integration {
  height: 362px;
}

.conform-fb-integration h1 {
  font-weight: 500;
  font-size: 28px;
  line-height: 140%;
  color: var(--black);
  text-align: center;
  letter-spacing: 0.1px;
  margin-bottom: 8px;
}

.conform-fb-integration p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #696974;
  margin-bottom: 30px;
  font-family: var(--roboto);
}

.btn-toggle.btn-lg.active>span.btn-deactive {
  color: #92929D;
}

.fb-mobile-breadcrumbt-text ol li:before {
  display: none !important;
}

.fb-mobile-breadcrumbt-text ol li:not(.active) {
  display: none;
}

.fb-mobile-breadcrumbt-text ol li {
  color: #171725 !important;
  font-size: 20px;
  font-weight: 500;
}

.fb-mobile-breadcrumbt-text ol,
.fb-mobile-breadcrumb-circle ol {
  border: 0 !important;
}

.fb-mobile-breadcrumb-circle ol li:before {
  display: none !important;
}

.fb-mobile-breadcrumb-circle ol li span {
  width: 10px;
  height: 10px;
  background: #0062FF;
  opacity: 0.1;
  display: inline-block;
  border-radius: 50%;
}

.fb-mobile-breadcrumb-circle ol li.active span {
  opacity: 9;
}