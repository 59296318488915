.paddig_bottom_180 {
    padding-bottom: 180px;
}

.projectcardMenu.view-more .dropdown-item.del-menu {
    color: var(--red) !important;
}

.projectcardMenu.view-more .dropdown-item {
    font-weight: 500 !important;
    font-family: var(--inter);
}

.projectcardMenu .dropdown-menu {
    padding: 8px !important;
}


.projectcardMenu.view-more .dropdown-item.del-menu:hover {
    color: white !important
}

.form-control.rerainput {
    padding-right: 60px !important;
}

.drag-active {
    border-color: var(--primary-color) !important;
    background-color: rgb(0 98 255 / 10%);
}

.form-control.pad_in_input {
    padding-left: 34px !important;
}

.rotateIcon {
    transform: rotate(90deg);
}

.createprojectmenu>ul>li>button {
    text-align: left;
}

.createprojectmenu ul>li button {
    text-align: left;
}

.createprojectmenu ul>li button span {
    order: 1 !important
}

.createprojectmenu ul>li button svg {
    order: 2 !important
}

.createprojectmenu ul>li button svg.rotateIcon {
    transform: rotate(-90deg);
}

.position_right {
    left: 100%;
    margin-left: 17px;
}

.uploadmediatable .table thead tr th {
    border-top: 1px solid #DEDEDE !important;
    border-bottom: 1px solid #DEDEDE !important;

}

.mediauploadmore {
    height: 250px;
}

.uploadmediatable .table thead tr th:nth-child(1) {
    border-left: 1px solid #DEDEDE !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.uploadmediatable .table thead tr th:last-child {
    border-right: 1px solid #DEDEDE !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

}

.uploadmediatable .table {
    border-collapse: separate;
    border-spacing: 0;
}

.basic_form .form-group+.form-group {
    margin-top: 0;
}

.basic_form .form-group .form-control,
.modalForm .form-group .form-control {
    border-radius: 8px !important;
}

.rightmodal.modal-dialog {
    max-width: 440px !important;
    height: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-radius: 0;
    height: 100%;
    right: -440px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out !important;
}

.modal.show .rightmodal.modal-dialog {
    transform: none;
    right: 0;
}

.rightmodal .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
}

.rightmodal .form-group+.form-group {
    margin-top: 0;
}

.righmodalfooter .pr-btn {
    padding: 10px 20px;
    font-weight: 600;
    font-family: var(--inter);
    border-radius: 8px;
}

.righmodalfooter .outline-btn {
    padding: 10px 20px;
    border-radius: 8px;
    font-family: var(--inter);
}


.righmodalfooter .outline-btn:hover {
    background-color: var(--black-dark-100);
}

@media only screen and (max-width: 767px) {
    .all-pr .settings-title .form-group {
        width: 100%;
    }

    .proejctheading {
        transition: 0.5s all;
    }

    .scrollable-div.active .proejctheading {
        display: none !important;
    }

    .scrollable-div.active {
        margin-top: 6px;
    }

}

@media only screen and (max-width: 600px) {
    .uplaodyunormalgrop {
        gap: 12px;

    }

    .uplaodyunormalgrop button svg {
        display: none;
    }

    .uplaodyunormalgrop button {
        font-size: 14px;
        white-space: nowrap;
        justify-content: center;

    }

    .uplaodyunormalgrop button:nth-child(1) {
        width: 60%;
    }


    .mediauploadmore {
        height: 140px;
    }

    .mediafileupload {
        padding-right: 0 !important;
    }
}