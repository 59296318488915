/* .table-permission tbody tr td:nth-child(1) {
    
} */

.table-permission thead tr th,
.table-permission tbody tr td {
    border: 1px solid #e5e7eb !important;
}

.table-permission .active {
    border: 1px solid #0062FF !important;
}

.table-permission tbody tr:hover td {
    /* background-color: #0062FF0D !important; */
}

.table-permission tbody tr:hover {
    /* background-color: #E5EFFF !important; */
}

.table-permission tbody tr:hover td:nth-child(1) {
    /* background-color: #0062FF0D */
}

.table-permission thead tr th .role-text:hover input {
    border: 1px solid #92929E;
    border-radius: 4px;
    cursor: pointer;
}

/* .table-permission thead tr th .role-text input:hover {
    border: 1px solid #0064FF;
} */

.table-permission thead tr th .role-text input:focus {
    border: 1px solid #0064FF !important;
}

.role-text-active {
    border: 1px solid #0064FF !important;
}

/* to be remove */
.table-permission tbody tr td:nth-child(1) {
    background-color: white !important;
}

.table-permission thead tr th:nth-child(1),
.table-permission tbody tr td:nth-child(1) {
    position: sticky;
    left: 0px;
    text-align: center;
    min-width: 225px;
    max-width: 225px;
    box-shadow: 2px 2px 8px rgba(219, 226, 239, 0.5);
    background-color: solid;
}