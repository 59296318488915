.template-bottom-shadow {
    box-shadow: 0px -4px 36px 0px rgba(0, 0, 0, 0.12);
}

.preview-linear-gradient {
    background: linear-gradient(180deg, #FFF 48.82%, #FFF 72.44%);
    background: linear-gradient(180deg, color(display-p3 1 1 1) 48.82%, color(display-p3 1 1 1 / 0.00) 72.44%);
}

.button-shadow {
    box-shadow: 0px 1px 3px 0px rgba(14, 24, 41, 0.10), 0px 1px 2px 0px rgba(14, 24, 41, 0.06);
    box-shadow: 0px 1px 3px 0px color(display-p3 0.0627 0.0941 0.1569 / 0.10), 0px 1px 2px 0px color(display-p3 0.0627 0.0941 0.1569 / 0.06);
}

.template-preview .modal-content {
    width: 352px !important;
    border-radius: 12px !important;
}

.template-preview .modal-dialog-centered {
    justify-content: center !important;
}

.action-svg:hover svg path {
    fill: none;
    stroke: #FC5A5A !important;
}

.svg-action-button-shadow {
    box-shadow: 0px 1px 3px 0px rgba(14, 24, 41, 0.10), 0px 1px 2px 0px rgba(14, 24, 41, 0.06);
    box-shadow: 0px 1px 3px 0px color(display-p3 0.0627 0.0941 0.1569 / 0.10), 0px 1px 2px 0px color(display-p3 0.0627 0.0941 0.1569 / 0.06);
}

.campaign-launch .modal-dialog {
    max-width: 549px !important;
}

.chat-initials {
    background: linear-gradient(94deg, #00B219 15.93%, #33C640 100%);
}

.chat-header {
    box-shadow: 0px 1px 3px 0px rgba(14, 24, 41, 0.10), 0px 1px 2px 0px rgba(14, 24, 41, 0.06);
}

.chat-shadow {
    box-shadow: 0px 1px 2px 0px rgba(14, 24, 41, 0.05);
}

.campaign-detail tbody tr td {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

.campaign-detail .table td,
.campaign-detail .table th {
    border-bottom: 0 !important;
}

.campaign-detail tbody tr td:nth-child(1) {
    padding-left: 12px !important;
}

.campaign-detail .table thead {
    border-radius: 12px !important;
}

.campaign-detail thead {
    position: unset !important;
    border-radius: 12px !important;
}

.chat-date-shadow {
    box-shadow: 0px 1px 3px 0px rgba(14, 24, 41, 0.10), 0px 1px 2px 0px rgba(14, 24, 41, 0.06);
}

.pac-container {
    z-index: 999999 !important;
}