.center-modal .modal-dialog {
    width: 80% !important;
}

.modal-bradcum .breadcrumb-item.active {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.modal-bradcum .breadcrumb-item.active a {
    color: var(--primary-color) !important;
}

.Prepare-content {
    padding-top: 54px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 44px;
}

.Prepare-content h3 {
    margin-bottom: 24px;
}

.Prepare-content ul li {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #696974;
    margin-bottom: 20px;
    display: flex;
}

.Prepare-content ul li+.pr-btn {
    margin-bottom: 20px;
    display: inline-block;
    margin-left: 23px;
}

.Prepare-content ul li span {
    margin-right: 16px;
}

.drag-file {
    width: 600px;
    margin: auto;
}

.file-import .pr-btn,
.def-btn-set .pr-btn {
    padding: 7px 24px
}

.result-according {
    width: 410px;
    display: table;
    margin: auto;
}

.result-according .card {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    box-shadow: unset;
}

.result-according .card .card-header {
    background: transparent;
    box-shadow: unset;
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    color: #171725;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--worksans);
    padding: 12px 8px;
}

.result-according .card .card-body {
    background: #F9F9FB;
    border: 0;
    font-family: var(--worksans);
    padding: 14px 0;
}

.result-according .card .blank {
    max-width: 100px;
    flex: 0 0 100px;
    padding-left: 30px;
}

.result-according .card .card-header>div:nth-child(3) {
    margin-left: auto;
    border: 0;
}

.result-according .card .card-header:hover,
.result-according .card .card-header[aria-expanded="true"] {
    background: #f9f9fb;
}

.result-according .card .card-body>div {
    margin: 8px 0;
}

.result-according .card .card-body .result {
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
}

.result-according .card .card-body .errorrow {
    font-size: 12px;
    color: #696974;
    font-weight: 600;
    font-family: var(--worksans);
}

.simple-row {
    max-width: 300px;
    flex: 0 0 140px;
}

.resutl-heading {
    margin-top: 50px;
    margin-bottom: 23px;
    color: #696974;
    font-size: 12px;
    font-weight: 600;
    font-family: var(--worksans);
    padding: 0 12px;
}

.resutl-heading .two {
    padding-left: 30px !important;
}

.resutl-heading .one {
    max-width: 120px;
    flex: 0 0 120px;
    padding-left: 30px !important;
}

.field-maping .card {
    max-width: 100%;
    box-shadow: unset;
}

.field-maping.match .view-more button:hover svg path {
    stroke: var(--primary-color);
    fill: unset;
}

.field-maping {
    width: 686px;
    display: table;

    margin: 33px auto 0 auto;
}

.fild-according .card-body .row:not(.heading) .col-md-4 {
    padding-right: 0;
    padding-left: 6px;
}

.lead-assignment .css-26l3qy-menu,
.fild-according .css-26l3qy-menu {
    box-shadow: var(--box-shadow);
    border: 0 !important;
}

.lead-assignment-inner {


    height: 370px;
    overflow-y: auto;
    overflow-x: hidden;
}

.lead-assignment {
    width: 686px;
    margin: auto;
    margin-top: 34px;


}

.lead-assignment .col-md-12>div {
    width: 550px;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 10px;
}

.block .css-1insrsq-control {
    background: white;
}

.import-complete {
    padding-top: 150px;

    margin-bottom: 44px;
}

td.file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
    margin-right: 30px;
    white-space: nowrap;
}

.help-box>button {
    position: absolute;
    right: 0;
    bottom: 5px;
}

.import-complete p {
    margin-bottom: 120px !important;
}

.import-complete+.line,
.import-complete+.line+.def-btn-set {
    display: none !important;
}

.help-dialog {
    position: absolute;
    right: 16px;
    bottom: 100px;
    width: 284px;
    height: 353px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.11);
    border-radius: 16px;

    transition: 0.5s all;
    display: none;
}

.help-dialog ul li {
    list-style: disc;
    color: #696974;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    margin-left: 16px;
    margin-bottom: 16px;
}

.help-dialog ul {
    margin-top: 13px;
    padding: 0 16px;
}

.help-box.active .help-dialog {
    display: block;
}

.help-header h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--black);
    margin: 0;
    letter-spacing: 0.1px;
}

.help-header {
    padding: 8px 16px;
    border-bottom: 1px solid #DEDEDE;
}

.upload-sucessfull {
    margin-top: 50px;
    height: 400px;
    overflow-y: auto;
}

.fild-according {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 40px;
}

.fild-according .card-header:hover {
    box-shadow: 0px 4px 6px rgb(68 68 79 / 10%);
    -webkit-box-shadow: 0px 4px 6px rgb(68 68 79 / 10%);
    -moz-box-shadow: 0px 4px 6px rgb(68 68 79 / 10%);
    /* z-index: 0; */
}

.field-maping.match .css-26l3qy-menu {
    z-index: 9;
}

.m_row span:nth-child(1) {
    max-width: 120px;
    flex: 0 0 120px;
}

.m_row:nth-child(even) {
    background: #F9F9FB;
}

.m_row {
    color: #171725;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding: 4px 30px;
}

.not-upload {
    height: 410px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not-upload a {
    margin-bottom: 120px;
}

.fild-according .card-header .row .col-md-6:nth-child(1) {
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
    letter-spacing: 0.1px;
    line-height: 20px;
}

.file-import .abs-text {
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 10px;
    padding: 10px;
}

.all-project-main.import-table-main {
    min-height: calc(100vh - 190px);
    max-height: calc(100vh - 190px);
}

.import-modals .modal-content {
    height: 700px;
}

.uploaded-file-list {
    background: #fff;
    left: 24px;
    padding: 5px;
    border-radius: 5px;
}

.uploaded-file-hide {
    display: none;
}

.uploaded-file:hover+.uploaded-file-hide {
    display: block;
}

.def-btn-set button {
    min-width: 120px;
}

.def-btn-set {
    padding: 24px 0;
    border-top: 1px solid var(--black-dark-400);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.error-show {
    width: 475px;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.error-show .resutl-heading {
    margin-top: 28px;
}

.error-show .show-errors {
    height: 320px;
    overflow-x: hidden;
    overflow-y: auto;
}

.lead-assignment .css-4ljt47-MenuList,
.fild-according .css-4ljt47-MenuList {
    max-height: 145px;
}


td.import-status span {
    width: 130px;
    height: 34px;
    font-weight: 600;
    color: white;
    text-align: center;
    font-size: calc(var(--value) * 7);
    line-height: 34px;
    border-radius: calc(var(--value) * 2);
    align-items: center;
    display: inline-block;
}

td.import-status span.orange-color-btn {
    background: var(--orange);
}

td.import-status span.red-color-btn {
    background: var(--red);
}


td.import-status span.green-color-btn {
    background: var(--green);
}

.import-complete+.def-btn-set {
    display: none !important;
}

.show-errors span.blank {
    padding-left: 18px;
}

.import-leads-table tbody tr td:nth-child(3) {
    max-width: 180px;
}

.import-leads-table tbody tr td:nth-child(2) {
    max-width: 180px;

}


.import-leads-table tbody tr td:nth-child(4) {
    max-width: 180px;
}

.result-according .view-more button:hover svg path {
    fill: none;
    stroke: var(--primary-color);
}

.result-according .view-more .dropdown-item {
    color: var(--black-dark-800) !important;
}

.result-according .view-more .dropdown-item:hover {
    background-color: white;
}

.result-according .view-more .dropdown-item+.dropdown-item {
    margin-top: 2px;
}

.result-according .view-more .dropdown-menu {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.result-according .view-more button {
    margin-left: 12px;
}