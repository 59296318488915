.nav-payment-milestone li a:before {
    content: " ";
    position: absolute;
    left: 0;
    bottom: -25px;
    width: 100%;
    height: 1px;
    background: var(--primary-color);
    opacity: 0;
}

.navpaymentmodal li a:before {
    bottom: -16px
}

.nav-payment-milestone li a.active:before {
    opacity: 9;
}

.nav-payment-milestone li a.active {
    color: var(--primary-color) !important;
}

.hoverpdf {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: 0.5s all;
    opacity: 0;
}

.uplaodpdf:hover .hoverpdf {
    opacity: 9;
}

.hoverpdf button.outline-btn:hover {
    background: var(--black-dark-200);
}

.hoverpdf button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 7px 12px !important;
    gap: 4px;

}

.company-info .uplaodpdf {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 98, 255, 0.1);
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    margin-bottom: 4px;
}

.company-info .line {
    height: 1px;
    background-color: #DEDEDE;
    margin-bottom: 32px;
}

.company-info h2 {
    border-bottom: 1px solid var(--black-dark-400);
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 8px;
    color: var(--black-dark-700);
}

nav.inner-page-breadcrumb a:not([href]):not([tabindex]) {
    color: #696974;
}

.pdfmodal-header .back-btn:hover svg rect {
    fill: var(--black);
}

.react-pdf__message {
    padding: 50px;
    font-size: 16px;
    color: var(--black);
}

.pdfmodal-header h2 {
    max-width: 300px;
}

.react-pdf__Document::-webkit-scrollbar {
    opacity: 0;
    display: none;

}

.pdfmodal-header button:hover svg path {
    fill: white !important;
}

.guidetooltip .tooltip-inner {
    width: auto;
    max-width: 350px;
}

.pdfmodal-header button:hover svg path {
    fill: white;
}

.pdfview .pdfmodal-header {

    background: linear-gradient(0deg, rgba(68, 68, 79, 0.9), rgba(68, 68, 79, 0.9)), linear-gradient(180deg, #000000 -97.73%, rgba(0, 0, 0, 0) 100%);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9999;
    color: white;
    padding: 2px 0;
}

.pdfview .pdfmodal-header .dwnl-btn {
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    padding: 3px 8px;
}

.pdfview .pdfmodal-header .close:hover svg rect {
    fill: rgba(0, 0, 0, 0.3);
}

.pdfview .pdfmodal-header .dwnl-btn:hover {
    background: white;
    color: #44444F !important;
}

.pdfview .pdfmodal-header .dwnl-btn:hover svg path {
    fill: #44444F !important;
}

.pdfview .modal-content {
    margin-top: 25px !important;
}

.guidemodal-open .modal-backdrop.show {
    opacity: 0.85;
}

.plush-minus button:hover svg path {
    fill: white !important;
}

.pdfview button:disabled:hover {
    background: black;
}

.pdfview button:disabled {
    background: #828283;
}

.pdfview button:disabled svg path {
    fill: #44444F;
}

.plush-minus {
    padding: 4px 0;
}

.bottom-preview {
    padding-left: 20px;
    padding-right: 20px;
}

.plush-minus {
    border-left: 1px solid color(srgb 1 1 1 / 0.5);
    margin-left: 18px;
    padding-left: 18px;
}

.bottom-preview {
    background: rgb(87 87 97);
    border-radius: 4px;
    position: fixed;
    bottom: 20px;
}

.bottom-preview .plush-minus button:hover svg rect {
    fill: #3D3D44;
}

.bottom-preview p {
    font-weight: 400;
    font-size: 16px;
    color: white;
}

.bottom-preview button {
    background: transparent !important;
}

.bottom-preview p span:nth-child(2) {
    margin: 0 5px;
}

.pdfview .modal-dialog,
.pdfview .modal-dialog .modal-content {

    border: 0;
}

.react-pdf__Document {
    max-width: 100vw;
    overflow: scroll;
}


.pdfview .modal-dialog {
    max-width: max-content;
}

.pdfview .modal-dialog .react-pdf__Document {
    margin: auto;
}

.pdfview button:hover {
    background: var(--white);
}

.pdfview:not(.pdfmodal-header) button:hover svg path {
    fill: #44444F;
}

.pdfview button.next {
    right: 20px;
    top: 50%;
    position: fixed;

}

.pdfview button {
    background: #828283;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
}

.pdfview button.prev {
    position: fixed;
    left: 20px;
    top: 50%;
}

.name-index {
    display: flex;
}

.name-index .number-count {
    text-align: center;
    width: 20px;
    letter-spacing: 0.5px;
    color: #92929D;
    font-size: 12px;
    line-height: 22px;
}

.name-index div:nth-child(2) {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #44444F;
    margin-left: 4px;
}

.moveboxes>div input {
    width: 16px;
    height: 16px;
    top: 3px;
    position: relative;
}

.moveboxes .item-box {
    display: flex;
    align-items: center;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    width: 100%;
    justify-content: space-between;
    padding: 6px 10px;
}


.moveboxes .item_1,
.moveboxes .item_2,
.moveboxes .item_3,
.moveboxes .item_4,
.moveboxes .item_5 {
    grid-column-start: 1;
}

.moveboxes .item_6,
.moveboxes .item_7,
.moveboxes .item_8,
.moveboxes .item_9,
.moveboxes .item_10 {
    grid-column-start: 2;
}

.moveboxes .item_11,
.moveboxes .item_12,
.moveboxes .item_13,
.moveboxes .item_14,
.moveboxes .item_15 {
    grid-column-start: 3;
}

.moveboxes .item_16,
.moveboxes .item_17,
.moveboxes .item_18,
.moveboxes .item_19,
.moveboxes .item_20 {
    grid-column-start: 4;
}

.moveboxes .item_21,
.moveboxes .item_22,
.moveboxes .item_23,
.moveboxes .item_24,
.moveboxes .item_25 {
    grid-column-start: 5;
}

.moveboxes .item_26,
.moveboxes .item_27,
.moveboxes .item_28,
.moveboxes .item_29,
.moveboxes .item_30 {
    grid-column-start: 6;
}

.moveboxes {
    display: grid;
    grid-auto-flow: row dense;
    max-width: 570px;
    overflow: auto;
    grid-gap: 10px 24px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
    height: 260px;
}

.movemain-box {
    padding-top: 20px;
    border: 1px solid #E2E2EA;
    border-radius: 8px;

}

.moveboxes>div {
    width: 244px;
    display: flex;
    align-items: center;
}

.customise-footer .outline-btn {
    padding: 8px 33px;
}

.customise-footer .pr-btn {
    padding: 9px 33px;
}



.active-avaliabel-row .card.active-column .item button {
    opacity: 0;
}

.active-avaliabel-row .card.active-column .item:hover button {
    opacity: 9;
}

.active-avaliabel-row .card.active-column .item button:hover svg path {
    fill: var(--primary-color);
}

.active-avaliabel-row .card.active-column .item h4 {
    font-size: 14px;
    margin: 0;
    letter-spacing: 0.5px;
    color: #44444F;
    font-weight: 400;
    line-height: 22px;
    font-family: var(--roboto);
    margin-left: 13px;
}

.active-avaliabel-row .card.active-column .item {
    cursor: pointer;
    background: #FAFAFB;
    border-radius: 2px;
    letter-spacing: 0.5px;
    color: #44444F;
    font-size: 14px;
    padding: 4px 12px;
}

.active-avaliabel-row .card.active-column {
    padding-right: 0;
    padding-bottom: 0;
}

.active-avaliabel-row .card .card-header h4 span {
    margin-left: 8px;
}

.active-avaliabel-row .available-card .item:hover:not(.item-disable) {
    cursor: grab;
}

.active-avaliabel-row .available-card .item:hover svg path {
    fill: var(--primary-color);
}

.search-box .close-icon {
    position: absolute;
    right: 10px;
    top: 5px;
}

.search-box .form-control {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    font-size: 14px !important;
    height: 30px !important;
    line-height: 16px;
}


.active-avaliabel-row .card .itms-body::-webkit-scrollbar {
    background: #DEDEDE;
    border-radius: 0;
    width: 8px;

}


.active-avaliabel-row .card .itms-body::-webkit-scrollbar-thumb {
    background-color: #696974;
    border: 0;
    width: 6px;
}

.customision-content a.close {
    position: absolute;
    right: 20px;
    top: 30px;
}

.active-avaliabel-row .card.available-card .itms-body {
    margin-top: 0px;
}

.search-box {
    margin-bottom: 8px !important;
}

.active-avaliabel-row .card .itms-body {
    max-height: 230px;
    min-height: 230px;
    overflow: auto;
    padding-right: 8px;

}

.active-avaliabel-row .card.active-column .item+.item {
    margin-top: 10px;
}

.active-avaliabel-row .card.available-card {
    padding-right: 0;
    padding-bottom: 0;
}

.active-avaliabel-row .card.available-card .card-header {
    padding-right: 12px;
}

.active-avaliabel-row .available-card .item {
    background: #FAFAFB;
    border-radius: 2px;
    letter-spacing: 0.5px;
    color: #44444F;
    font-size: 14px;
    padding: 4px 12px;
}

.active-avaliabel-row .available-card .item h4 {
    font-size: 14px;
    margin: 0;
    letter-spacing: 0.5px;
    color: #44444F;
    font-weight: 400;
    line-height: 22px;
    font-family: var(--roboto);
}

.active-avaliabel-row .available-card .item+.item {
    margin-top: 10px;
}

.active-avaliabel-row .card {
    padding: 16px 12px;
    box-shadow: unset;
    border: 1px solid #E2E2EA;
    border-radius: 8px;
}

.active-avaliabel-row .card .card-header {
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.customisecolumnstabs .nav-pills .nav-link:hover,
.customisecolumnstabs .nav-pills .show>.nav-link:hover {
    color: var(--primary-color);
    background: #E5EFFF;
}

.customisecolumnstabs .nav-pills {
    margin-bottom: 16px;
}

.customisecolumnstabs {
    margin-top: 24px;
}

.customisecolumnstabs .nav-pills .nav-link {
    letter-spacing: 0.5px;
    color: #696974;
    font-size: 14px;
    font-family: var(--roboto);
    font-weight: 400;
    padding: 5px 12px;
    border-radius: 4px;
}

.customisecolumnstabs .nav-pills .nav-link.active,
.customisecolumnstabs .nav-pills .show>.nav-link {
    color: var(--primary-color);
    background: #E5EFFF;
    font-weight: 500;
}

.customisecolumnstabs .nav-item+.nav-item {
    margin-left: 8px;
}

.customise-footer {
    border-top: 0.5px solid #D5D5DC;
    padding: 12px 12px 16px 12px;
}

.CustomiseColumns .modal-content .modal-body {
    padding: 0;
}

.CustomiseColumns .modal-content .modal-body .customision-content {
    padding: 24px;
}

.CustomiseColumns .modal-content {
    border: 0;
}

.CustomiseColumns .modal-dialog {
    max-width: 620px;
}

.active-avaliabel-row .card.active-column .item.item-disable {
    background: #F1F1F5;
}

.active-avaliabel-row .card.active-column .item.item-disable button {
    display: none;
}

.active-avaliabel-row .card.active-column .item.item-disable {
    cursor: no-drop;

}

.active-avaliabel-row .card.active-column .item.item-disable h4 {
    color: #92929D;
}

.active-avaliabel-row .card.active-column .item.item-disable svg path {
    stroke: #F1F1F5;
    fill: #92929D;
}

.def-table .table thead tr th {
    border-top: 1px solid #E2E2EA !important;
    border-bottom: 1px solid #E2E2EA !important;
}

.def-table table thead tr th:first-child {
    border-left: 1px solid #E2E2EA !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.def-table table thead tr th:last-child {
    border-right: 1px solid #E2E2EA !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.def-table table thead tr {
    border-spacing: 0 !important;
}

.def-table .table {
    border-collapse: separate;
    border-spacing: 0;
}

.def-tabs .nav-item .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.def-tabs .nav-item .nav-link:before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background: #252C32;
    opacity: 0;
}

.def-tabs .nav-item .nav-link {
    position: relative;
}

.def-tabs .nav-item+.nav-item {
    margin-left: 32px;
}

.def-tabs .nav-item .nav-link.active:before {
    opacity: 9;
}

.def-tabs .nav-item .nav-link.active {
    font-weight: 500;
}

nav.inner-page-breadcrumb ul li+li {
    margin-left: 6px;
}

nav.inner-page-breadcrumb ul li svg {
    margin-right: 6px;
}

nav.inner-page-breadcrumb ul li a.active:hover,
nav.inner-page-breadcrumb ul li a:hover,
nav.inner-page-breadcrumb ul li a.active {
    color: #171725 !important;
}



nav.inner-page-breadcrumb ul li a,
nav.inner-page-breadcrumb ul li {
    letter-spacing: -0.006em;
    color: #696974;
    font-size: 14px;
    font-weight: 400;
}

nav.inner-page-breadcrumb ul li a:hover {

    color: var(--primary-color) !important;
}

.integrations .box .card.comming-soon a.pr-btn {
    background: rgba(0, 98, 255, 0.1);
    border-color: snow;
    color: var(--primary-color) !important;
    border-radius: 4px !important;
    line-height: 20px;
}

.video-cards .card .dead-reason-notes .right-text {
    padding-left: 8px;
}

.roles-table .outline-btn:hover {
    background: var(--primary-color);
    color: white !important;
}

.del-modal .modal-content {
    border: 0;
    /* box-shadow: var(--box-shadow); */
}

.tooltip::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    position: absolute;
    clip-path: polygon(50% 0%, 54% -2%, 100% 50%, 0% 50%);
    -webkit-clip-path: polygon(50% 0%, 54% -2%, 100% 50%, 0% 50%);
    background: #162c50;

}

.tooltip.show {
    opacity: 9 !important;
}

.tooltip-inner a:hover {
    background-color: transparent !important;
}

.tooltip-inner a+a {
    margin-top: 4px;
}

.tooltip-inner a {
    padding-left: 13px !important;
}

.tooltip-inner {
    text-align: left !important;
    background: #162c50 !important;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.11);
    padding: 12px !important;
    color: white !important;
    font-size: 14px;
    font-weight: 400;
    z-index: -1;

}

.tooltip-arrow {
    width: 20px;
    height: 20px;
    display: none;
}

.tooltip[x-placement="bottom"] {
    inset: 10px auto auto 0px !important
}

.tooltip[x-placement="bottom"]:after {
    top: -3px;
    left: calc(50% - 10px);
}

.tooltip[x-placement="top"] {
    inset: auto auto 10px 0px !important;
}

.tooltip[x-placement="right"]:after {
    clip-path: polygon(100% 100%, 100% 0, 0 50%);
    width: 15px;
    top: calc(50% - 10px);
    left: -15px;
}

.tooltip[x-placement="right"] {
    inset: 0px auto auto 20px !important;
}

.tooltip[x-placement="left"]:after {
    right: -14px;
    top: calc(50% - 10px);
    clip-path: polygon(0 100%, 0 0, 100% 50%);
    width: 15px;
}

.tooltip[x-placement="left"] {
    inset: 0px 15px auto auto !important;
}

.tooltip[x-placement="top"]:after {
    left: 43%;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    height: 15px;
}

.field-maping.match h2 button.btn {
    background: transparent;
    border: 0;
    padding-left: 8px;
}

.manage-account-title input.searchinput {
    width: 240px;
}

.main-import-table {
    box-shadow: 2px 2px 8px rgba(213, 213, 220, 0.8);
    margin-top: 48px;
    border-radius: 8px;
    height: 380px;
}

.tbale-head {
    background: var(--primary-color);
    color: white;
    display: flex;
    flex: 0 0 465px;
    width: 465px;
    box-shadow: 2px 2px 8px rgb(213 213 220 / 80%);
    border-radius: 8px 8px 0 0px;
    height: 45px;
    text-align: center;
    line-height: 45px;

}

.tbale-head div:nth-child(1) {
    width: 120px;
    border-right: 2px solid white;
    text-align: center;
}

.tbale-head>div {
    font-family: var(--popins) !important;
    font-weight: 500;
    letter-spacing: .5px;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    line-height: 45px;
}


.result-according .view-more button:hover .dropdown-menu {
    display: block !important;
}

.card-footer-btm {
    padding: 12px;
    background: #FAFAFB;
    border: 1px solid #E2E2EA;
    border-radius: 0px 0px 8px 8px;
    border-top: 0;
}

.fild-according .card-header button svg:hover path {
    stroke: var(--primary-color);
    cursor: pointer;
}

.fild-according .collapse.show .row.heading img {
    display: table;
    margin: auto;
}

.fild-according .card-header svg {
    display: inline-block;

}

.result-according .dropdown.view-more:hover .dropdown-menu {
    display: block !important;
}

.result-according>div p:last-child .dropdown-menu,
.result-according>div p:nth-child(2) .dropdown-menu {
    bottom: 0;
    top: auto;
    right: 100%;
    left: auto;
}

.settings-title {
    border-bottom: 1px solid var(--black-dark-400);
    padding: 24px 24px 16px 24px
}

.show-leads.active {
    right: 0;
}

.show-leads.hide {
    display: none;
}

.csv-export-modal .modal-footer {
    border: 0;
}

.csv-export-header {
    background: var(--red);
    text-align: center;
    margin-top: -28px;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 11px 0;
}

.csv-export-modal h5 {
    text-align: center;
    color: var(--black);
    letter-spacing: 0.1px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 12px;
}

.csv-export-modal p {
    text-align: center;
    letter-spacing: 0.2px;
    color: #696974;
}

.csv-export-modal .modal-footer button {
    padding: 7px 0;
    font-weight: 500 !important;
    font-size: 14px;
    font-family: var(--popins);
}


.show-leads {
    position: absolute;
    top: 180px;
    right: -400px;
    z-index: 99;
    width: 400px;

    transition: 0.5s all;
    height: 387px;
    overflow: auto;
}

.setting-menu {
    width: 224px;
    flex: 0 0 224px;
    padding-bottom: 18px;
    background: white;
    /* box-shadow: inset -1px 0px 0px #E2E2EA; */
    border-right: 1px solid #E2E2EA;
    height: 100%;
    overflow: auto;


}

.setting-menu h2 {
    padding-left: 18px;
    padding-bottom: 18px;
    padding-top: 18px;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 9;
}

.setting-menu .card-header {
    cursor: pointer;
    padding: 12px 18px;
    border: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 22px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.setting-menu .card-header[aria-expanded="true"] svg {
    transform: rotateX(180deg);
}

.setting-menu .card-header[aria-expanded="true"] svg path {
    fill: var(--black);
}

.setting-menu .card-header svg {
    transition: 0.5s all;
}

.setting-menu .card {
    border-bottom: 1px solid var(--black-dark-400) !important;
    border: none;
    border-radius: 0;
    padding: 0 !important;
}

.setting-menu .card .nav a {
    margin-bottom: 8px;
}


.setting-menu div#accordion {
    margin-right: 1px;
}

.setting-menu .card a {
    padding: 8px 18px;
    display: block;
    margin: 0 15px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04px;
    color: var(--black-dark-700);
    font-weight: 500;
}

.setting-menu .card ul {
    margin: 0 1px 8px 1px;
}

.setting-menu .card ul li+li {
    margin-top: 8px;
}

.setting-menu .card a:hover,
.setting-menu .card a.active {
    background: #F0F6FF;
    color: var(--primary-color);
}

.data-import {
    background: white;
    width: calc(100% - 222px);
    flex: 0 0 calc(100% - 224px);
}

ol.breadcrumb {
    background: transparent;
    border-bottom: 2px solid #E2E2EA;
    border-radius: 0;
    padding: 0 0 10px 0;
    margin: 0;
}

ol.breadcrumb li a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01px;
    color: var(--black-dark-700);
    font-weight: 400;
    padding: 0 20px;
}

ol.breadcrumb li.active a {
    font-weight: 500;
    color: var(--black);
    position: relative;
}


.breadcrumb-item+.breadcrumb-item {
    padding-left: 0 !important;
    position: relative;
    margin-left: 20px;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: ">";
    position: absolute !important;
    left: -15px !important;
    padding-right: 0 !important;

}

ol.breadcrumb li a:before {
    content: " ";
    position: absolute;
    left: 0;
    bottom: -12px;
    width: 100%;
    height: 2px;
    background: var(--black);
    transition: 0.5s all;
    opacity: 0;
}

ol.breadcrumb li.active a:before {
    opacity: 9;
}

.file-import {
    margin-top: 24px;
}

.abs-text {
    position: absolute;
    bottom: 15px;
    width: 386px;
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    color: var(--black-dark-700);
    font-weight: normal;
}

.import-roule ul li {
    display: flex;
    margin-bottom: 16px;
}

.import-roule ul li span {
    margin-right: 16px;
}

.import-roule ul {
    padding-right: 110px;
}

.reset-submit {
    border-left: 1px solid #E2E2EA;
    background: white;
    width: 100%;
    border-top: 1px solid #E2E2EA;
}

.left {
    width: 226px;
    flex: 0 0 226px;
}


.fild-according .card {
    padding: 0;
    border: 0;
    border-bottom: 1px solid #E2E2EA;
    border-radius: 0;
}

.fild-according .card-header[aria-expanded="true"] .icon svg:nth-child(2) {}


.icon {
    width: 20px;
    margin-right: 20px;
}

.fild-according button.save-btn {
    border: 0;
    background: 0;
    margin-right: 20px;
    letter-spacing: 0.4px;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
    border-radius: 4px;
    background: #F0F6FF;
    letter-spacing: 0.04px;
    padding: 4px 8px;
}

.fild-according .card-header button {
    background-color: transparent !important;
    border: 0;
    padding-left: 8px;
}

.fild-according .card-header[aria-expanded="true"] button {
    opacity: 9;
}

.fild-according .card-body {
    padding: 20px 26px;
    background: #FAFAFB;
    border-top: 1px solid #E2E2EA;
}


.fild-according .card-body .row:not(.heading)+.row {
    border-top: 0.5px solid #D5D5DC;
}


.fild-according .card-body .row:not(.heading) .col-md-6 {
    padding: 0;
}


.card-notification {
    width: 260px;
    margin-left: auto;
}

.card-notification .card {
    border: 1px solid #E2E2EA;
    border-radius: 4px;
}

.card-notification .card .card-header {
    background: #F1F1F5;
    border-bottom: 1px solid #E2E2EA;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 14px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
}

.card-notification .card .card-header svg {
    margin-right: 8px;
}

.card-notification .card .card-body {
    padding: 16px;
    font-size: 14px;
    line-height: 22px;
}

.block {
    display: flex;
    align-items: flex-start;
}




.block .input {
    padding-top: 3px;
    margin-right: 16px;
}

.blocks {
    width: 557px;
    padding: 16px 22px;
    background: var(--black-dark-100);
    border-radius: 4px;
}

.block p {
    font-size: 14px;
    line-height: 22px;
    color: var(--black-dark-700);
    font-weight: 400;
    margin-bottom: 0;
}


.block+.block {
    margin-top: 16px;
}

.deletemodal .modal-footer {
    background: #E5E5E5;
    padding: 10px 16px;
}

.deletemodal .modal-body {
    border-bottom: 1px solid #E2E2EA;
}



.integrations .box {

    padding-top: 24px;
    padding-right: 24px;
    flex-wrap: wrap;
    overflow: scroll;
}

.integrations .box .card p {
    min-height: 45px;
}

.integrations .box .card {
    border-radius: 12px;
    box-shadow: 2px 2px 8px rgba(219, 226, 239, 0.5);
    max-width: 100%;
    /* max-height: 210px; */
    padding: 20px;
    flex: 0 0 100%;
    /* height: 210px; */
    position: relative;
    margin: 15px 0;
}

.integrations .box .card picture {
    margin-bottom: 12px;
}

.integrations .box .card picture {
    max-width: 32px;
}

.integrations .box .card a {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.086541px;
    color: #0062FF;
    margin-top: 20px;
    padding: 5px 15px;
    border-radius: 4px;
    display: table;
    margin-left: auto;
}

.import-for-mobile .card {
    max-width: 90%;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
    transform: translateX(-12px);
    border-radius: 8px;
    border: 0;
}

.import-for-mobile a.pr-btn {
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 24px;
    display: table;
    margin-left: auto;
    margin-right: auto;
    background: #0062FF;
    opacity: 9;
    width: 90%;
    text-align: center;
    transform: translateX(-12px);
}

.integrations .box .card a:hover {
    background: var(--primary-color);
    color: white;
}

.serch-btn {
    color: var(--black-dark-800);
    background: var(--black-dark-200);
    padding: 5px 12px;
    letter-spacing: 0.5px;
    border-radius: 4px;
}

.serch-btn:hover {
    background-color: var(--primary-color);
    color: var(--white);
}

.serch-btn.active {
    background: var(--Blue-100);
    color: var(--primary-color);
}

.urer-table table thead {
    background-color: #FAFAFB;
    position: sticky;
    top: 0;
    z-index: 9;

}

.uder-table-main {
    padding-top: 24px;
    border-top: 1px solid #D5D5DC;

}

.urer-table table tbody tr:nth-child(1) td {
    border: 0;
}

.table tbody tr td {
    font-family: var(--worksans) !important;
}

thead tr th {
    border-top: 0;
    border-bottom: 0;
    padding-left: 16px !important;
    padding-right: 16px !important;
    z-index: 1;
    font-weight: var(--ro-regular);
    color: var(--black-dark-800);
    background-color: #FAFAFB;
    white-space: nowrap;
    border: 0 !important;
    font-family: var(--popins) !important;
    font-weight: 500;

}

table tbody tr:nth-child(1) td {
    border: 0;
}

.edit-table {
    max-width: 700px;
}


.all-project-main table tbody tr td:nth-child(1) {
    left: -1px !important;
}

.uder-table-main {
    width: 100%;
    height: calc(100vh - 180px);
    overflow: hidden;
}

.urer-table {
    height: 100%;
    overflow: auto;
}

.all-project-main {
    position: relative;
    min-width: 100%;
    overflow: auto;
    height: calc(100vh - 250px);
    z-index: 1;
}


.chang-passowrd button {
    padding: 10px 26px;
    font-size: 16px;
}

.chang-passowrd .pr-btn {
    width: 236px;
}

.company-info button {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 26px;
    width: 236px;
}

.uder-table-main .badge.bg-success {
    background: #82C43C !important;
}

.uder-table-main .badge.bg-danger {
    background: #FC5A5A !important;
}

.all-pr .all-projects-table {
    max-width: 100%;
}

.api-box {
    border: 1px solid #E2E2EA;
}

.api-box__header {
    background-color: var(--primary-color);
    color: #fff;
    padding: 8px 0;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;

}

.i-icon {
    height: 20px;
    width: 20px;
    border: 1px #fff;
}

.api-box__body {
    padding: 16px;
}

/* span {
    text-transform: lowercase;
    display: inline-block;
}

span::first-letter {
    text-transform: capitalize !important;
} */

.text-capitalize {
    text-transform: capitalize !important;
}

.api-box {
    overflow-wrap: break-word;
}

.mobile-menu button.btn svg {
    margin-right: 14px;
}

.mobile-menu .dropdown-menu {
    width: 100%;
}

.mobile-menu button.btn {
    display: flex;
    align-items: center;
    background: #F0F6FF;
    border-radius: 0px 8px 8px 0px;
    padding: 10px 18px !important;
    border: 0;
    color: var(--primary-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    letter-spacing: 0.1px;

    padding: 0;
}

.mobile-menu button.btn:focus {
    outline: 0;
    box-shadow: unset;

}

.urer-table .def-table table tbody tr td:last-child,
.all-project-table .def-table table tbody tr td:last-child,
.facbook-leads-add .def-table tbody tr td:last-child,
.lnding-page-table .def-table tbody tr td:last-child,
.housing-table .def-table tbody tr td:last-child,
.fb-lead-integration.def-table tbody tr td:last-child,
.fb-manage-account .def-table tbody tr td:last-child,
.fb-innerpageintegratoin .def-table tbody tr td:last-child {
    position: sticky;
    right: -10px;
}


@media only screen and (max-width: 600px) {





    .lnding-page-table .def-table .table tbody tr td:nth-child(2),
    .lnding-page-table .def-table .table thead tr th:nth-child(2),
    .housing-table .def-table .table tbody tr td:nth-child(2),
    .housing-table .def-table .table thead tr th:nth-child(2) {
        max-width: 240px !important;
        width: 340px !important;
    }

    .lnding-page-table .def-table {
        height: 60vh;
    }


    .facebook-head .pr-btn {
        max-width: unset;

        height: 32px;
    }

    .facebook-head .form-group {
        WIDTH: 100%;
    }

    .facebook-head .pr-btn span {
        white-space: nowrap;

    }

    .exprot-table-main .def-table {
        height: 60vh;
    }

    .integrations>.ml-24 {
        margin-left: 0;
    }

    .integrations .box {
        overflow-x: hidden;
    }

    .urer-table .def-table table tbody tr td:last-child,
    .all-project-table .def-table table tbody tr td:last-child,
    .facbook-leads-add .def-table tbody tr td:last-child,
    .lnding-page-table .def-table tbody tr td:last-child,
    .housing-table .def-table tbody tr td:last-child,
    .fb-lead-integration.def-table tbody tr td:last-child,
    .fb-manage-account .def-table tbody tr td:last-child,
    .fb-innerpageintegratoin .def-table tbody tr td:last-child {
        position: unset !important;

    }


    .import-table-main .def-table {
        height: 70vh;
    }

    .import-for-mobile {
        padding: 15px;
    }

    .import-for-mobile .card {
        transform: unset;
        width: 100%;
        max-width: unset;
        margin-top: 90px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .import-for-mobile a.pr-btn {
        transform: unset;
    }

    .company-info {
        margin-left: 15px;
        margin-right: 0px;
        height: 75vh !important;
        padding-right: 15px !important;
    }

    .company-info form .row .col-md-6+.col-md-6 {
        margin-top: 16px;
    }

    .settings-title {
        /* margin-left: 15px;
        margin-right: 15px;
        margin-top: 16px; */
        padding: 14px 16px 16px 16px;
        margin-bottom: 0;
    }



    .setting-change-password {
        margin-left: 15px;
        margin-right: 15px;
    }

    .personal-info-content {
        margin-left: 15px;
        margin-right: 15px;
    }

    .settings-title h1.fz28 {
        font-size: 26px;
        margin-bottom: 8px;
    }

    .settings-title p.fz16 {
        margin-bottom: 0;
    }

    .edit-input-modal button {
        margin: 0 !important;
        padding: 0 !important;
    }

    .edit-input-modal button+button {
        margin-left: 8px !important;
    }

    .name-box button {
        opacity: 9 !important;
    }

    .main-box .name-box h4 {
        font-size: 14px;
    }

    .sidebar-body>.mt-20 {
        margin: 0;
    }

    .tabs .tab-content {
        padding-right: 0 !important;
    }


    .main-box .name-box h3 {
        font-size: 14px;
    }

    .data-import.px-24 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .edit-input-modal {
        margin-bottom: 20px;
    }

    .setting-title h1 {
        margin-bottom: 0;
    }

    .all-leads-head {
        padding-right: 15px;
    }

    .all-project-main {
        max-height: calc(100vh - 320px) !important;
        min-height: calc(100vh - 320px) !important;
        padding: 0 !important;
    }

    .all-projects .pagination-custom {
        bottom: 85px;
    }

    .data-import {
        width: 100%;
        flex: 0 0 100%;
    }

    .def-table {
        padding-right: 0px !important;

    }

    .lead-assignment_table .def-table {
        height: 60vh;
        padding-bottom: 20px;
    }

    .urer-table .def-table {
        padding-bottom: 40px;

    }

    .roles-table .def-table {
        height: 65vh !important;
        --webkit-transform: translate3d(0, 0, 0);
        padding-bottom: 40px;
    }

    .lead-assignment_table .def-table .table tbody tr td:nth-child(2) {
        z-index: 1;
    }

    .assignment-title .form-group {
        width: 100%;
        margin-top: 12px;
    }

    .agency-table.ml-24 {
        margin-left: 15px;

    }

    body,
    div,
    table {
        --webkit-overflow-scrolling: touch;
        overscroll-behavior: contain;
    }

    .settings-title .col-md-6+.col-md-6 {
        margin-top: 14px;
    }

    .table tbody tr td:nth-child(2) {
        z-index: 0;
    }

    .facbook-leads-add {
        margin-left: 15px;
    }

    .setting-menu::-webkit-scrollbar {
        width: 0;
    }

    .settings-title .col-md-5 .form-group {
        width: 100%;
    }

    .settings-title .col-md-5 .form-group+.pr-btn span {
        white-space: nowrap;
    }

    .settings-title .col-md-5 .form-group+.pr-btn {
        height: 34px;
    }

    .mt-24.ml-24.lnding-page-table {
        margin-left: 15px;
    }

    .manage-account-title input.searchinput {
        width: 100%;

    }

    .manage-account-title .form-group {
        margin-top: 14px;
    }


}

.active-scroll-spy {
    background-color: yellowgreen;
    border-radius: 15px;
}