.yarl__icon {
    height: 44px !important;
    width: 44px !important;
}

.yarl__flex_center.yarl__thumbnails_thumbnail:not(.yarl__thumbnails_thumbnail_active) {
    border-color: transparent;
}

button.yarl__button.yarl__navigation_prev:disabled,
button.yarl__button.yarl__navigation_next:disabled {
    background: transparent;
}

ul li a.scrollsyp_active {
    color: var(--primary-color) !important;
    font-weight: 700;
}

.scrollsyp_active::before {
    opacity: 9 !important;
}

.AddYourListModal.modal-xl {
    max-width: 474px;
}

.AddYourListModal.modal-xl .modal-content {
    height: unset;
    border-radius: 0;
}

.KYCModal.modal {
    width: calc(100% - 64px);
    left: 64px;
    height: calc(100% - 48px);
    top: 48px;
}

.lpFilterarea {
    font-family: var(--inter);
}

.lpFilterarea .aply-btn .outline-btn:hover {
    background: rgba(0, 0, 0, 0.05);
}

.lpFilterarea .aply-btn .outline-btn {
    border: 0;
    font-size: 14px;
    font-weight: 500;
}

.KYCModal .invalid>div>div {
    border-color: var(--red);
}

.lpFilterarea .outline-btn {
    line-height: 1.5;
}

.lpFilterarea .outline-btn:hover {
    background: var(--black-dark-100);
}

.reservsetmodal .form-group.invalid label {
    color: var(--black-dark-700) !important;
}

/* filter modal for mobile  */


.FilterModal {
    display: none !important;
}

.filtermodalback {
    display: none;
}

.FilterModalHeader {
    border-bottom: 1px solid var(--black-dark-500);
    padding: 20px 20px;
}

.FilterModalHeader h4 {
    margin-bottom: 0;
    color: var(--black);
    font-family: var(--inter);
    font-size: 24px !important;
    font-weight: 500;

}

.FilterModal .input-list {
    height: 210px;
    overflow: auto;
}

.mobselectdate {
    font-size: 14px;
    line-height: 16px;
    font-family: var(--inter);
    border-radius: 6px;
    color: var(--black-dark-800);
    border: 1px solid #DEDEDE;
    white-space: nowrap;
    font-weight: 500;
    padding: 6px 10px;
    margin-right: 4px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.filterdateModal .dropdown-item {
    color: var(--black-dark-700);
    font-size: 16px;
}

.FilterModal .input-list .dropdown-item label {
    font-size: 16px;
    margin-top: 2px;
    margin-left: 4px;
}

.FilterModal .input-list .dropdown-item {
    line-height: 1.5;
    padding: 10px 26px;
}

.filterdateModal .dropdown-item:active {
    color: var(--black-dark-700);
}

.FilterModal .input-list .dropdown-item:focus,
.FilterModal .input-list .dropdown-item:hover,
.filterdateModal .dropdown-item:focus,
.filterdateModal .dropdown-item:hover {
    background: var(--black-dark-300);
}

.FilterModal .drop-search .searchinput {
    width: 100%;
}

.FilterModal .drop-search {
    padding: 0 24px;
    margin: 10px 0;
}

.FilterModal .aply-btn {
    display: flex;
    flex-direction: row;
    gap: 18px;
    padding: 20px 20px;
    border-top: 1px solid var(--black-dark-500);
}

.FilterModal .aply-btn button {
    width: 100%;
    height: 44px;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--inter);
}


/*  */

.lunchtodyaOwl .owl-theme .owl-nav button:hover,
.registerdEventes .owl-theme .owl-nav button:hover,
.past-events .owl-theme .owl-nav button:hover {
    background: var(--primary-color) !important;
}

.lunchtodyaOwl .owl-theme .owl-nav button:hover img,
.registerdEventes .owl-theme .owl-nav button:hover img,
.past-events .owl-theme .owl-nav button:hover img {
    filter: brightness(0) invert(1);
}

.lpFilterarea .download-btn-group .assigned-drop .dropdown>button:not(.active):focus {
    border-color: #44444f !important;
}

.lpFilterarea.an-title .download-btn-group .assigned-drop button.outline.active svg path {
    fill: var(--primary-color) !important;
}

.cursor-auto {
    cursor: auto !important;
}

.reservsetmodal .d-md-block.d-none+.d-md-none>div p {
    margin-bottom: 0;
}

.reservsetmodal .d-md-block.d-none {
    display: none !important;
}

.reservsetmodal .d-md-block.d-none+.d-md-none {
    display: block !important;
    opacity: 9 !important;
}

.reservsetmodal .d-md-block.d-none+.d-md-none>div {
    opacity: 9;
}

.reservsetmodal .d-md-block.d-none+.d-md-none>div button {
    display: none;
}

.lpFilterarea .download-btn-group .assigned-drop:nth-child(1)>.dropdown>button {
    width: 133px !important;
}

.lpFilterarea .download-btn-group .assigned-drop:nth-child(1)>.dropdown button span {
    max-width: 100px;
}

.lpFilterarea .download-btn-group .assigned-drop button svg {
    margin-left: auto !important;
}

.lpFilterarea .download-btn-group .assigned-drop:nth-child(2)>.dropdown>button {
    width: 133px !important;
}

.lpFilterarea .download-btn-group .assigned-drop button span {
    max-width: 100px;
}

.lpFilterarea .download-btn-group .assigned-drop:nth-child(3)>.dropdown>button {
    width: 150px !important;
}

.lpFilterarea .download-btn-group .assigned-drop:nth-child(4)>.dropdown>button {
    width: 150px !important;
}

.disablebtn {
    background: #979797;
    border-color: #979797;
    cursor: not-allowed !important;
}

.disablebtn:hover {
    background: #979797;
    border-color: #979797;
}

.lpcardtooptip .info-msg {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.lpcardtooptip .info-msg svg {
    width: 20px;
    flex: 0 0 20px;
    margin-top: 2px;
}

.registerdEventes .owl-nav button.owl-prev:hover img:nth-child(1),
.registerdEventes .owl-nav button.owl-next:hover img:nth-child(1) {
    display: block;
}

.registerdEventes .owl-nav button.owl-prev:hover img:nth-child(2),
.registerdEventes .owl-nav button.owl-next:hover img:nth-child(2) {
    display: none;
}



.registerdEventes .owl-theme .owl-nav {
    position: absolute;
    top: -52px;
    display: flex;
    justify-content: end;
    width: 100%;
}

.registerdEventes .owl-theme .owl-nav button.owl-prev {
    left: auto;
    margin-right: 60px !important;
}

.registerdEventes .owl-theme .owl-nav button.owl-next {
    right: 3px;
}

.registerdEventes .owl-theme .owl-nav button:hover,
.registerdEventes .owl-theme .owl-nav [class*='owl-']:hover {
    background-color: white;
}

.registerdEventes .owl-theme .owl-nav button {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DEDEDE;
    background: white;
    border-radius: 50%;
}

.registerdEventes .owl-carousel .owl-stage-outer {
    padding: 20px 5px;
}

.lpFilterarea {
    gap: 20px;
}

.an-title.lpFilterarea {
    margin: 0;
    padding: 0;
    border: 0;
}

.lpFilterarea .download-btn-group {
    gap: 8px;
}

.lpFilterarea .download-btn-group .assigned-drop button.outline {
    width: auto;
    min-width: unset;
    padding: 6px 10px !important;
    font-size: 14px;
    line-height: 16px;
    font-family: var(--inter);
    border-radius: 6px;
    color: var(--black-dark-800);
    border: 1px solid #DEDEDE;

}


.lpFilterarea .download-btn-group .assigned-drop button.outline svg {
    margin-left: 8px;
}

.disableseatreaserved {
    opacity: 0.7;
    cursor: not-allowed;
}

.disableseatreaserved:hover {
    opacity: 0.7;
    cursor: not-allowed;
}

.uploaddoc .layer {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    top: 0;
    background: black;
    opacity: 0;
    transition: 0.3s all;
}

.kycfom .form-group.invalid label {
    color: #696974 !important;
}

.uploaddoc:hover .layer {
    opacity: 0.3;
}

.lunchtodyaOwl .owl-theme .owl-dots .owl-dot {
    margin: 0 !important;
    padding: 0 !important;
}

.lunchtodyaOwl .owl-theme .owl-dots .owl-dot span {
    margin: 0;
    padding: 0;
    width: 12px;
    height: 12px;
    background: var(--primary);
    opacity: 0.15;
}

.lunchtodyaOwl .owl-theme .owl-dots .owl-dot.active span {
    opacity: 9;
    width: 16px;
    height: 16px;
}

.lunchtodyaOwl .owl-theme .owl-dots .owl-dot+.owl-dot {
    margin-left: 10px !important;
}

.lunchtodyaOwl .owl-theme .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lunchtodyaOwl .owl-theme .owl-nav {
    position: absolute;
    top: 40%;

}

.lunchtodyaOwl .owl-theme .owl-nav button.owl-prev {
    left: 20px;
}

.lunchtodyaOwl .owl-theme .owl-nav button.owl-next {
    right: 20px;
}


.lunchtodyaOwl .owl-nav button.owl-prev:hover img:nth-child(1),
.lunchtodyaOwl .owl-nav button.owl-next:hover img:nth-child(1) {
    display: inline-block !important;
}

.lunchtodyaOwl .owl-nav button.owl-prev:hover img:nth-child(2),
.lunchtodyaOwl .owl-nav button.owl-next:hover img:nth-child(2) {
    display: none !important;
}


.lunchtodyaOwl .owl-theme .owl-nav.disabled,
.lunchtodyaOwl .owl-theme .owl-dots.disabled {
    display: none !important;
}

.lunchtodyaOwl .owl-theme .owl-nav button {
    width: 44px;
    height: 44px;
    background: white !important;
    border-radius: 50%;
    box-shadow: 0px 4px 24px 2px rgba(0, 0, 0, 0.09);
}

.lpfilter .form-group {
    max-width: 228px;
    flex: 0 0 228px;
    margin-top: 0 !important;
}

.reservsetmodal .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
}

.reservsetmodal .modal-dialog {
    margin-left: auto;
    height: 100%;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 400px;
}

.kycbackdrop.show {
    top: 145px;
    left: 64px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    opacity: 9 !important;
}

.KYCModal .modal-content {
    border: 0;
    border-radius: 20px;
    padding: 20px 0px 20px 0px;
    height: 600px;
    /* filter: drop-shadow(0px 0px 35px rgba(0, 0, 0, 0.25)); */
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.25);

}

.KYCModal .modal-dialog {
    max-width: 734px;

}

.kycfom .form-control {
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 8px;
    font-family: var(--inter);
}

.kycfom .form-group+.form-group {
    margin: 0;
}

.kycfom .form-group label {
    color: #696974;
    font-weight: 500;
    margin-bottom: 8px;
    font-family: var(--inter);
}

.kycfom .form-group label span:nth-child(2) {
    color: rgba(105, 105, 116, 0.7);
    font-weight: 400;
}

.reservsetmodal .verify-input-group input {
    width: 44px;
    height: 44px;
    background: #FAFAFB;
    border: 1px solid #F1F1F5;
    border-radius: 8px;
}

.reservsetmodal .verify-input-group {
    justify-content: flex-start;
    gap: 8px
}

.invalid .uploaddoc {
    border-color: var(--red)
}

.reservedisablebtn:disabled:hover {
    background: rgb(0, 98, 255, 0.5);
}

.reservedisablebtn:disabled {
    border: 0;
    border-radius: 6px;
    background: rgb(0, 98, 255, 0.5);
    color: white;
    cursor: no-drop !important;
}

.KYCModal .verify-input-group {
    justify-content: flex-start;
    gap: 8px;
}

.KYCModal .verify-input-group input {
    background: #FAFAFB;
    /* Black/Dark 200 */
    border: 1px solid #F1F1F5;
    border-radius: 8px;
    width: 44px;
    height: 44px;
}

.KYCModal .verify-input-group input:focus {
    border-color: var(--primary-color);
}

.KYCModal .verify-input-group.invalid input {
    border-color: var(--red);
}

.LPfiltersticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: white;

}

.lpnofound {
    height: calc(100vh - 200px);
}

.lightmodal.modal {
    padding: 0 !important
}

/* Light modal css */
.lightmodal .modal-dialog {
    max-width: 100%;
    margin: 0 auto;
    background: transparent;
    height: 100%;
}

.lightmodal .modal-dialog .modal-content {
    background: rgb(0 0 0 / 47%);
    border: 0;
    height: 100%;
}

/* End light modal */

@media only screen and (max-width: 850px) {
    .an-title.lpFilterarea .download-btn-group {
        order: 2;
    }


    .lpfilter .form-group {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .KYCModal .modal-content {

        padding: 20px 0px 20px 0px;
    }

    .develoerdetail .pr-32 {
        padding-right: 0;

    }

}

@media only screen and (max-width: 768px) {
    .open-sidebar .projectdetailPage {
        width: calc(100% - 64px);
        flex: 0 0 calc(100% - 64px);

    }

    .kycbackdrop.show {
        left: 0
    }

    .KYCModal .modal-dialog {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .an-title.lpFilterarea {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start !important;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .an-title.lpFilterarea .download-btn-group::-webkit-scrollbar {
        opacity: 0;
        display: none;
    }

    .an-title.lpFilterarea .download-btn-group {
        width: 100%;
        overflow: auto;
    }

    .FilterModal {
        display: flex !important;
        align-items: flex-end;
    }

    .filtermodalback {
        display: block;
    }

    .FilterModal .modal-dialog {
        width: 100%;
        margin: 0;
        max-width: 100%;
    }

    .FilterModal .modal-dialog .modal-content {
        border: 0;
    }
}

@media only screen and (max-width: 600px) {

    /* .an-title .download-btn-group .assigned-drop .dropdown-menu {
        position: fixed !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        top: auto !important;
        transform: unset !important;
        width: 100% !important;
    } */

    .registerdEventes .owl-theme .owl-nav.disabled {
        display: none !important;
    }

    .lunchtodyaOwl .owl-stage-outer {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .an-title.lpFilterarea {
        gap: 12px;
    }

    .lpFilterarea .download-btn-group .assigned-drop button.outline {
        padding: 5px 10px !important
    }




    .KYCModal .modal-dialog {
        align-items: flex-start !important;
        margin-top: 140px;
    }

    .kycbackdrop.show {
        top: 105px;
    }

    .KYCModal .modal-content {
        height: auto;
    }

    .reservsetmodal .modal-dialog {
        max-width: 100%;
    }

    .LPfiltersticky {
        position: relative;
    }

    .lpnofound {
        height: unset;
        margin-top: 100px;
    }

    .develoerdetail .pr-32 {
        padding-right: 0;
    }

    .develoerdetail .mt-40 {
        margin-top: 12px;
    }
}