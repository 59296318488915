/* 

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
 } */
.short-btn .assigned-drop .dropdown-menu {
    width: 216px;

}

.search-input .close-icon:hover svg path {
    fill: var(--primary-color);
}

.short-btn .assigned-drop.show button svg,
.assigned-drop.show button svg {
    transform: rotate(175deg);
}

.short-btn .assigned-drop svg,
.assigned-drop button svg {
    transition: 0.5s all;
}

.merge-del-btn button.cancel-btn:hover {
    /* background: linear-gradient(0deg, rgba(23, 23, 38, 0.30) 0%, rgba(23, 23, 38, 0.30) 100%), #FF4852;
    background: linear-gradient(0deg, color(display-p3 0.0913 0.0902 0.1462 / 0.30) 0%, color(display-p3 0.0913 0.0902 0.1462 / 0.30) 100%), color(display-p3 0.9887 0.3528 0.3528); */
    background-color: var(--red);
    color: white;
}

.merge-del-btn button.cancel-btn.onlydelete:hover {
    background: linear-gradient(0deg, rgba(23, 23, 38, 0.30) 0%, rgba(23, 23, 38, 0.30) 100%), #FF4852;
    background: linear-gradient(0deg, color(display-p3 0.0913 0.0902 0.1462 / 0.30) 0%, color(display-p3 0.0913 0.0902 0.1462 / 0.30) 100%), color(display-p3 0.9887 0.3528 0.3528);
}

.merge-del-btn button.cancel-btn:hover svg path {
    fill: white;
}

.merge-del-btn .merge-btn:hover {
    opacity: 0.9;
}

.select-all-label {
    /* display: none !important; */
}

.select-all-check:hover+.select-all-label {
    display: block !important;
}