.modal-content {
    border: 0 !important;
}

.project-maping .input-row .form-control+.input-feedback {
    margin-left: 20px;
}

.housing-import-modal {
    max-width: 800px !important;
}

.housing-import-modal .header-modal {
    padding-top: 42px;
    padding-bottom: 32px;
}

.housing-import-modal .secure-for-modal {
    bottom: 38px;
}

.body-modal {
    padding: 0 24px;
}

.footer-modal {
    padding: 25px 0 30px 0;
}

.profile-import .form-group label {
    font-weight: 500 !important;
    color: var(--black);
    font-size: 16px;
}

.body-modal .card {
    padding: 0;
    background: #FAFAFB;
    border: 1px solid #E2E2EA;
    border-radius: 8px 8px 0px 0px;
    box-shadow: unset !important;
}

.body-modal .card .card-header {
    padding: 10px 0px 12px 16px;
    border-bottom: 1px solid #E2E2EA;
}

.body-modal .card .card-header svg {
    margin-right: 8px;
}

.project-maping {
    margin-top: 25px;
    margin-top: 25px;

}

.housing-import-modal .card-body {
    max-height: 380px;
    overflow: auto;
}

.housing-import-modal .card-body button.add-btn {
    display: block;
    width: 100%;
    text-align: center;
    border: 1px solid #E2E2EA;
    padding: 9px 0;
    border-radius: 4px;
    letter-spacing: 0.5px;
    color: #92929D;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
}

.housing-import-modal .card-body button.add-btn:hover {
    color: var(--primayr-color);
    border-color: var(--primay-color);
}

.project-maping .row.heading {
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1;
}

.project-maping .input-row .form-control {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 8px !important;
}

.project-maping .input-row {
    padding-top: 16px;
    margin: 0;
    padding-bottom: 16px;
}

.project-maping .input-row+.input-row {
    border-top: 0.5px solid #D5D5DC;

}

.project-maping .input-row .col-md-5 {
    padding-left: 0;
    padding-right: 0;
}

.project-maping .heading {
    position: sticky;
    top: -20px;
}

.send-mail.body-modal .card .card-header {
    padding-top: 20px;
    padding-bottom: 20px;
}

.col-left {
    width: 80px;
    padding-left: 15px;
    letter-spacing: 0.1px;
    color: #92929D;
    font-weight: 500;
    font-family: var(--popins);
}

.col-center {
    width: 70%;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */
    letter-spacing: 0.1px;
    color: #171725;
}

.col-right {
    width: 129px;
    padding-right: 15px;
}

.col-right button {
    margin: auto;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    letter-spacing: 0.1px;
    color: #92929D;
}

.copy-content button {
    display: table;
    background: no-repeat;
    color: #92929D;
    font-size: 16px;
    font-weight: 400;
}

.copy-content button:hover {
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.copy-content button svg {
    margin-right: 8px;
}

.copy-content button:hover svg path {
    fill: var(--primary-color);
}

.copy-content .box .row .col-8 {
    font-size: 16px;
    color: #92929D;
    letter-spacing: 0.1px;
    font-weight: 500;
}

.card-body .box {
    padding: 24px 0;
    border-bottom: 1px solid #D5D5DC;
}

.card-body .box .row:nth-child(1) {
    margin-bottom: 4px;
}

.card-body .box .col-12 p {
    font-size: 16px;
    letter-spacing: 0.1px;
    color: #171725;
    padding-right: 15px;
}

.card-body .copy-content {
    max-height: 365px;
    overflow-y: scroll;
    overflow-x: hidden;
}


.process-card {
    background-color: white !important;
    height: 422px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.process-card h1 {
    margin-top: 50px;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 100% */
    text-align: center;
    letter-spacing: 0.1px;

    /* Black/Black */
    color: #171725;
    margin-bottom: 14px;
}

.process-card p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    text-align: center;
    letter-spacing: 0.5px;


    color: #696974;
    padding: 0 60px;
    margin-bottom: 44px;
}

.custom-mailbox p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */
    letter-spacing: 0.1px;
    color: #171725;
}

.custom-mailbox p+p {
    margin-top: 10px;
}

.housing-import-modal a.cursor-pointer.close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.housing-import-modal .card {
    background: white !important;
}

.project-maping .input-row .form-control {
    margin-left: 20px;
    width: 265px;
}

.project-maping .input-row .css-b62m3t-container {
    margin-right: 20px;
}

.housing-import-modal .card-body h2 .infocard {
    min-width: 300px !important;
    padding: 7px 15px;
    line-height: 22px;
}


.project-maping .row.heading .col-md-5.pl-0 .infocard {
    left: -100px;
    top: 30px;
}

.project-maping .row.heading .col-md-5.pl-0:nth-child(1) .infocard {
    min-width: 300px !important;
}

button.infobox {
    border: 0;
    background: transparent;
    position: relative;
    overflow: hidden;
}

button.infobox:hover {
    overflow: visible;
}

.infobox .infocard {
    position: absolute;
    left: 33px;
    top: -2px;
    padding: 8px 17px;
    background: #162c50;
    width: 160px;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0.1px;
    box-shadow: 0px 25px 80px rgb(226 226 234 / 60%);
    border-radius: 8px;
    transition: 0.5s all;
    opacity: 0;
    color: var(--white);
    z-index: 999;
    font-family: var(--roboto);
    box-shadow: var(--box-shadow);
}

.infobox .housing_infocard {
    min-width: 250px !important;
}

.infobox:hover .infocard {
    opacity: 9;
}

.project-maping .infobox .infocard {
    right: auto;
    left: -100px;
    top: 30px;
}

.housing-import-modal .modal-content .modal-content {
    min-height: 635px;
}

@media only screen and (max-width: 600px) {
    nav.inner-page-breadcrumb ul::-webkit-scrollbar {
        opacity: 0;
        display: none;
    }

    nav.inner-page-breadcrumb ul {
        max-width: 500px;
        overflow: auto;
        flex-wrap: nowrap;
        align-items: center !important;
    }

    nav.inner-page-breadcrumb ul li {
        white-space: nowrap;
    }

    .fb-lead-integration .table tbody tr td:nth-child(2) {
        max-width: 250px !important;
    }

    .housing-import-modal .secure-for-modal {
        display: none;
    }

    .housing-import-modal .header-modal h2 img {
        display: table;
        margin: auto;
        margin-bottom: 16px;
    }

    .housing-import-modal .header-modal h2 {
        font-size: 18px;
        text-align: center;
        font-weight: 500;
    }

    .modal-dialog.modal-radius.housing-import-modal.modal-dialog-centered.modal-fullscreen {
        margin-left: auto;
        margin-right: auto;
        margin: auto;
        width: 95%;
    }

    .modal-open .modal.show {
        padding-left: 0 !important;
        overflow: hidden !important;
    }

    .housing-import-modal .header-modal {
        padding-bottom: 16px;
    }

    .body-modal .card .card-header {
        align-items: flex-start !important;
        padding: 16px !important;
    }


    .project-maping .row.heading {
        display: none;
    }

    .project-maping .input-row .col-md-2 {
        height: 12px;
    }

    .project-maping .input-row+.input-row {

        padding-top: 0;
    }

    .housing-import-modal .css-1s2u09g-control {
        height: 38px;
    }

    .housing-import-modal .card-body {
        max-height: 280px;
        overflow-x: hidden;
        padding: 0;
    }

    .send-mail.body-modal .card .card-header h1 {
        font-size: 14px;
    }

    .send-mail .card-body .box .row {
        flex-wrap: wrap;
    }

    .send-mail .card-body .box .row .col-left,
    .send-mail .card-body .box .row .col-center,
    .send-mail .card-body .box .row .col-right {
        width: 100%;
        padding-left: 18px;
        padding-right: 18px;
    }

    .send-mail .card-body .box .row .col-left {
        margin-bottom: 12px;
    }

    .send-mail .card-body .box .row .col-center {
        margin-bottom: 12px;
    }

    .housing-import-modal .card {
        background: white !important;
    }


    .project-maping .input-row input {
        width: 265px;
        margin-left: 20px;
    }

    .project-maping .css-b62m3t-container {
        margin-right: 20px;
    }

    .send-mail .card-body .box .row .col-right button {
        margin-left: auto;
        margin-right: 0;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .housing-integratoin .all-leads-head .fz28 {
        font-size: 22px;
        margin-bottom: 14px;
    }

    .housing-integratoin .form-inline,
    .acres-heading .form-inline,
    .facebook-head .form-inline,
    .landingpage-head .form-inline {
        margin-right: auto;
        width: 47%;
    }

    .housing-integratoin .form-inline input,
    .acres-heading .form-inline input,
    .facebook-head .form-inline input,
    .landingpage-head .form-inline input {
        width: 100%;
    }

    .all-leads-head .fz28 {
        font-size: 24px;
    }


    .process-card p {
        padding: 0;
    }

    .process-card p {
        padding: 0;
    }

    .project-maping .input-row {
        padding: 20px;
        padding-top: 16px !important;
    }

    .profile-import {
        padding: 20px;
        border-bottom: 0.5px solid #D5D5DC;
    }

    .profile-import+h2 {
        padding-left: 20px;
        margin-bottom: 0;
    }

    .housing-import-modal .card-body button.add-btn {
        margin-bottom: 20px;
        width: 90%;
        display: table;
        margin-left: auto;
        margin-right: auto;
    }

    .card-body .box {
        padding: 16px 20px;
    }

    .process-card h1 {
        margin-top: 24px;
    }

    .project-maping .input-row .form-control {
        margin-left: 0;
        width: 100%;
    }




    .all-leads-head .search-input input {
        width: 100% !important;
    }

    .project-maping .css-b62m3t-container {
        margin-right: 0 !important;
    }

    .send-mail.body-modal .card .card-header div p {
        display: none;
    }
}