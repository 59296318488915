.projectlistdeftable table tbody tr td:nth-child(2),
.projectlistdeftable table thead tr th:nth-child(2) {
    position: sticky;
    left: 65px;

}

.ytbmodal {
    max-width: 623px !important;
}

.ytbmodal>div {
    height: unset !important;
    border: 0 !important;
    border-radius: 0 !important;
}

.ytbody {
    height: 200px;
}

.ytfooter button {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding: 9px 45px;
}

.uplaodytbtext {
    position: absolute;
    left: auto;
    right: auto;
    bottom: 16px;
    color: rgba(138, 138, 151, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: var(--inter);

}

.uplaodytbtext button {
    border: 0;
    background-color: transparent;
    color: var(--primary-color);
    /* text-decoration: underline; */
    cursor: pointer;
}

.upytbbtn {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;
    font-family: var(--inter);
    font-size: 14px;
    color: var(--black-dark-700);
    border-radius: 4px;
    border: 1px solid #DEDEDE;
    background: #FAFAFB;
    padding: 4px 10px;
}

.upytbbtn:hover {
    background-color: var(--black-dark-200);
}

.configrationtable table tbody tr td:last-child {
    position: sticky;
    right: 0;
}

.configrationtable table thead {
    z-index: 0 !important;
}

.configeditbtn,
.configdelbtn {
    border-radius: 4px;
    border: 1px solid #DEDEDE;
    background: #FAFAFB;
    color: #696974;
    font-family: var(--inter);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;
    display: inline-flex;
    gap: 8px !important;
    align-items: center;
    padding: 5px 12px;
}

.configeditbtn:hover,
.configdelbtn:hover {
    background-color: '#fff'
}

.dragsecure {
    position: absolute;
    left: 28px;
    bottom: 28px;
    border-radius: 14px;
    border: 1.434px solid #DEDEDE;
    background: #FFF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background-color: white;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--inter);
    color: #8A8A97;
    padding: 4px 14px;
    line-height: 18px;
}

.backbtn {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    margin-right: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 4px
}

.backbtn:hover {
    background-color: var(--black-dark-200);
}

.backbtn:hover svg path {
    stroke: var(--black);
}

.creatproject .create_project_dropdownbtn {
    color: var(--primary-color);
}

.creatproject .create_project_dropdownbtn svg path {
    fill: var(--primary-color)
}

.proparty_type {
    font-family: var(--inter);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 10px;
    border-radius: 6px;
    border: 1px solid #DEDEDE;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px #E1EBFB;
    display: inline-flex;
    margin-bottom: 4px;
    color: #515151;
    text-transform: capitalize;

}

.gmap {
    margin-bottom: 16px;
}

.positondate {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 10px;
    color: white;
    font-family: var(--inter);
    border-radius: 0px 0px 4px 4px;
    background: linear-gradient(180deg, rgba(23, 23, 37, 0.00) 0%, #171725 100%);
    padding: 6px 0;
    line-height: 12px;
}

.cardthumbnail {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #C4D2E9 0%, #C4D2E9 100%) lightgray 50%;
}

.setthumbnail {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #252C32;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    background: #FFF;
    padding: 4px 8px;
    margin-bottom: 8px;

}

.animitestags {
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    background: #FAFAFB;
    font-size: 16px;
    font-family: var(--inter);
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.096px;
    padding: 7px 16px;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    cursor: auto;
}

.animitestags svg {
    width: 10px;
    flex: 0 0 10px;
    cursor: pointer;
}


/* .animitestags:hover {
    background-color: var(--black-dark-200);
} */

.confiboxview {
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid #DEDEDE;
    max-width: 210px;
    flex: 0 0 210px
}

.closetagbtn {
    display: inline-flex;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 0;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    flex: 0 0 28px;
    padding: 0
}

.closetagbtn:hover {
    background-color: #EDEDED;
}

.confiboxview h6 {
    color: var(--black);
    font-size: 16px;
    font-family: var(--inter);
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.1px;
    margin-bottom: 0;

}

.confiboxview p {

    margin-bottom: 0;
    color: #7A7A7A;
    font-size: 12px;
    font-family: var(--inter);
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.gmap>div {
    position: relative !important;
    width: 100% !important;
    height: 250px !important;
}

.addlandmarkbtn {
    font-size: 14px;
    font-family: var(--inter);
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.084px;
    color: var(--primary-color);
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    background: #FAFAFB;
    padding: 4px 16px;
    line-height: 18px;
    margin-top: 20px;
}

.addlandmarkbtn:hover {
    background-color: var(--black-dark-200);
}

.btntags {
    font-size: 14px;
    font-family: var(--inter);
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.084px;
    color: var(--primary-color);
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    background: #FAFAFB;
    padding: 4px 16px;
    line-height: 18px;
}

.btntags:hover {
    background-color: var(--black-dark-200);
}

.privateinfo span {
    font-size: 14px;
    color: var(--black-dark-700);
    line-height: 16px;
}

.privateinfo {
    padding: 6px 4px;
}

.animiteslist {

    padding: 14px 14px;
}

.animiteslist span {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--inter);
    line-height: 14px;
    letter-spacing: 0.1px;
    color: var(--black)
}

.circlecheckbox {
    position: relative;
}

input[type=checkbox].circlecheckbox {
    position: relative;
    border: 1px solid rgba(182, 194, 226, 1);
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 0.6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    border-radius: 50%;
}

input[type=checkbox].circlecheckbox:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 5px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 1px 1px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
}

input[type=checkbox].circlecheckbox:checked {
    background: var(--primary-color);
    border-color: var(--primary-color);
}

.create_project_dropdownbtn {
    box-shadow: 0px 0px 10px 0px rgba(225, 235, 251, 1);
    border: 1px solid rgba(222, 222, 222, 1);
    background-color: white;
    border-radius: 6px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    font-family: var(--inter);
    line-height: 20px;
}

.create_project_dropdownbtn:hover {
    background-color: var(--black-dark-200);
}

.addrerabtn {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 1;
    position: absolute;
    right: 8px;
    top: 36px;
    z-index: 1;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.addrerabtn.disable {
    opacity: 0.6;
    cursor: auto;
}

.sucessbtn {
    background: #DFF7EB;
    letter-spacing: 0.107474px;
    font-weight: 500;
    border: 0;
    font-size: 14px;
    line-height: 21px;
    font-family: var(--inter);
    color: #05A36F;
    display: inline-block;
    border-radius: 4px;
    padding: 6px 15px;
    cursor: auto !important;
}

.failbtn {
    background: #F9E9E9;
    letter-spacing: 0.107474px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    font-family: var(--inter);
    color: var(--red);
    display: inline-block;
    border-radius: 4px;
    padding: 6px 15px;
    border: 0;
    cursor: auto !important;
}

.mediauploadtable {
    padding: 38px 60px;
    background-color: #FAFAFB;
    border: 1px dashed #0062FF;

}

.drag_dropmedia {
    border: 1px dashed #e2e2ea;
    width: 100%;
    background-color: #FAFAFB;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 380px;
}

.mediaupalodtab button {
    color: #696974;
    letter-spacing: 0.1px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-family: var(--inter);
    border: 0;
    border-bottom: 1px solid transparent;
    background-color: white;
    padding-bottom: 16px;

}

.absrupay {
    position: absolute;
    left: 14px;
    top: 46px;
}

.editnamebtn {
    border-radius: 6px;
    border: 1px solid #F1F1F5;
    background: #FAFAFB;
    padding: 4px;
    margin-left: 8px;
}

.editnamebtn:hover svg path {
    stroke: var(--primary-color);
}

.mediauploadbtn {
    display: inline-flex;
    align-items: center;
    padding: 6px 20px;
    gap: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}

.previewupload {
    height: 228px !important;
}

.mediaupalodtab {
    display: flex;
    align-items: center;
    gap: 32px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 99999;
}

.mediaupalodtab button.active {
    border-bottom: 1px solid var(--primary-color);
    color: var(--primary-color)
}

.addpaymentplan {
    background: #F9F9FB;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 115px;
}

.bankDetailtable {
    height: unset !important;
    max-height: 333px;
}

.bankDetailtable table tbody tr td:nth-child(2),
.bankDetailtable table thead tr th:nth-child(2) {
    max-width: unset !important;
}

.addmorebtn {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: var(--inter);
    background-color: white;
    padding: 7px 16px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.addmorebtn:hover {
    background-color: var(--black-dark-100);
}

.modalForm {
    gap: 16px;
    padding: 16px 20px 32px 20px;
}

.righmodalfooter {
    padding-top: 22px;
    padding-bottom: 22px;
    gap: 10px
}

.createprojecttable table tbody tr td:nth-child(1) {
    padding-left: 16px !important;
    padding-right: 16px !important;
    text-align: left;
}

.createprojecttable table thead tr th:nth-child(1) {
    width: unset;
    min-width: unset;
    max-width: unset;
    text-align: left;
}

.createprojecttabs {
    gap: 32px
}

.projecttitle button {
    display: inline-flex;
    align-items: center;
    gap: 4px;
}

.createprojecttabs a {
    color: #252C32;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.006em;
    position: relative;
    padding-bottom: 8px;
    font-family: var(--inter);

}

.createprojecttabs a::before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: var(--primary-color);
    opacity: 0;
}

.createprojecttabs a.active::before {
    opacity: 9;
}

.createprojecttabs a.active {
    font-weight: 700;
    color: var(--primary-color) !important
}

.createprojecttabs a:hover {
    color: #252C32;
}

.inputwithdropbtn {
    right: 10px;
    top: 42px;

    font-family: var(--inter);
}

.inputwithdropbtn button {
    font-size: 12px;
    line-height: 20px;
    padding: 0;
    color: var(--black);
    font-weight: 500;
}

.inputwithdropbtn button>svg {
    margin-left: 4px;
}

.savebtn:hover {
    opacity: 0.9;
}

.savebtn {
    color: white;
    background-color: var(--primary-color);
    padding: 8px 80px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    box-shadow: 4px 4px 8px #F1F1F5;
    border-radius: 6px;
    border: 0
}

.cancelbtn:hover {
    background-color: var(--black-dark-100);
}

.createprojectfooter {
    padding: 20px 24px;
}

.cancelbtn {
    color: #333333;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    display: inline-flex;
    gap: 4px;
    align-items: center;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    padding: 7px 12px;
    background-color: white;
}

.inputwithdropdownmenu {
    width: auto;
}


.inputwithdropdownmenu a+a {
    margin-top: 4px;
}

.inputwithdropdownmenu a {
    padding: 4px 8px;
    font-size: 14px;
    font-family: var(--inter);
    font-weight: 500;
    border-radius: 4px;
    color: var(--black-dark-800)
}

.creatproject {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.basic_form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 32px;
    row-gap: 16px;
    width: 915px;

}

.mediafileupload {
    width: 100%;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 24px;
    margin-bottom: 64px;


}

.col_span_2 {
    grid-column: span 2 / span 2;
}

.overflowcontent {
    /* height: calc(100vh - 220px); */
}

.groupofinput {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.editdelgrp button {
    width: 35px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black-dark-300);
    padding: 0;
    flex: 0 0 35px;
    background-color: #ffff;
    border-radius: 4px;
}

.rarainput {
    background: rgba(217, 217, 217, 0.4);
    border-bottom: 1px solid #696974 !important;
    border: none;
    padding: 4px 4px;
    width: 100%;
    font-size: 12px;
    color: #696974 !important;
    font-weight: 500;
}

.rarainput:focus {
    outline: 0;
    box-shadow: unset;

}

.raragrp {
    color: #696974;
    letter-spacing: 0.1px;
    font-weight: 500;
    font-family: var(--inter);
    font-size: 12px;
    width: 100%;
}

.rerablock {
    border: 1px solid var(--black-dark-300);
    border-radius: 6px;
    padding: 8px 12px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 70px;
    overflow: auto;
}

.editbtn:hover svg path {
    stroke: var(--primary-color);
}

.calicon {
    position: absolute;
    right: 10px;
    top: 42px;
    width: 18px;
    height: 18px;
}

.editdelgrp {
    display: flex;
    align-items: center;
    gap: 4px;
}

.upload {
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    height: 154px;
    position: relative;
    overflow: hidden;
}

.upload img {
    object-fit: cover;
}

.uplaodbtngrp {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload:hover .hoverbox {
    opacity: 9;
}

.hoverbox {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: 0.3s all;


}

.delsave .editbtn:hover {

    background-color: rgba(0, 98, 255, 0.15);
    border-color: rgba(0, 98, 255, 0.15);
}

.delsave .delbtn:hover {
    background-color: rgba(252, 90, 90, 0.15);
    border-color: rgba(252, 90, 90, 0.15);
}


.delbtn {
    border-radius: 4px;
    height: 34px;
    width: 34px;
    flex: 0 0 34px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.downloadbtn:hover svg path {
    stroke: var(--primary-color);
}

.downloadbtn {
    border-radius: 4px;
    height: 34px;
    width: 34px;
    flex: 0 0 34px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.delbtn:hover svg path {
    stroke: var(--red);
}

.view_grid_listBtngrp {
    height: 32px;
    white-space: nowrap;

}

.cardviewProjectbtn {
    /* position: absolute;
    left: 14px;
    right: 14px;
    bottom: 14px; */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.CardLaunchIcon {
    left: 8px;
    bottom: 8px;
    position: absolute;
}

.view_grid_listBtngrp button:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.view_grid_listBtngrp button:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.view_grid_listBtngrp button.active {
    background-color: #E5E9EB;
}

.view_grid_listBtngrp button.active svg path {
    fill: #696974;
}

.view_grid_listBtngrp button {
    /* width: 32px; */
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    flex: 0 0 32px;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    background-color: white;
}

.filter_btn {
    border: 0;
    background-color: white;
    border-radius: 4px;
    padding: 4.5px 16px;
    height: 32px;
    white-space: nowrap;
}

.filter_btn_active {
    background-color: var(--Blue-100);
    color: var(--primary-color)
}

.filter_btn_active svg path {
    fill: var(--primary-color)
}

.filter_btn:hover {
    background: #F1F1F5;
    color: var(--black) !important;
}

.filter_btn:hover svg path {
    fill: var(--black)
}

.newupdate {
    background: #F2F8FF;
    border: 1px solid #0062FF;
    border-radius: 3px;
    color: #0062FF;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    padding: 4px 8px;
    margin-left: 8px;
    cursor: auto;
}

.addnewprojectbtn {
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-shadow: 6px 14px 6px rgba(0, 0, 0, 0.01), 3px 8px 5px rgba(0, 0, 0, 0.02), 1px 4px 4px rgba(0, 0, 0, 0.03), 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #0062FF;
    padding: 4px 8px;
    font-family: var(--inter);
    margin-top: 18px;
}

.addnewprojectbtn:hover {
    background-color: var(--black-dark-200);
}

.girdProjectCard:hover {
    background-color: #fafafb;

}

.girdProjectCard {
    background: #FFFFFF;
    border-radius: 4px;
    /* filter: drop-shadow(2px 4px 15px rgba(23, 23, 37, 0.15)); */
    /* max-width: 316px; */
    /* flex: 0 0 316px; */
    padding: 8px 8px;
    font-family: var(--inter);
    position: relative;
}

/* .cardLauchheding {
    height: 48px;
} */

.girdProjectCard figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;

}

.projectcardMenu a {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: var(--black-dark-800) !important;
}

.projectcardMenu a+a {
    margin-top: 0 !important;
}

.girdProjectCard figure {
    border-radius: 4px;
    height: 168px;
    overflow: hidden;
    margin-bottom: 8px;
}

.project_grid_layout {
    /* 
    gap: 26px;
    
    padding-bottom: 40px;
    margin-right: 12px;
    /* transition: 0.5s all;
    opacity: 0; */
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 40px;
    column-gap: 20px;
    flex-wrap: wrap;
    align-items: flex-start;
}

.project_grid_layout.active {
    opacity: 9;
}

.projectcardMenu>button {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 1;
    width: 32px;
    height: 32px;
    background: white !important;
    display: inline-flex !important;
    padding: 0;
    align-items: center;
    justify-content: center;
    border: 1px solid #DEDEDE !important;
    border-radius: 6px;
}

.projectcardMenu>button svg path {
    fill: #44444F;
}

.cardNewupdate {
    position: absolute;
    left: 8px;
    top: 8px;
    font-weight: 600;
    cursor: default;
    margin-left: 0;
}

.loadMore {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    font-family: var(--inter);
    color: #5A5A67;
    display: table;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    background-color: white;
    padding: 10px 60px;
    line-height: 18px;
    color: var(--primary-color);
}

.loadMore:hover {
    background-color: rgb(0 98 255 / 10%);
}

.loadMorediv {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 40px;
}



.mainMenu {
    width: 148px;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    padding: calc(var(--value)*6);
    border: 0;
    position: absolute;
    z-index: 9999;
    background: white;
    right: 0;
    top: 100%;
    margin-top: 5px;
}

.mainMenu>ul li {
    text-align: right;
    color: var(--black-dark-800);
    font-size: 14px;
    letter-spacing: .01px;
    line-height: 22px;
    border-radius: 4px;
    padding: 4px 8px;
    font-family: var(--inter);
    font-weight: 500;
    position: relative;
}

.mainMenu>ul {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: 0;
}

.mainMenu>ul li button {
    border: 0;
    background-color: transparent;
    color: var(--black-dark-800);
    font-size: 14px;
    font-weight: 500;
    font-family: var(--inter);
    width: 100%;
    text-align: right;
    padding: 0;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

}

.mainMenu>ul li button span {
    order: 2;
}

.mainMenu>ul li button svg {
    order: 1
}

.mainMenu>ul li button svg {
    transition: 0.3s all;
}

.mainMenu>ul li:hover {
    background: var(--black-dark-200);
    cursor: pointer;
}

.childmenu {
    position: absolute;
    right: 100%;
    margin-right: 15px;
    background: white;
    width: 148px;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    padding: calc(var(--value)*6);
    border: 0;
    z-index: 99;
    background: white;
    margin-top: 5px;
    top: 0;
}

.childmenu>li {
    text-align: left !important;
}

.pb-180 {
    padding-bottom: 180px;
}

@media screen and (min-width: 1710px) {
    .cardcenterview {
        width: 1385px !important;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (max-width: 1345px) {
    .cardcenterview {
        margin-left: auto;
        margin-right: auto;

    }

}


@media only screen and (max-width: 1300px) {




    .createprojecttabs::-webkit-scrollbar {
        display: none;
        opacity: 0;
    }

    .basic_form {
        width: 100%;
        padding-right: 24px;

    }

}






@media only screen and (max-width: 1400px) {
    .mediafileupload {


        width: 100%;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media only screen and (max-width: 1200px) {
    .project_grid_layout {
        row-gap: 24px;
    }

    .mediafileupload {


        width: 100%;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media only screen and (max-width: 1000px) {
    .mediafileupload {


        width: 100%;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .project_grid_layout {
        display: inline-grid;
    }
}

@media only screen and (max-width: 990px) {
    .dragsecure {
        font-size: 12px;
        left: 10px;
        top: 12px;
        bottom: auto
    }

    .hoverbox {
        opacity: 9 !important;
    }

    .proejctheading {
        gap: 12px;
    }

    .project_grid_layout {
        grid-template-columns: repeat(3, minmax(0, 1fr));

    }
}


@media only screen and (max-width: 800px) {
    .project_grid_layout {
        grid-template-columns: repeat(3, minmax(0, 1fr));

    }

    .hoverbox {
        opacity: 9 !important;
    }

    .mediafileupload {



        width: 100%;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .createprojecttabs a {
        font-size: 14px;
    }

    .createprojecttabs {
        gap: 22px;
    }
}



@media only screen and (max-width: 600px) {
    .project_grid_layout {

        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .msingleline {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        white-space: pre-wrap;
    }

    .overflowcontentrow {
        max-width: 100%;
        overflow: auto;
    }

    .overflowcontentrow::-webkit-scrollbar {
        display: none !important;
    }

    .cardcenterview {
        max-width: 100%;
    }

    .mediafileupload {
        margin-bottom: 32px;
    }

    .configrationtable table tbody tr td:last-child {
        position: relative;
        right: unset;
    }

    .configeditbtn,
    .configdelbtn {
        padding: 4px
    }


    .dragsecure svg {
        width: 24px;
        height: 24px;
    }

    .editdelgrp button {
        width: 24px;
        height: 24px;
        flex: 0 0 24px;

    }

    .project_grid_layout {
        justify-content: center;
    }

    .projectlistdeftable table tbody tr td:nth-child(2) {
        max-width: unset !important;
    }



    .basic_form {
        display: flex;
        flex-direction: column;
        padding-right: 20px;
    }

    .createprojectfooter {
        gap: 18px
    }

    .createprojectfooter button {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        white-space: nowrap;
        text-align: center;
        justify-content: center;
    }

    .mediaupalodtab {
        width: 100% !important;
        white-space: nowrap;
        gap: 24px
    }

    .mediaupalodtab button {
        font-size: 14px;
        padding-left: 0;
        padding-right: 0;
    }

    .mediaupalodtabsection {
        padding-right: 12px;
        padding-left: 12px;
    }

    .drag_dropmedia {
        height: auto;
        padding: 24px 0;
    }


    .drag_dropmedia h1 {
        font-size: 16px;
        padding: 0 20px;
    }

    .drag_dropmedia p {
        font-size: 12px;
        padding: 0 20px
    }

    .mediauploadbtn svg {
        height: 24px !important;
        margin-bottom: 0 !important;
    }

    .mediauploadbtn {
        margin-top: 16px;
    }

    .mediauploadtable {
        padding: 16px
    }

    .mediauplaodtableheading {
        width: 100%;
    }

    .mediauplaodtableheading button {
        width: 100%;
    }

    .projecttitle {
        padding-top: 16px;
    }

    .filter_btn {
        padding-left: 4px;
        padding-right: 4px;
    }

    .proejctheading {
        gap: 12px
    }

    .creatdropdownmenu>div {
        display: inline-block;
    }
}

@media only screen and (max-width: 420px) {
    .project_grid_layout {

        grid-template-columns: repeat(1, minmax(0, 1fr));

    }
}