@tailwind base;
@tailwind components;
@tailwind utilities;


.Toastify__close-button--light {
    color: #92929D;
    opacity: 9;
    margin-top: 9px;
}

.Toastify__toast-body {
    font-size: 14px;
    font-weight: 500;
    color: #171725;
}



.Toastify__toast {
    border-radius: 0;
}

.Toastify__toast--success {
    border-left: 4px solid #3DD598;
}

.Toastify__toast--info {
    border-left: 4px solid #0062FF;
}

.Toastify__toast--warning {
    border-left: 4px solid #FFC542;
}

.Toastify__toast--error {
    border-left: 4px solid #FC5A5A;
}