.login-flow-form .css-1s2u09g-control {
    height: 44px;
    box-sizing: border-box;
    border-radius: 4px !important;
    color: #44444F !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}


button.login-btn {
    background: var(--primary-color);
    border-radius: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #FAFAFB;
    border: 0;
    text-align: center;
    width: 100%;
    padding: 12px 0;
    margin-bottom: 30px;
}

.createpawword-main-area figure {
    margin-bottom: 48px;
}

.valid-icon {
    position: absolute;
    right: 15px;
    top: 40px;
}

.loginuser-main-area {
    max-width: 380px;
    flex: 0 0 380px;
    margin-left: 76px;
    margin-top: 70px;
}

.loginuser-main-area figure {
    margin-bottom: 48px;
}

.loginuser-main-area .foget-password-para {
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    font-family: var(--roboto);
    color: #92929D;
    letter-spacing: 0.5px;
}

.loginuser-main-area .foget-password-para a {
    color: var(--primary-color);
}


.main-div.login-page {
    height: 100vh;
}

.loginuser-main-area .form-group .view-password {
    position: absolute;
    right: 15px;
    top: 40px;
    border: 0;
    background: transparent;
    padding: 0;
}

.createpawword-main-area {
    max-width: 380px;
    flex: 0 0 380px;
    margin-left: 76px;
    margin-top: 70px;
}

.veryfy-modal .modal-dialog {
    max-width: 496px;
}

.veryfy-modal .modal-dialog .modal-body {
    padding: 0;
}

.veryfy-modal .modal-dialog .modal-body .emilveryfiy-box {
    padding: 32px 48px 28px 48px;
}

.veryfy-modal .modal-dialog .modal-body .emilveryfiy-box p {
    text-align: center;
    letter-spacing: 0.5px;
    line-height: 26px;
    font-family: var(--roboto);
    margin-bottom: 24px;
}

.veryfy-modal .modal-dialog .modal-body .otp-form {
    padding: 32px 48px 28px 48px;
}

.veryfy-modal .modal-dialog .modal-content {
    order: 0;
    border-radius: 20px;
}


.otp-form .veryfy-btn {
    margin-left: 0;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 24px;
}

.otp-form .veryfy-btn+p {
    text-align: center;
}

.otp-form .veryfy-btn+p a {
    color: var(--primary-color);
}

.ReEmailVeryfiy .invalid .invalid-icon {
    top: 10px;
}

.veryfy-msg {
    display: flex;
    align-items: center;
    flex-direction: column;
    letter-spacing: 0.5px;
    color: #0B8235;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    font-family: var(--roboto);
    width: 100%;
    background: rgba(61, 213, 152, 0.17);
    border: 1px solid #3DD598;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 22px;
}

.veryfy-msg span {
    display: block;
    color: var(--black);
    font-weight: 500;
}

.veryfy-modal .modal-dialog .modal-body .emilveryfiy-box p a,
.veryfy-modal .modal-dialog .modal-body .emilveryfiy-box p a:hover {
    color: var(--primary-color);
}

.veryfy-modal .modal-dialog .modal-body .emilveryfiy-box p span {
    color: var(--black);
    font-weight: 500;
}

.verify-input-group {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.emilveryfiy-box .veryfy-btn {
    width: 100%;
    display: table;
    margin: auto;
    margin-top: 12px;
}

.verify-input-group input {
    width: 56px;
    height: 48px;
    border: 1px solid #E2E2EA;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--black-dark-800);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    text-align: center;
}

.verify-input-group input:focus {
    outline: 0;
    border-color: var(--primary-color);
}

.invalid .verify-input-group input {
    border: 1px solid var(--red) !important;
}

.main-div.loginFlow,
.main-div.create-password {
    height: 100vh;
}

.login-form-main-area {
    max-height: 100vh;
    overflow: scroll;
}


.login-flow-form .from-group {
    margin-bottom: 24px;
    position: relative;
}

.login-flow-form {
    width: 486px;
    display: inline-block;
    margin-top: 70px;
    margin-left: 50px;
    padding-bottom: 48px;
}

.login-flow-form .signup-btn {
    background: var(--primary-color);
    border-radius: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #FAFAFB;
    border: 0;
    text-align: center;
    width: 100%;
    padding: 12px 0;
    margin-bottom: 30px;
}

button.veryfy-btn {
    width: 192px;
    text-align: center;
    flex: 0 0 192px;
    background: var(--primary-color);
    color: white;
    border: 0;
    height: 44px;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 24px;
    font-family: var(--roboto);
    font-weight: 500;
    border-radius: 8px;
    margin-left: 8px;
}

button.verified-btn {
    width: 164px;
    white-space: nowrap;
    height: 44px;
    border-radius: 8px;
    margin-left: 8px;
    flex: 0 0 164px;
    border: 0;
    background: #82C43C;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: var(--roboto);
    letter-spacing: 0.01px;
}

.verify-email {
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    padding: 9px 8px;
    font-size: 14px;
    font-weight: 500;
    color: #44444F;
    margin-top: 24px;
    border-left: 6px solid var(--primary-color);
}

.login-flow-form p.termsandservces {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: #92929D;
    line-height: 22px;
    font-family: var(--roboto);
    margin-top: 24px;
}

.login-flow-form p.termsandservces span {
    color: #44444F;
}

.verify-email span {
    font-weight: 500;
    font-size: 16px;
}

.forget-pass-msg {
    margin-top: 47px;
    margin-bottom: 42px;
}


.forget-pass-msg p:nth-child(1) {
    font-size: 18px !important;
    font-weight: 500;
    line-height: 26px;
    color: var(--black) !important;
}

.forget-pass-msg p:nth-child(2) {
    margin-top: 0 !important;
    font-size: 14px !important;
    color: #686868 !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
}

.mobile-input span.invalid-icon {
    /* top: 10px; */
    right: 10px;
}

.forget-pass-msg p:nth-child(2) a {
    color: var(--primary-color) !important;
}

.abs-logo {
    position: absolute;
    left: 0;
    bottom: 0;
}


.side-banner {
    background: var(--primary-color);
}

.user-login {
    height: 100vh;
}

.side-banner {
    display: flex;
    flex-direction: column;

}

.side-image {
    position: absolute;
}

.invalid .input-feedback {
    color: var(--red);
    font-size: 14px;
}

.main-heading.text-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-heading {
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
    padding-left: 90px;
    padding-right: 90px;
}

.siebar-text h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.4px;
    margin-bottom: 16px;
}

p.sub-heading-text {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #E2E2EA;
    margin-bottom: 0;
}

.logo {
    margin-left: 24px;
    margin-top: 24px;
}

.my-login-page .card {
    background: #FFFFFF;
    box-shadow: 0px 25px 80px rgba(226, 226, 234, 0.6);
    border-radius: 10px;
    width: 416px;
    min-height: 466px;
    border-radius: 20px;
    padding: 48px;
    border: 0;
}

.my-login-page .card .card-body {
    padding: 0;
}

.my-login-page .card .card-body img {
    margin: auto;
    display: table;

}

.my-login-page .card .card-body img+p {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.1px;
    margin-bottom: 24px;
}

label {
    color: var(--black-dark-700);
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
}

.form-control {

    box-sizing: border-box;
    border-radius: 8px;
    padding-top: 21px !important;
    padding-bottom: 21px !important;
    color: #44444F !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.input-feedback {
    color: var(--red);
    font-size: 12px;
}

.form-group.password {
    margin-bottom: 0;
}

.form-group {
    margin-bottom: 0 !important;
}


span.view-pass {
    position: absolute;
    right: 8px;
    top: 12px;
}

.card.forget-pass p {
    text-align: center;

    font-size: 14px;
}

.card.forget-pass img+p {
    margin-top: 46px;
}



.card {
    max-width: 570px;
    padding: 24px;
    border: 0;
    box-shadow: var(--box-shadow);
}

.signupform {
    padding: 40px 40px 40px 24px;
}

.signupform {
    max-height: 100vh;
    overflow: auto;
}

.signupform .pr-btn {
    font-size: 18px;
    font-weight: 400;
    padding: 10px 0px;
    margin-bottom: 18px;
}

.thankyou .pr-btn {
    padding: 10px 24px;
}

.card.forget-msg {
    padding-top: 96px;
}

.card.forget-msg .form-group a {
    color: var(--white) !important;
    background-color: var(--primary-color);
    font-size: calc(var(--value) * 7);
    line-height: calc(var(--value) * 11);
    border-radius: calc(var(--value) * 2);
    border: 0;
    font-weight: var(--wo-medium);
    display: block;
    padding: 10px 0;
    font-size: 18px;
}

@media only screen and (max-width: 600px) {


    .login-flow-form {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0px;
        padding-right: 15px;
    }

    .login-flow-form button.veryfy-btn {
        padding-left: 16px;
        padding-right: 16px;
        width: unset;
        flex: 0 0 auto;
    }

    .verify-email {
        font-size: 12px;
    }

    .createpawword-main-area {
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;
        flex: 0 0 100%;
        margin-top: 40px;
    }

    .createpawword-main-area figure {
        margin-bottom: 30px;
    }

    .loginuser-main-area {
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
        flex: 0 0 10%;
        margin-top: 40px;
    }
}