.an_circles {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: relative;

}

.an_circles>div {
    animation: growAndFade 3s infinite ease-out;
    background-color: #fff;
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
}

.an_circles .circle1 {
    animation-delay: 1s;
}

.an_circles .circle2 {
    animation-delay: 2s;
}

.an_circles .circle3 {
    animation-delay: 3s;
}

@keyframes growAndFade {
    0% {
        opacity: 9;
        transform: scale(0.2);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}


body[aria-describedby="button-tooltip"] {
    overflow: hidden !important;
}

.onlyget_certified {
    border: none;
}

.onlytreerelivant .item {
    max-width: 295px;
}

.onlytreerelivant {
    gap: 36px;
}

.onlytreementor {
    gap: 32px
}

.onlytreementor .item {
    max-width: 298px;
}

.owl-mentors button {
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2.66667px 16px 1.33333px rgba(0, 0, 0, 0.09) !important;
    border-radius: 50% !important;
    cursor: pointer !important;
}

.owl-mentors button:hover img:nth-child(1) {
    display: none !important;
}

.owl-mentors button:hover img:nth-child(2) {
    display: block !important;
}

.owl-mentors button:hover img {
    filter: grayscale(0) !important;
}

.owl-mentors .owl-nav {
    z-index: 99;
    right: 0 !important;
    top: -39px !important;
}

.owl-mentors .owl-stage-outer {
    overflow: visible;
}


.owl-mentors button.disabled {
    opacity: 9 !important;
}

.owl-mentors .owl-nav button.owl-prev {
    left: auto;
    right: 30px;
}

.owl-mentors .owl-item.active {
    width: 100% !important;
}

.owl-mentors .owl-item:not(.active) {
    display: none;
    width: 100% !important;
}

.owl-mentors .owl-stage {
    max-width: 354px;
    width: 100% !important;
    transform: unset !important;
}


.relevantCourses .owl-theme .owl-nav [class*='owl-']:hover,
.mentor .owl-theme .owl-nav [class*='owl-']:hover {
    background: rgba(0, 98, 255, 0.1) !important;
}

.mentor .owl-theme .owl-nav button,
.relevantCourses .owl-nav button {
    width: 42px !important;
    height: 42px !important;
    border-radius: 50% !important;
    box-shadow: 0px 4px 24px 2px rgba(0, 0, 0, 0.09) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.mentor .owl-nav button.owl-prev {
    left: -64px;
}

.mentor .owl-theme .owl-nav [class*=owl-]:hover img,
.relevantCourses .owl-nav [class*=owl-]:hover img {
    filter: grayscale(0)
}


.mentor .owl-nav button.owl-next {
    right: -60px !important;
}

/* .mentor .owl-nav button:hover img:nth-child(1),
.relevantCourses .owl-nav button:hover img:nth-child(1) {
    display: block !important;
}

.mentor .owl-nav button img:nth-child(2),
.relevantCourses .owl-nav button img:nth-child(2) {
    display: none !important;
} */

.live_mentro_group .carousel-item {
    margin-bottom: 24px;
}

.mentor .owl-theme .owl-nav {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    top: 240px !important;
}

.relevantCourses .owl-theme .owl-nav {
    top: 30% !important;
}

.relevantCourses .owl-nav button.owl-prev {
    left: -68px;
}

.relevantCourses .owl-nav button.owl-next {
    right: -68px;
}

@media (max-width: 1200px) {
    .CourseOutline .col-xl-8 {
        margin-top: 24px;
    }

    .get_certified .row .col-md-5 {
        align-items: center;
        display: flex;
    }

    .onlytreerelivant .item {
        max-width: 250px;
    }

    .onlytreerelivant {
        gap: 20px
    }
}

@media (max-width: 992px) {
    .mentor .owl-nav button.owl-next {
        right: 10px !important;
    }

    .mentor .owl-nav button.owl-prev {
        left: 10px !important;
    }

    .mentor .owl-theme .owl-nav {
        top: 105px !important
    }

    .onlytreerelivant .item {
        max-width: 300px;
    }

    .relevantCourses .owl-nav button.owl-prev {
        left: 10px;
    }

    .relevantCourses .owl-nav button.owl-next {
        right: 10px;
    }

    .relevantCourses .owl-theme .owl-nav {
        top: 20% !important;
    }

    .relevantCourses .owl-theme .owl-nav [class*='owl-'],
    .relevantCourses .owl-theme .owl-nav [class*='owl-']:hover,
    .mentor .owl-theme .owl-nav button,
    .mentor .owl-theme .owl-nav button:hover,
    .mentor .owl-theme .owl-nav [class*='owl-']:hover {
        background-color: white !important;
    }
}

@media (max-width: 768px) {
    .onlytreerelivant .item {
        max-width: 300px;
    }

    .get_certified .row .col-md-5 {
        align-items: start;
        display: flex;
    }




    .relevantCourses .owl-nav button {
        width: 70px;
        height: 70px;
    }

    .relevantCourses .owl-nav button.owl-prev {
        left: 0px;
    }

    .relevantCourses .owl-nav button.owl-next {
        right: 0px;
    }

    .relevantCourses .owl-theme .owl-nav {
        top: 23% !important;
    }

    .courses .card figure .view-details {
        z-index: 99;
        opacity: 0;
        width: 100%;
        height: 100%;

    }

    .courses .card figure .view-details a {
        opacity: 0;
        width: 100%;
        height: 100%;
    }

    .relevantCourses .owl-theme .owl-nav [class*='owl-'],
    .relevantCourses .owl-theme .owl-nav [class*='owl-']:hover,
    .mentor .owl-theme .owl-nav button,
    .mentor .owl-theme .owl-nav button:hover,
    .mentor .owl-theme .owl-nav [class*='owl-']:hover {
        background-color: white !important;
    }

    .relevantCourses .video-cards .card {
        margin-bottom: 0;
    }
}

@media (max-width: 600px) {
    .onlytreerelivant .item {
        max-width: 100%;
    }

    .CourseCard_mainBlock {
        flex-wrap: wrap;
        gap: 8px;
    }

    .CourseCard_mainBlock h2 {
        margin-bottom: 0;
    }



    .mentor .owl-nav button.owl-prev {
        left: 0px !important
    }


    .mentor .owl-nav button.owl-next {
        right: 0px !important;
    }


    .relevantCourses .owl-theme .owl-nav [class*='owl-'],
    .relevantCourses .owl-theme .owl-nav [class*='owl-']:hover,
    .mentor .owl-theme .owl-nav button,
    .mentor .owl-theme .owl-nav button:hover,
    .mentor .owl-theme .owl-nav [class*='owl-']:hover {
        background-color: white !important;
    }

    .onlytreementor .item {
        max-width: 100% !important;
        width: 100%;
    }

    .onlytreementor .item figure img {
        width: 100%;

    }

    .relevantCourses .owl-theme .owl-nav {
        top: 28% !important;
    }

}

@media (max-width: 320px) {
    .relevantCourses .owl-theme .owl-nav {
        top: 23% !important;
    }

}